import { BsStars } from "react-icons/bs"
import { IoIosCloseCircle } from "react-icons/io"
import { LuRefreshCw } from "react-icons/lu"

export const statusColor = {
    'New': "bg-blue-light dark:bg-transparent text-blue border border-blue border-opacity-20",
    'Processing': "bg-orange-light dark:bg-transparent text-orange border border-orange border-opacity-20",
    'Cancelled': "bg-red-light dark:bg-transparent text-red border border-red border-opacity-20",
    'Completed': "bg-green-light dark:bg-transparent text-green border border-green border-opacity-20",
}

export const statusIcons = {
    'New': <BsStars />,
    'Processing': <LuRefreshCw />,
    'Cancelled': <IoIosCloseCircle />,
    'Completed': <BsStars />,
}