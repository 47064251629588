import React from 'react'
import { CardLayout, RichTextEditor } from '../../Molecules';
import { DataTable } from '.';
import { BiSolidEdit } from 'react-icons/bi';
import { FaTimes } from 'react-icons/fa';
import { ImBin } from 'react-icons/im';
import { Button, InputWithLabel, PageHeading, Switch, UploadFile } from '../../Atoms';
import { DeleteModalComponent } from '../modal/delete';
import { ModalComponent } from '../modal';
import { BsCheck } from 'react-icons/bs';
import { FaEye } from 'react-icons/fa6';

export const PostCommentsTable = () => {

    const [deleteModal, setDeleteModal] = React.useState(false);
    const [viewModal, setViewModal] = React.useState(false);
    const [categoryModal, setCategoryModal] = React.useState(false);
    const [importModal, setImportModal] = React.useState(false);
    const [content, setContent] = React.useState('')

    const statusColor = {
        'No': "bg-red-light dark:bg-transparent text-red border-2 border-red ",
        'Yes': "bg-green-light dark:bg-transparent text-green border-2 border-green ",
    }

    const statusIcons = {
        'Yes': <BsCheck />,
        'No': <FaTimes className='text-xs' />,
    }

    const columns = [
        {
            name: "name",
            label: "Title",
            filter: true,
            sort: true,
            sortOrder: "desc",
            visible: true,
        },
        {
            name: "customer",
            label: "Customer",
            filter: true,
            sort: true,
            sortOrder: "desc",
            visible: true,
        },
        {
            name: "visibility",
            label: "Visibility",
            filter: true,
            sort: true,
            sortOrder: "desc",
            visible: true,
            customComponent: (props) => {
                return (
                    <div className='flex items-center justify-center'>
                        <span className={`w-5 h-5 p-1 flex items-center space-x-1 justify-center text-xs leading-5 font-semibold rounded-full ${statusColor[props?.visibility]} p-0.5 `}>
                            <div className='text-sm'>{statusIcons[props.visibility]}</div>
                        </span>
                    </div>
                );
            },
        },
        {
            name: "",
            label: "Action",
            filter: true,
            sort: true,
            sortOrder: "desc",
            visible: true,
            customComponent: (props) => {
                return (
                    <div className='flex items-center justify-center space-x-3'>
                        <div
                            onClick={() => {

                                setViewModal(!viewModal)
                                setContent(props)
                            }}
                            className='text-gray-icon cursor-pointer flex items-center justify-center space-x-1 text-sm'>
                            <div><FaEye className='text-lg' /></div>
                            <div className='font-medium text-gray-text'>View</div>
                        </div>
                        <div
                            onClick={() => setCategoryModal(!categoryModal)}
                            className='text-orange cursor-pointer flex items-center justify-center space-x-1 text-sm'>
                            <div><BiSolidEdit className='text-lg' /></div>
                            <div className='font-medium'>Edit</div>
                        </div>
                        <div
                            onClick={() => setDeleteModal(!deleteModal)}
                            className='text-red cursor-pointer flex items-center justify-center space-x-1 text-sm'>
                            <div><ImBin className='text-lg' /></div>
                            <div className='text-red font-medium'>Delete</div>
                        </div>
                    </div>
                );
            },
        },

    ];

    const data = [
        {
            id: 1,
            name: 'Street 1',
            customer: '123456',
            description: 'Any thing cool is working',
            visibility: 'Yes',
            last_updated: '12/12/2021',

        },
    ]

    const options = {
        paginationTop: true,
        paginationBottom: true,
    };

    const onSubmitForm = (e) => {
        e.preventDefault()
        const formData = new FormData(e.target)
        const data = Object.fromEntries(formData)
        console.log(data)
        // navigate('/dashboard')
    }

    const ImportModalBody = (
        <form
            onSubmit={onSubmitForm}
            className='p-5 '
        >
            <div className=' flex items-center justify-between pb-3'>
                <div className='text-xl font-semibold'>
                    Import Categories
                </div>
                <div>
                    <FaTimes
                        onClick={() => setImportModal(!importModal)}
                        className='text-gray-icon cursor-pointer' />
                </div>
            </div>
            <div className='text-orange text-xs md:text-sm pb-2 font-semibold hover:underline hover:duration-300'>
                Download example CSV file
            </div>
            <div>
                <UploadFile
                    title={'Upload a CSV file'}
                    name={'file'}
                    id={'file'}
                    accept={'.csv'}
                />
            </div>

            <div className='flex items-center mt-5 space-x-2'>
                <Button
                    title={'Import'}
                    type={'submit'}
                    textSize={'text-xs md:text-sm  px-4'}
                    height={'h-8 md:h-10'}
                    background={'bg-orange'}
                    color="text-white"
                    border={true}
                />
                <Button
                    title={'Cancel'}
                    type={'button'}
                    onButtonPressed={() => setImportModal(!importModal)}
                    textSize={'text-xs md:text-sm max-w-min px-4'}
                    height={'h-8 md:h-10'}
                    background={'bg-white'}
                    color="text-black"
                    border={true}
                />
            </div>
        </form>
    )

    const CategoryModalBody = (<form
        onSubmit={onSubmitForm}
        className='p-5 dark:border-gray-dark  bg-white dark:bg-black-SECONDARY rounded-lg'
    >
        <div className='flex items-center justify-between pb-6'>
            <div className='text-sm lg:text-base font-semibold dark:text-white'>
                Create comment
            </div>
            <div>
                <FaTimes
                    onClick={() => setCategoryModal(!categoryModal)}
                    className='text-gray-icon cursor-pointer' />
            </div>
        </div>
        <div
            className=' flex flex-col space-y-3 '
        >
            <InputWithLabel
                title={'Title'}
                name="title"
                placeholder='Enter title'
                password={false}
                type={'text'}
                isRequired={true}
            />
            <InputWithLabel
                title={'Customer'}
                name="customer"
                placeholder='Enter customer'
                password={false}
                type={'text'}
                disabled={true}
                isRequired={true}
            />
            <div>
                <Switch title="Approved for public" />
            </div>

            <div className='md:col-span-2'>
                <RichTextEditor
                    name="conent"
                    title="Content"
                    isRequired={true}
                />
            </div>

        </div>


        <div className='flex items-center mt-5 space-x-2'>
            <Button
                title={'Create'}
                onButtonPressed={() => setCategoryModal(!categoryModal)}
                type={'submit'}
                textSize={'text-xs md:text-sm max-w-min px-4'}
                height={'h-8 md:h-10'}
            />
            <Button
                title={'Create & create another'}
                type={'submit'}
                textSize={'text-xs md:text-sm  px-4'}
                height={'h-8 md:h-10'}
                background={'bg-white'}
                color="text-black"
                border={true}
            />
            <Button
                title={'Cancel'}
                type={'button'}
                onButtonPressed={() => setCategoryModal(!categoryModal)}
                textSize={'text-xs md:text-sm max-w-min px-4'}
                height={'h-8 md:h-10'}
                background={'bg-white'}
                color="text-black"
                border={true}
            />
        </div>

    </form>
    )

    const ViewModalBody = (<form
        onSubmit={onSubmitForm}
        className='p-5'
    >
        <div className='flex items-center justify-between pb-6'>
            <div className='text-xl font-semibold'>
                View ${content?.name}
            </div>
            <div>
                <FaTimes
                    onClick={() => setViewModal(!viewModal)}
                    className='text-gray-icon cursor-pointer' />
            </div>
        </div>
        <div className='flex flex-col space-y-4'>
            <div className='flex flex-col'>
                <div className='text-sm font-semibold pb-2'>Name</div>
                <div className='text-sm line-clamp-1'>{content?.name}</div>
            </div>
            <div className='flex flex-col'>
                <div className='text-sm font-semibold pb-2'>Customer</div>
                <div className='text-sm line-clamp-1'>{content?.customer}</div>
            </div>

            <div className='flex flex-col'>
                <div className='text-sm font-semibold pb-2'>Visibility</div>
                <div className='flex items-center'>
                    <span className={`w-5 h-5 p-1 flex items-center space-x-1 justify-center text-xs leading-5 font-semibold rounded-full ${statusColor[content?.visibility]} p-0.5 `}>
                        <div className='text-sm'>{statusIcons[content?.visibility]}</div>
                    </span>
                </div>
            </div>
            <div className='flex flex-col'>
                <div className='text-sm font-semibold pb-2'>Updated at</div>
                <div className='text-sm line-clamp-1'>{content?.last_updated}</div>
            </div>
            <div className='flex flex-col'>
                <div className='text-sm font-semibold pb-2'>Content</div>
                <div className='text-sm line-clamp-3'>{content?.description}</div>
            </div>
        </div>


        <div className='flex items-center mt-5 space-x-2'>
            <Button
                title={'Close'}
                type={'button'}
                onButtonPressed={() => setViewModal(!viewModal)}
                textSize={'text-xs md:text-sm max-w-min px-4'}
                height={'h-8 md:h-10'}
                background={'bg-white'}
                color="text-black"
                border={true}
            />
        </div>

    </form>
    )
    return (
        <div className=''>
            <ModalComponent
                style="w-11/12 sm:w-10/12 md:w-1/2  2xl:w-[22%] top-[25%] lg:top-[30%] "
                modal={categoryModal}
                onModalStatus={() => setCategoryModal(!categoryModal)}
                content={CategoryModalBody}
            />

            <DeleteModalComponent
                onModalStatus={() => setDeleteModal(!deleteModal)}
                modal={deleteModal}
                type={'delete'}
            />

            <ModalComponent
                style="w-11/12 sm:w-10/12 md:w-1/2 lg:w-4/12  2xl:w-[22%] top-[25%] lg:top-[30%] "
                modal={importModal}
                onModalStatus={() => setImportModal(!importModal)}
                content={ImportModalBody}
            />

            <ModalComponent
                style="w-11/12 sm:w-10/12 md:w-1/2  2xl:w-[22%] top-[25%] lg:top-[30%] "
                modal={viewModal}
                onModalStatus={() => setViewModal(!viewModal)}
                content={ViewModalBody}
            />

            <CardLayout
                showTitle={true}
                title=''
                buttonTitle={'New Comment'}
                onButtonPressed={() => setCategoryModal(!categoryModal)}
                button={true}

                customButtonsStatus={false}
                content={<DataTable
                    data={data}
                    groupBy={false}
                    columns={columns} options={options} />}
            />
        </div>


    )
}
