import React from "react";
import { FaTimes } from "react-icons/fa";
import Modal from "react-modal";
import { RiDeleteBin5Line } from "react-icons/ri";
import { CiWarning } from "react-icons/ci";
import { Button } from "../../Atoms";

const customStyles = {
    content: {
        position: "absolute",
        border: "none",
        background: "#fff",
        outline: "none",
        padding: "0px",
        zIndex: "9999 !important",
    },
};

Modal.defaultStyles.overlay.backgroundColor = "rgba(0,0,0,0.3)";

export const DeleteModalComponent = ({
    content,
    modal,
    onModalStatus,
    defaultStyle,
    type,
    style = "w-11/12 sm:w-10/12 md:w-1/2 lg:w-4/12  2xl:w-[22%] top-[25%] lg:top-[30%] ",
    id
}) => {
    return (
        <Modal
            id={id}
            className={` ${defaultStyle ? "w-11/12 sm:w-10/12 md:w-8/12 xl:w-7/12 top-[25%] lg:top-[15%] 2xl:top-[20%] rounded-lg shadow-equal_dark" : style
                } mx-auto absolute right-0 left-0 rounded-xl shadow-equal_dark`}
            isOpen={modal}
            onRequestClose={onModalStatus}
            style={customStyles}
            overlayClassName=""
        >
            <div className="p-5 rounded-md border dark:border-gray-dark flex flex-col items-center justify-center dark:bg-black-PRIMARY">
                <div className="flex items-center justify-end w-full">
                    <FaTimes onClick={onModalStatus} />
                </div>
                <div className="flex items-center justify-center">
                    <div className="bg-red bg-opacity-10 h-14 w-14 rounded-full flex items-center justify-center">
                        {
                            type === 'delete' ? <RiDeleteBin5Line className="text-red text-2xl" /> : type === 'reset' ? <CiWarning className="text-red text-2xl" /> : <FaTimes className="text-red text-2xl" />
                        }
                    </div>
                </div>
                <div className="font-semibold text-xs sm:text-sm lg:text-base mt-5 mb-2 dark:text-white">
                        {type === 'delete' ? "Delete Prince O'Conner": type === 'reset' ? "Are you sure?" : "Detach 1197 Nienow Park, 09359 Lake Alexandrefurt"}
                </div>
                <div className="font-regular text-xs md:text-sm text-gray-text dark:text-gray-icon">
                    Are you sure you would like to do this?
                </div>
                <div className='mt-6 flex items-center justify-center w-full space-x-2'>
                    <Button
                        type='button'
                        textSize={'text-xs md:text-sm px-4'}
                        height={'h-8 md:h-9 w-full'}
                        background={'bg-white dark:bg-black-SECONDARY'}
                        color={'text-black dark:text-white'}
                        title={'Cancel'}
                        border={true}
                        onButtonPressed={onModalStatus}
                    />
                    <Button
                        type='button'
                        textSize={'text-xs md:text-sm px-4'}
                        height={'h-8 md:h-9 w-full'}
                        background={'bg-red'}
                        title={'Confirm'}
                        onButtonPressed={onModalStatus}
                    />
                </div>

            </div>
        </Modal>
    );
};

Modal.setAppElement("#root");