import React, { useEffect, useState } from 'react'
import { Layout, LibraryModalComponent } from '../../Components/Organism'


export const Library = () => {

    return (
        <Layout>
            <LibraryModalComponent />    
        </Layout>
    )
}
