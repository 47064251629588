import { useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import { Header } from '../Molecules';
import { DrawerComponent } from './drawer';
import { ASSETS } from '../../Assets/path';

import { AiOutlineHome } from "react-icons/ai";
import { HiOutlineShoppingBag, HiOutlineSquares2X2, HiOutlineNewspaper, HiOutlineUsers } from "react-icons/hi2";
import { FaChevronDown, FaChevronUp, FaUsers } from "react-icons/fa6";
import { PiLinkSimple } from "react-icons/pi";


export const Layout = ({ children }) => {
    const location = useLocation()


    const [showSidebar, setShowSidebar] = useState(true)
    const [toggleSidebar, setToggleSidebar] = useState(false)
    const [showShopMenu, setShowShopMenu] = useState(true)
    const [showBlogMenu, setShowBlogMenu] = useState(true)
    const [showMedia, setShowMedia] = useState(true)

    const ShopMenuItems = [
        {
            id: 1,
            href: '/shop/products',
            title: 'Products',
            icon: <HiOutlineSquares2X2 className='text-xl md:text-2xl' />,
        },
        {
            id: 2,
            href: '/shop/orders',
            title: 'Orders',
            icon: <HiOutlineShoppingBag className='text-xl md:text-2xl' />,
        },
        {
            id: 3,
            href: '/shop/customers',
            title: 'Customers',
            icon: <FaUsers className='text-xl md:text-2xl' />,
        },
    ];

    const BlogsMenuItems = [
        {
            id: 1,
            href: '/blog/posts',
            title: 'Posts',
            icon: <HiOutlineNewspaper className='text-xl md:text-2xl' />,
        },
        {
            id: 2,
            href: '/blog/categories',
            title: 'Categories',
            icon: <HiOutlineShoppingBag className='text-xl md:text-2xl' />,
        },
        {
            id: 3,
            href: '/blog/authors',
            title: 'Authors',
            icon: <HiOutlineUsers className='text-xl md:text-2xl' />,
        },
        {
            id: 4,
            href: '/blog/links',
            title: 'Links',
            icon: <PiLinkSimple className='text-xl md:text-2xl' />,
        },
    ];

    const MediaMenuItems = [
        {
            id: 1,
            href: '/media/library',
            title: 'Media Library',
            icon: <HiOutlineNewspaper className='text-xl md:text-2xl' />,
        },
    ];

    return (
        <div className='min-h-screen overflow-y-auto flex flex-col bg-gray-50 dark:bg-black-PRIMARY dark:text-white'>
            <DrawerComponent
                isOpen={toggleSidebar}
                direction={'left'}
                toggleDrawer={() => setToggleSidebar(!toggleSidebar)}
            >
                <div className='flex justify-between z-[999999] dark:bg-black-PRIMARY w-full'>
                    <div className={`relative w-full   lg:bg-transparent min-w-[300px]  space-y-8 pb-10  overflow-y-auto min-h-screen text-center bg-primary text-secondary`}
                    >
                        <div className='border-b border-gray-100 dark:border-gray-dark py-3 flex items-center  w-full  '>
                            <img src={ASSETS.LOGO} className='h-10 object-cover filter invert brightness-500 dark:filter dark:invert dark:brightness-800' alt='logo' />
                        </div>

                        <div className='px-3 md:px-5'>
                            <NavLink
                                to='/dashboard'
                                key='dashboard'
                                className={({ isActive }) =>
                                    isActive
                                        ? 'p-2.5  bg-black dark:text-white bg-opacity-5 text-orange flex items-center rounded-md px-4 duration-300 cursor-pointer hover:bg-black hover:bg-opacity-5 hover:text-primary hover:duration-[100ms]'
                                        : 'p-2.5  text-gray-text dark:text-white flex items-center rounded-md px-4 duration-300 cursor-pointer hover:bg-black hover:bg-opacity-5 hover:text-primary hover:duration-[100ms]'
                                }

                            >
                                <div className='w-4'><AiOutlineHome className='text-xl md:text-2xl' /></div>
                                <span className="text-[15px] ml-4 font-semibold">Dashboard</span>
                            </NavLink>
                        </div>

                        <div className='space-y-3 px-3 md:px-5'>
                            <div
                                onClick={() => setShowShopMenu(!showShopMenu)}
                                className='px-3 cursor-pointer text-sm text-gray-text dark:text-gray-icon font-medium flex justify-between items-center'><div>Shop</div>
                                {
                                    showShopMenu ?
                                        <FaChevronDown className='text-xs text-gray-icon' /> :
                                        <FaChevronUp className='text-xs' />
                                }


                            </div>
                            {
                                showShopMenu &&
                                <div className=''>
                                    {ShopMenuItems.map(({ href, title, icon, id }) => (
                                        (
                                            <NavLink
                                                to={href}
                                                key={id}
                                                className={({ isActive }) =>
                                                    isActive
                                                        ? 'p-2.5  bg-black bg-opacity-5 text-orange flex items-center justify-between rounded-md px-4 duration-300 cursor-pointer hover:bg-black hover:bg-opacity-5 hover:text-primary hover:duration-[100ms]'
                                                        : 'p-2.5  text-gray-text dark:text-white flex items-center justify-between rounded-md px-4 duration-300 cursor-pointer hover:bg-black hover:bg-opacity-5 hover:text-primary hover:duration-[100ms]'
                                                }

                                            >
                                                <div className='flex items-center space-x-4'>
                                                    <div className={`w-4 ${href.includes(location.pathname) ? 'text-orange' : 'text-gray-icon'}`}>{icon}</div>
                                                    <div className="text-xs lg:text-[14px] ml-4 font-semibold">{title}</div>
                                                </div>
                                                {
                                                    href.includes('orders') ?
                                                        <div className='font-semibold px-1.5 h-6 flex items-center justify-center text-xs rounded-md  bg-orange-light dark:bg-black-PRIMARY border border-orange border-opacity-15 text-orange'>
                                                            120
                                                        </div> :
                                                        <div></div>
                                                }

                                            </NavLink>

                                        )
                                    ))}
                                </div>


                            }
                        </div>

                        <div className='space-y-3 px-3 md:px-5'>
                            <div
                                onClick={() => setShowBlogMenu(!showBlogMenu)}
                                className='px-3 cursor-pointer text-sm text-gray-text dark:text-gray-icon font-medium flex justify-between items-center'><div>Blog</div>
                                {
                                    showBlogMenu ?
                                        <FaChevronDown className='text-xs text-gray-icon' /> :
                                        <FaChevronUp className='text-xs' />
                                }


                            </div>
                            {
                                showBlogMenu &&
                                <div className=''>
                                    {BlogsMenuItems.map(({ href, title, icon, id }) => (
                                        (
                                            <NavLink
                                                to={href}
                                                key={id}
                                                className={({ isActive }) =>
                                                    isActive
                                                        ? 'p-2.5 group bg-black dark:text-white bg-opacity-5 text-orange flex items-center justify-between rounded-md px-4 duration-300 cursor-pointer hover:bg-black hover:bg-opacity-5 hover:text-primary hover:duration-[100ms]'
                                                        : 'p-2.5 group  text-gray-text dark:text-white flex items-center justify-between rounded-md px-4 duration-300 cursor-pointer hover:bg-black hover:bg-opacity-5 hover:text-primary hover:duration-[100ms]'
                                                }

                                            >
                                                <div className='flex items-center space-x-4'>
                                                    <div className={`w-4 ${href.includes(location.pathname) ? 'text-orange' : 'text-gray-icon'}`}>{icon}</div>
                                                    <div className="text-xs lg:text-[14px] ml-4 font-semibold">{title}</div>
                                                </div>
                                                {
                                                    href.includes('orders') ?
                                                        <div className='font-semibold px-1.5 h-6 flex items-center justify-center text-xs rounded-md  bg-orange-light dark:bg-black-PRIMARY border border-orange border-opacity-15 text-orange'>
                                                            120
                                                        </div> :
                                                        <div></div>
                                                }

                                            </NavLink>

                                        )
                                    ))}
                                </div>


                            }
                        </div>
                        <div className='space-y-3'>
                            <div
                                onClick={() => setShowMedia(!showMedia)}
                                className='px-3 cursor-pointer text-sm text-gray-text dark:text-gray-icon font-medium flex justify-between items-center'><div>Media</div>
                                {
                                    showMedia ?
                                        <FaChevronDown className='text-xs text-gray-icon' /> :
                                        <FaChevronUp className='text-xs' />
                                }
                            </div>
                            {
                                showMedia &&
                                <div className=''>
                                    {MediaMenuItems.map(({ href, title, icon, id }) => (
                                        (
                                            <NavLink
                                                to={href}
                                                key={id}
                                                className={({ isActive }) =>
                                                    isActive
                                                        ? 'p-2.5  bg-black bg-opacity-5 text-orange flex items-center justify-between rounded-md px-4 duration-300 cursor-pointer hover:bg-black hover:bg-opacity-5 hover:text-primary hover:duration-[100ms]'
                                                        : 'p-2.5  text-gray-text dark:text-white flex items-center justify-between rounded-md px-4 duration-300 cursor-pointer hover:bg-black hover:bg-opacity-5 hover:text-primary hover:duration-[100ms]'
                                                }

                                            >
                                                <div className='flex items-center space-x-4'>
                                                    <div className={`w-4 ${href.includes(location.pathname) ? 'text-orange' : 'text-gray-icon'}`}>{icon}</div>
                                                    <div className="text-xs lg:text-[14px] ml-4 font-semibold">{title}</div>
                                                </div>
                                                {
                                                    href.includes('orders') ?
                                                        <div className='font-semibold px-1.5 h-6 flex items-center justify-center text-xs rounded-md  bg-orange-light dark:bg-black-PRIMARY border border-orange border-opacity-15 text-orange'>
                                                            120
                                                        </div> :
                                                        <div></div>
                                                }
                                            </NavLink>

                                        )
                                    ))}
                                </div>
                            }
                        </div>

                    </div>

                </div>


            </DrawerComponent>

            {
                showSidebar ? (
                    <div className='overflow-hidden'>
                        <Header onShowMenu={() => setToggleSidebar(!toggleSidebar)} />
                        <div className='flex justify-between mt-14'>
                            <div className={`fixed bg-white dark:bg-black-PRIMARY lg:bg-transparent ${toggleSidebar ? 'block' : 'hidden lg:block'} min-w-[300px] px-5 space-y-8 py-10 lg:w-3/12 overflow-y-auto min-h-screen text-center bg-primary text-secondary`}
                            >
                                <NavLink
                                    to='/dashboard'
                                    key='dashboard'
                                    className={({ isActive }) =>
                                        isActive
                                            ? 'p-2.5  bg-black bg-opacity-5 text-orange flex items-center rounded-md px-4 duration-300 cursor-pointer hover:bg-black hover:bg-opacity-5 hover:text-primary hover:duration-[100ms]'
                                            : 'p-2.5  text-gray-text dark:text-white flex items-center rounded-md px-4 duration-300 cursor-pointer hover:bg-black hover:bg-opacity-5 hover:text-primary hover:duration-[100ms]'
                                    }

                                >
                                    <div className='w-4'><AiOutlineHome className='text-xl md:text-2xl text-gray-icon' /></div>
                                    <span className="text-[15px] ml-4 font-semibold">Dashboard</span>
                                </NavLink>

                                <div className='space-y-3'>
                                    <div
                                        onClick={() => setShowShopMenu(!showShopMenu)}
                                        className='px-3 cursor-pointer text-sm text-gray-text dark:text-gray-icon font-medium flex justify-between items-center'><div>Shop</div>
                                        {
                                            showShopMenu ?
                                                <FaChevronDown className='text-xs text-gray-icon' /> :
                                                <FaChevronUp className='text-xs' />
                                        }


                                    </div>
                                    {
                                        showShopMenu &&
                                        <div className=''>
                                            {ShopMenuItems.map(({ href, title, icon, id }) => (
                                                (
                                                    <NavLink
                                                        to={href}
                                                        key={id}
                                                        className={({ isActive }) =>
                                                            isActive
                                                                ? 'p-2.5  bg-black bg-opacity-5 text-orange flex items-center justify-between rounded-md px-4 duration-300 cursor-pointer hover:bg-black hover:bg-opacity-5 hover:text-primary hover:duration-[100ms]'
                                                                : 'p-2.5  text-gray-text dark:text-white flex items-center justify-between rounded-md px-4 duration-300 cursor-pointer hover:bg-black hover:bg-opacity-5 hover:text-primary hover:duration-[100ms]'
                                                        }

                                                    >
                                                        <div className='flex items-center space-x-4'>
                                                            <div className={`w-4 ${href.includes(location.pathname) ? 'text-orange' : 'text-gray-icon'}`}>{icon}</div>
                                                            <div className="text-xs lg:text-[14px] ml-4 font-semibold">{title}</div>
                                                        </div>
                                                        {
                                                            href.includes('orders') ?
                                                                <div className='font-semibold px-1.5 h-6 flex items-center justify-center text-xs rounded-md  bg-orange-light dark:bg-black-PRIMARY border border-orange border-opacity-15 text-orange'>
                                                                    120
                                                                </div> :
                                                                <div></div>
                                                        }


                                                    </NavLink>

                                                )
                                            ))}
                                        </div>


                                    }
                                </div>

                                <div className='space-y-3'>
                                    <div
                                        onClick={() => setShowBlogMenu(!showBlogMenu)}
                                        className='px-3 cursor-pointer text-sm text-gray-text dark:text-gray-icon font-medium flex justify-between items-center'><div>Blog</div>
                                        {
                                            showBlogMenu ?
                                                <FaChevronDown className='text-xs text-gray-icon' /> :
                                                <FaChevronUp className='text-xs' />
                                        }


                                    </div>
                                    {
                                        showBlogMenu &&
                                        <div className=''>
                                            {BlogsMenuItems.map(({ href, title, icon, id }) => (
                                                (
                                                    <NavLink
                                                        to={href}
                                                        key={id}
                                                        className={({ isActive }) =>
                                                            isActive
                                                                ? 'p-2.5  bg-black bg-opacity-5 text-orange flex items-center justify-between rounded-md px-4 duration-300 cursor-pointer hover:bg-black hover:bg-opacity-5 hover:text-primary hover:duration-[100ms]'
                                                                : 'p-2.5  text-gray-text dark:text-white flex items-center justify-between rounded-md px-4 duration-300 cursor-pointer hover:bg-black hover:bg-opacity-5 hover:text-primary hover:duration-[100ms]'
                                                        }

                                                    >
                                                        <div className='flex items-center space-x-4'>
                                                            <div className={`w-4 ${href.includes(location.pathname) ? 'text-orange' : 'text-gray-icon'}`}>{icon}</div>
                                                            <div className="text-xs lg:text-[14px] ml-4 font-semibold">{title}</div>
                                                        </div>
                                                        {
                                                            href.includes('orders') ?
                                                                <div className='font-semibold px-1.5 h-6 flex items-center justify-center text-xs rounded-md  bg-orange-light dark:bg-black-PRIMARY border border-orange border-opacity-15 text-orange'>
                                                                    120
                                                                </div> :
                                                                <div></div>
                                                        }


                                                    </NavLink>

                                                )
                                            ))}
                                        </div>
                                    }
                                </div>
                                <div className='space-y-3'>
                                    <div
                                        onClick={() => setShowMedia(!showMedia)}
                                        className='px-3 cursor-pointer text-sm text-gray-text dark:text-gray-icon font-medium flex justify-between items-center'><div>Media</div>
                                        {
                                            showMedia ?
                                                <FaChevronDown className='text-xs text-gray-icon' /> :
                                                <FaChevronUp className='text-xs' />
                                        }
                                    </div>
                                    {
                                        showMedia &&
                                        <div className=''>
                                            {MediaMenuItems.map(({ href, title, icon, id }) => (
                                                (
                                                    <NavLink
                                                        to={href}
                                                        key={id}
                                                        className={({ isActive }) =>
                                                            isActive
                                                                ? 'p-2.5  bg-black bg-opacity-5 text-orange flex items-center justify-between rounded-md px-4 duration-300 cursor-pointer hover:bg-black hover:bg-opacity-5 hover:text-primary hover:duration-[100ms]'
                                                                : 'p-2.5  text-gray-text dark:text-white flex items-center justify-between rounded-md px-4 duration-300 cursor-pointer hover:bg-black hover:bg-opacity-5 hover:text-primary hover:duration-[100ms]'
                                                        }

                                                    >
                                                        <div className='flex items-center space-x-4'>
                                                            <div className={`w-4 ${href.includes(location.pathname) ? 'text-orange' : 'text-gray-icon'}`}>{icon}</div>
                                                            <div className="text-xs lg:text-[14px] ml-4 font-semibold">{title}</div>
                                                        </div>
                                                        {
                                                            href.includes('orders') ?
                                                                <div className='font-semibold px-1.5 h-6 flex items-center justify-center text-xs rounded-md  bg-orange-light dark:bg-black-PRIMARY border border-orange border-opacity-15 text-orange'>
                                                                    120
                                                                </div> :
                                                                <div></div>
                                                        }
                                                    </NavLink>

                                                )
                                            ))}
                                        </div>
                                    }
                                </div>

                            </div>
                            <main className='w-full lg:w-9/12 lg:ml-auto'>{children}</main>
                        </div>
                    </div>
                ) :
                    <div>
                        <main>{children}</main>
                    </div>
            }
        </div>
    );
}
