import React from 'react'
import { useParams } from 'react-router-dom'
import { FaChevronUp } from 'react-icons/fa6'

import { Button, CustomSelect, CustomSelectWithLabel, InputWithLabel, MultiInputWithLabel, PageHeading, TextAreaWithLabel, UploadFile } from '../../../Components/Atoms'
import { useAppNavigate } from '../../../Hooks'
import { Layout } from '../../../Components/Organism'
import { BreadCrum, CardLayout, RichTextEditor } from '../../../Components/Molecules'
import { UploadModalComponent } from '../../../Components/Organism/modal/uploadModal'



export const LinkDetail = () => {
    const navigate = useAppNavigate()
    const { type, id } = useParams()

    const onSubmitForm = (e) => {
        e.preventDefault()
        const formData = new FormData(e.target)
        const data = Object.fromEntries(formData)
        console.log(data)
        // navigate('/dashboard')
    }

    return (
        <Layout>
            <div className='p-5 md:p-10 font-inter'>
                <div className='py-1.5'>
                    <BreadCrum content={[
                        {
                            title: 'Links',
                            url: '/blog/links'
                        },
                        {
                            title: `${type == 'edit' ? 'Edit' : 'Create'} Link`,
                            url: `${type == 'edit' ? `/blog/links/edit/${id}` : '/blog/links/create'}`
                        },
                    ]} />
                </div>
                <div className='flex items-center justify-between'>
                    <PageHeading title={type == 'add' ? 'Create Link' : 'Edit Link'} />
                    <CustomSelect
                        options={[
                            {
                                id: 1,
                                label: 'English',
                                value: 'english'
                            },
                            {
                                id: 2,
                                label: 'Indonesia',
                                value: 'indonesia'
                            },
                            {
                                id: 3,
                                label: 'Arabic',
                                value: 'arabic'
                            },
                        ]}
                    />
                </div>

                <form
                    onSubmit={onSubmitForm}
                    className='mt-5 space-y-6'
                >
                    <div className='space-y-6'>
                        <div
                            className=' grid md:grid-cols-2 gap-4 border border-gray-100 dark:border-gray-dark  bg-white dark:bg-black-PRIMARY rounded-xl px-5 pt-6 pb-10'
                        >
                            <InputWithLabel
                                title={'Title'}
                                name="title"
                                placeholder='Enter title'
                                password={false}
                                type={'text'}
                                isRequired={true}
                            />
                            <InputWithLabel
                                title={'Color'}
                                name="color"
                                placeholder='Enter color'
                                password={false}
                                type={'color'}
                                disabled={false}
                                isRequired={false}
                            />

                            <div className='md:col-span-2'>
                                <TextAreaWithLabel
                                    title={'Description'}
                                    name="description"
                                    placeholder='Enter description'
                                    type='text'
                                    disabled={false}
                                    isRequired={true}
                                />
                            </div>

                            <div className='md:col-span-2'>
                                <InputWithLabel
                                    title={'URL'}
                                    name="url"
                                    placeholder='Enter url'
                                    password={false}
                                    type='text'
                                    disabled={false}
                                    isRequired={true}
                                />
                            </div>

                        </div>

                        <div className='mt-4'>
                            <CardLayout showTitle={true} title='Images'
                                button={false}
                                customButtonsStatus={true}
                                customButtons={
                                    <div className=''>
                                        <FaChevronUp className='text-gray-icon text-xs' />
                                    </div>
                                }
                                buttonTitle={'New Address'}
                                buttonType={'orange'}
                                content={<div className='p-5'>
                                    <UploadModalComponent />

                                </div>} />
                        </div>

                    </div>

                    {
                        type == 'edit' ?
                            <div className='flex items-center mt-5 space-x-2'>
                                <Button
                                    title={'Save Changes'}
                                    type={'submit'}
                                    textSize={'text-xs md:text-sm px-4'}
                                    height={'h-8 md:h-10'}
                                />
                                <Button
                                    title={'Cancel'}
                                    type={'button'}
                                    onButtonPressed={() => navigate('/shop/customers')}
                                    textSize={'text-xs md:text-sm max-w-min px-4'}
                                    height={'h-8 md:h-10'}
                                    background={'bg-white'}
                                    color="text-black"
                                    border={true}
                                />
                            </div> :
                            <div className='flex items-center mt-5 space-x-2'>
                                <Button
                                    title={'Create'}
                                    type={'submit'}
                                    textSize={'text-xs md:text-sm max-w-min px-4'}
                                    height={'h-8 md:h-10'}
                                />
                                <Button
                                    title={'Create & create another'}
                                    type={'submit'}
                                    textSize={'text-xs md:text-sm  px-4'}
                                    height={'h-8 md:h-10'}
                                    background={'bg-white'}
                                    color="text-black"
                                    border={true}
                                />
                                <Button
                                    title={'Cancel'}
                                    type={'button'}
                                    onButtonPressed={() => navigate('/shop/customers')}
                                    textSize={'text-xs md:text-sm max-w-min px-4'}
                                    height={'h-8 md:h-10'}
                                    background={'bg-white'}
                                    color="text-black"
                                    border={true}
                                />
                            </div>
                    }
                </form>
            </div>
        </Layout>
    )
}
