import React, { useEffect } from 'react'
import { Button, CustomSelectWithLabel, InputWithLabel, MultiInputWithLabel, PageHeading, UploadFile } from '../../../Components/Atoms'
import { useAppNavigate } from '../../../Hooks'
import { DeleteModalComponent, Layout, PostCommentsTable } from '../../../Components/Organism'
import { BreadCrum, CardLayout, RichTextEditor, Tabs } from '../../../Components/Molecules'
import { FaChevronUp, FaEye } from 'react-icons/fa6'

import { useParams } from 'react-router-dom'
import { ASSETS } from '../../../Assets/path'
import { BiCommentDetail, BiSolidEdit } from 'react-icons/bi'
import { UploadModalComponent } from '../../../Components/Organism/modal/uploadModal'

export const PostDetail = () => {
    const navigate = useAppNavigate()
    const [deleteModal, setDeleteModal] = React.useState(false)
    const [postType, setPostType] = React.useState(1)
    const { type, id } = useParams()

    useEffect(() => {
        setPostType(type)
    }, [type])

    const onSubmitForm = (e) => {
        e.preventDefault()
        const formData = new FormData(e.target)
        const data = Object.fromEntries(formData)
    }

    const tabList = [
        {
            id: 1,
            name: 'View Post',
            icon: <FaEye />
        },
        {
            id: 2,
            name: 'Edit Post',
            icon: <BiSolidEdit />
        },
        {
            id: 3,
            name: 'Manage Comments',
            icon: <BiCommentDetail />
        }
    ]

    return (
        <Layout>
            <div className='p-5 md:p-10 font-inter'>
                <div className='py-1.5'>
                    <BreadCrum content={[
                        {
                            title: 'Posts',
                            url: '/blog/posts'
                        },
                        {
                            title: 'Bette Swaniawski',
                            url: `/blog/posts/${id}/${type}`
                        },
                        {
                            title: `${postType == 1 ? 'View' : postType == 2 ? 'Edit' : 'Comments'}`,
                            url: `/blog/posts/${id}/${postType}`
                        },

                    ]} />
                </div>
                <div className='flex items-center justify-between'>
                    <PageHeading title='Edit Total contextually' />
                    {
                        postType == 2 &&
                        <Button
                            title={'Delete'}
                            type={'button'}
                            textSize={'text-xs md:text-sm  px-4'}
                            height={'h-9'}
                            background={'bg-red'}
                            color="text-white"
                            border={false}
                            onButtonPressed={() => setDeleteModal(!deleteModal)}
                        />
                    }
                </div>


                <DeleteModalComponent
                    onModalStatus={() => setDeleteModal(!deleteModal)}
                    modal={deleteModal}
                    type={'delete'}
                />
                <div className='w-full flex items-center justify-center mt-5'>
                    <Tabs
                        list={tabList}
                        setTab={setPostType}
                        tab={postType}
                        iconStatus={true}
                    />
                </div>

                {
                    postType == 1 ?
                        <div className=''>
                            <div className='p-5 mt-5 grid grid-cols-3 gap-4 border dark:border-gray-dark  bg-white dark:bg-transparent rounded-xl px-5'>
                                <div className='flex flex-col space-y-1'>
                                    <div className='font-medium text-sm'>Title</div>
                                    <div className='font-regular text-sm text-gray-text'>CarWala</div>
                                </div>
                                <div className='flex flex-col space-y-1'>
                                    <div className='font-medium text-sm'>Author</div>
                                    <div className='font-regular text-sm text-gray-text'>CarWala</div>
                                </div>
                                <div className='row-span-2 flex items-center justify-end'>
                                    <img src={ASSETS.AVATAR} alt='' className='w-40 h-40' />
                                </div>
                                <div className='flex flex-col space-y-1'>
                                    <div className='font-medium text-sm'>Slug</div>
                                    <div className='font-regular text-sm text-gray-text'>CarWala</div>
                                </div>
                                <div className='flex flex-col space-y-1'>
                                    <div className='font-medium text-sm'>Category</div>
                                    <div className='font-regular text-sm text-gray-text'>CarWala</div>
                                </div>
                                <div className='flex flex-col space-y-1'>
                                    <div className='font-medium text-sm'>Published at</div>
                                    <div className='font-regular bg-green-50 text-green max-w-min whitespace-nowrap rounded-md text-xs px-2 py-1'>Feb 7,2024</div>
                                </div>
                                <div className='flex flex-col space-y-1'>
                                    <div className='font-medium text-sm'>Tags</div>
                                    <div className='space-x-1 flex items-center '>{['App', 'Milo'].map((item, index) =>
                                        <div
                                            key={index}
                                            className='px-2 py-1 rounded-sm text-xs bg-orange-light text-orange'>{item}</div>)}</div>
                                </div>


                            </div>

                            <CardLayout showTitle={true} title='Content'
                                content={<div className='p-5 text-xs md:text-sm'>Then again--"BEFORE SHE HAD THIS FIT--" you never had fits, my dear, I think?' he said to one of the others looked round also, and all of them attempted to explain the mistake it had fallen into it.</div>}
                            />
                        </div>
                        :
                        postType == 2 ?
                            <form
                                onSubmit={onSubmitForm}
                                className='mt-5 space-y-6'
                            >
                                <div className='space-y-6'>
                                    <div
                                        className=' grid md:grid-cols-2 gap-4 border dark:border-gray-dark  bg-white dark:bg-transparent rounded-xl px-5 pt-6 pb-10'
                                    >
                                        <InputWithLabel
                                            title={'Name'}
                                            name="name"
                                            placeholder='Enter name'
                                            password={false}
                                            type={'text'}
                                            isRequired={true}
                                        />
                                        <InputWithLabel
                                            title={'Slug'}
                                            name="slug"
                                            placeholder='Enter slug'
                                            password={false}
                                            type={'text'}
                                            disabled={false}
                                            isRequired={false}
                                        />

                                        <div className='md:col-span-2'>
                                            <RichTextEditor
                                                name="content"
                                                title="Content"
                                                isRequired={false}
                                            />
                                        </div>
                                        <CustomSelectWithLabel
                                            title={'Author'}
                                            name="author"
                                            placeholder='Select author'
                                            isRequired={true}
                                            options={[
                                                { value: '1', label: 'Author 1' },
                                                { value: '2', label: 'Author 2' },
                                                { value: '3', label: 'Author 3' },
                                            ]}
                                        />
                                        <CustomSelectWithLabel
                                            title={'Category'}
                                            name="category"
                                            placeholder='Select category'
                                            isRequired={true}
                                            options={[
                                                { value: '1', label: 'Category 1' },
                                                { value: '2', label: 'Category 2' },
                                                { value: '3', label: 'Category 3' },
                                            ]} />
                                        <InputWithLabel
                                            title={'Published Date'}
                                            name="published_date"
                                            placeholder='Enter published date'
                                            password={false}
                                            type='date'
                                            disabled={false}
                                            isRequired={false}
                                        />
                                        <MultiInputWithLabel
                                            title={'Tags'}
                                            name="tags"
                                            placeholder='Enter tags'
                                            type={'text'}
                                            isRequired={false}
                                            disabled={false}
                                        />
                                    </div>

                                    <div className='mt-4'>
                                        <CardLayout showTitle={true} title='Images'
                                            button={false}
                                            customButtonsStatus={true}
                                            customButtons={
                                                <div className=''>
                                                    <FaChevronUp className='text-gray-icon text-xs' />
                                                </div>
                                            }
                                            buttonTitle={'New Address'}
                                            buttonType={'orange'}
                                            content={<div className='p-5'>
                                                <UploadModalComponent/>

                                            </div>} />
                                    </div>

                                </div>


                                <div className='flex items-center mt-5 space-x-2'>
                                    <Button
                                        title={'Save Changes'}
                                        type={'submit'}
                                        textSize={'text-xs md:text-sm px-4'}
                                        height={'h-8 md:h-10'}
                                    />

                                    <Button
                                        title={'Cancel'}
                                        type={'button'}
                                        onButtonPressed={() => navigate('/shop/products')}
                                        textSize={'text-xs md:text-sm  px-4'}
                                        height={'h-8 md:h-10'}
                                        background={'bg-white'}
                                        color="text-black"
                                        border={true}
                                    />
                                </div>
                            </form> : <div className='mt-5'>
                                <PostCommentsTable />
                            </div>
                }



            </div>
        </Layout>
    )
}
