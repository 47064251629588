import React from 'react'
import { ASSETS } from '../../../Assets/path'
import { Button, Checkbox, InputWithLabel, PageHeading } from '../../../Components/Atoms'
import { useAppNavigate } from '../../../Hooks'
import { Layout } from '../../../Components/Organism'
import { BreadCrum } from '../../../Components/Molecules'

export const NewCustomer = () => {
    const navigate = useAppNavigate()

    const onSubmitForm = (e) => {
        e.preventDefault()
        const formData = new FormData(e.target)
        const data = Object.fromEntries(formData)
        console.log(data)
        // navigate('/dashboard')
    }

    return (
        <Layout>
            <div className='p-10 font-inter '>
                <div className='py-1.5'>
                <BreadCrum content={[
                    {
                        title: 'Customers',
                        url: '/shop/customers'
                    },
                    {
                        title: 'Create',
                        url: '/shop/customers/new'
                    },

                ]} />
                </div>

                <PageHeading title='Create Customer' />
                <form
                    onSubmit={onSubmitForm}
                    className='mt-5'
                >
                    <div
                        className=' grid grid-cols-2 gap-4 border border-gray-100 dark:border-gray-dark  bg-white dark:bg-black-PRIMARY rounded-xl px-6 md:px-8 pt-6 pb-10'
                    >
                        <InputWithLabel
                            title={'Name'}
                            name="name"
                            placeholder='Enter name'
                            password={false}
                            type={'text'}
                            isRequired={true}
                        />
                        <InputWithLabel
                            title={'Email address'}
                            name="email"
                            placeholder='Enter email'
                            password={false}
                            type={'email'}
                            isRequired={true}
                        />
                        <InputWithLabel
                            title={'Phone'}
                            name="phone"
                            placeholder='Enter phone no.'
                            password={false}
                            type={'tel'}
                            isRequired={false}
                        />
                        <InputWithLabel
                            title={'Birthday'}
                            name="phone"
                            placeholder='Enter phone no.'
                            password={false}
                            type={'date'}
                            isRequired={false}
                        />
                    </div>


                    <div className='flex items-center mt-5 space-x-2'>
                        <Button
                            title={'Create'}
                            type={'submit'}
                            textSize={'text-xs md:text-sm max-w-min px-4'}
                            height={'h-8 md:h-10'}
                        />
                        <Button
                            title={'Create & create another'}
                            type={'submit'}
                            textSize={'text-xs md:text-sm  px-4'}
                            height={'h-8 md:h-10'}
                            background={'bg-white'}
                            color="text-black"
                            border={true}
                        />
                        <Button
                            title={'Cancel'}
                            type={'button'}
                            onButtonPressed={() => navigate('/shop/customers')}
                            textSize={'text-xs md:text-sm max-w-min px-4'}
                            height={'h-8 md:h-10'}
                            background={'bg-white'}
                            color="text-black"
                            border={true}

                        />
                    </div>

                </form>

            </div>
        </Layout>


    )
}
