import React from 'react'
import { ASSETS } from '../../Assets/path'
import { Button, Checkbox, InputWithLabel } from '../../Components/Atoms'
import { useAppNavigate } from '../../Hooks'

export const Signin = () => {
    const navigate = useAppNavigate()
    
    const onSubmitForm = (e) => {
        e.preventDefault()
        const formData = new FormData(e.target)
        const data = Object.fromEntries(formData)
        console.log(data)
        navigate('/dashboard')
        
    }

    return (
        <div className='bg-gray-50 dark:bg-black-PRIMARY font-inter min-h-screen overflow-hidden flex flex-col justify-center items-center'>
            <form
                onSubmit={onSubmitForm}
                className='min-h-[400px] space-y-5 border border-gray-100 dark:border-gray-dark md:min-w-[500px] bg-white dark:bg-black-SECONDARY rounded-xl px-6 md:px-8 pt-6 pb-10'>
                <div className='flex flex-col items-center justify-center space-y-1'>
                    <div className=''>
                    <img src={ASSETS.LOGO} alt='' className='h-8 hidden lg:block lg:h-10 object-contain  dark:brightness-500 dark:filter dark:invert dark:brightness-800' />
                    </div>
                    <div className='text-black text-2xl font-semibold lg:font-bold dark:text-white'>Sign in</div>
                </div>
                <InputWithLabel
                    title={'Email address'}
                    name="email"
                    placeholder='Enter email'
                    password={false}
                    type={'email'}
                />
                <InputWithLabel
                    title={'Password'}
                    name="password"
                    placeholder='Enter Password'
                    password={true}
                    type={'password'}
                />
                <Checkbox
                    title={'Remember me'}
                    name={'remember'}
                />
                <Button
                    title={'Sign in'}
                    type={'submit'}
                    textSize={'text-xs md:text-sm px-4'}
                    height={'h-8 md:h-10'}
                />

            </form>
        </div>
    )
}
