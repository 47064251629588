import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { useTheme } from '../../../Theme';


ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
);



const labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', "Nov", "Dec"]

export const LineChart = ({ label, dataList }) => {

    const { theme } = useTheme()

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'bottom',
                labels: {
                    boxWidth: 12, // Change this line to set the width
                    boxHeight: 12, // Change this line to set the height
                },
            },
            title: {
                display: false,
                text: 'Chart.js Line Chart',
            },        
        },
        scales: {
            x: {
                grid: {
                    display: false, // Add this line
                },
            },
            y: {
                grid: {
                    color: theme == 'dark' ? '#313133' : '#F7F7F7',    
                },
            },
        },
    };

    const data = {
        labels,
        datasets: [
            {
                label: label,
                data: dataList,
                borderColor: '#f59e09',
                backgroundColor: 'rgba(255, 251, 235, 0.5)',
                borderWidth: 2,
                pointBackgroundColor: '#f59e09',                
                fill:true
            },
        ],
    };
    return <Line options={options} data={data} />;
}
