import React from 'react'
import { FaPlus } from 'react-icons/fa6'

export const CustomSelect = ({ options, onSelect, name }) => {
    return (
        <div className='border bg-white dark:bg-transparent rounded-md px-4 py-2 text-sm border-gray-100 dark:border-gray-dark'>
            <select
                onChange={onSelect}
                name={name}
                className='w-full outline-none bg-transparent'>
                {
                    options.map((option, index) => {
                        return (
                            <option key={index} value={option.value}>{option.label}</option>
                        )
                    })
                }
            </select>
        </div>
    )
}

export const CustomSelectWithLabel = ({ title, options, onSelect, isRequired, name, button, onPressAddButton , value }) => {
    return <div className='flex flex-col space-y-2'>
        <label htmlFor={name} className='text-sm whitespace-nowrap text-black dark:text-white font-semibold max-w-min'>
            <div className='relative'>{title}
                {isRequired && <div className='text-red absolute -top-1.5 -right-2'>*</div>}
            </div>
        </label>
        <div className='border flex rounded-md text-sm border-gray-100 dark:border-gray-dark'>
            <div className={`py-2 ${button ? 'w-[90%]' : 'w-full'} px-5`}>
                <select
                    name={name}
                    onChange={onSelect}
                    value={value}
                    className='w-full outline-none bg-transparent'>
                    {options.map((option, index) => {
                        return (
                            <option key={index} value={option.value}>{option.label}</option>
                        )
                    })}
                </select>
            </div>
            {
                button &&
                <div
                    onClick={onPressAddButton}
                    className='cursor-pointer text-gray-icon flex items-center justify-center border-l border-gray-100 dark:border-gray-dark w-10 h-full'>
                    <FaPlus />
                </div>
            }
        </div>
    </div>

}
