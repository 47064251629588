import React from 'react'
import PropTypes from 'prop-types'
import { IoEye, IoEyeOff } from "react-icons/io5";

export const InputWithLabel = ({
    title,
    name,
    placeholder,
    password,
    type,
    isRequired = true,
    disabled = false,
    value
}) => {
    const [isPasswordShowing, setIsPasswordShowing] = React.useState(false)

    return (
        <div className='flex flex-col space-y-2'>
            <label htmlFor={name} className='text-sm whitespace-nowrap text-black dark:text-white font-semibold max-w-min'>
                <div className='relative'>{title}
                    {isRequired && <div className='text-red absolute -top-1.5 -right-2'>*</div>}
                </div>
            </label>
            <div className='w-full'>
                {
                    type == 'color' ?
                        <label 
                        htmlFor={name}
                        className='border-[1.5px] flex items-center justify-between  w-full  text-sm  px-4 placeholder-gray-200 border-gray-100 dark:border-gray-dark  h-8 lg:h-[36px] rounded-lg focus:outline-none focus:ring-2 focus:ring-orange'>
                            <div className='w-5'>

                            </div>
                            <div className='h-6 w-6 flex items-center justify-center overflow-hidden rounded-full'>
                            <input
                                type={type}
                                name={name}
                                id={name}
                                autoComplete='off'
                                placeholder={placeholder}
                                defaultValue={'white'}
                                disabled={disabled}
                                className='h-10 w-10 object-cover'
                            />
                            </div>
                        </label> : 
                    password ?
                <div className='border hover:border-2 hover:border-orange hover:duration-300 flex items-center w-full text-black   placeholder-gray-200 border-gray-100 dark:border-gray-dark h-8 lg:h-[36px] rounded-lg'>
                    <input
                        type={isPasswordShowing ? 'text' : 'password'}
                        name={name}
                        id={name}
                        placeholder={placeholder}
                        autoComplete='off'
                        disabled={disabled}
                        className='w-[90%] bg-transparent px-4 outline-none text-sm  text-black dark:text-white  placeholder-gray-200'
                    />
                    <div className='w-[10%] min-w-10  border-l flex items-center justify-center border-gray-100 dark:border-gray-dark text-lg md:text-2xl h-full'>
                        {
                            isPasswordShowing ?
                                <IoEyeOff
                                    className='text-gray-300 cursor-pointer'
                                    onClick={() => setIsPasswordShowing(false)}
                                /> :
                                <IoEye
                                    className='text-gray-300 cursor-pointer'
                                    onClick={() => setIsPasswordShowing(true)}
                                />
                        }

                    </div>
                </div> :
                <input
                    type={type}
                    name={name}
                    id={name}
                    autoComplete='off'
                    value={value}
                    placeholder={placeholder}
                    disabled={disabled}
                    className='border-[1.5px] dark:bg-black-SECONDARY w-full text-black dark:text-white text-sm  px-4 placeholder-gray-200 border-gray-100 dark:border-gray-dark  h-8 lg:h-[36px] rounded-lg focus:outline-none focus:ring-2 focus:ring-orange'
                />
                }

            </div>
        </div>
    )
}

InputWithLabel.propTypes = {
    title: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    password: PropTypes.bool.isRequired,
    placeholder: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    isRequired: PropTypes.bool,
    disabled: PropTypes.bool
}