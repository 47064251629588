import React from 'react'


import { CardLayout, OrdersCard } from '../../../Components/Molecules'
import { DataTable } from '../../../Components/Organism/table'

import { BiSolidEdit } from "react-icons/bi";
import { useAppNavigate } from '../../../Hooks'
import { BsCheck } from 'react-icons/bs'
import { FaTimes } from 'react-icons/fa';
import { ModalComponent } from '../../../Components/Organism';

export const ProductsPage = () => {

    const navigate = useAppNavigate()
    const [modal, setModal] = React.useState(false)

    const statusColor = {
        'No': "bg-red-light text-red border-2 border-red ",
        'Yes': "bg-green-light text-green border-2 border-green ",
    }

    const statusIcons = {
        'Yes': <BsCheck />,
        'No': <FaTimes className='text-xs' />,
    }


    const columns = [
        {
            name: "number",
            label: "Number",
            filter: true,
            sort: true,
            sortOrder: "desc",
            visible: true,
        },
        {
            name: "customer",
            label: "Customer",
            filter: true,
            sort: true,
            sortOrder: "desc",
            visible: true,
        },
        {
            name: "visibility",
            label: "Visibility",
            filter: true,
            sort: true,
            sortOrder: "desc",
            visible: true,
            customComponent: (props) => {
                return (
                    <div className='flex items-center justify-center'>
                        <span className={`w-5 h-5 p-1 flex items-center space-x-1 justify-center text-xs leading-5 font-semibold rounded-full ${statusColor[props?.visibility]} p-0.5 `}>
                            <div className='text-sm'>{statusIcons[props.visibility]}</div>
                        </span>
                    </div>
                );
            },
        },

        {
            name: "currency",
            label: "Currency",
            filter: false,
            sort: true,
            sortOrder: "desc",
            visible: true,
        },
        {
            name: "total_price",
            label: "Total Price",
            filter: false,
            sort: true,
            sortOrder: "desc",
            visible: true,
        },

        {
            name: "shipping_cost",
            label: "Shipping Cost",
            filter: true,
            sort: false,
            sortOrder: "desc",
            visible: true,
        },
        {
            name: "order_date",
            label: "Order Date",
            filter: false,
            sort: true,
            sortOrder: "desc",
            visible: true,
        },
        {
            name: "",
            label: "Action",
            filter: true,
            sort: true,
            sortOrder: "desc",
            visible: true,
            customComponent: (props) => {
                return (
                    <div
                        onClick={() => navigate(`/shop/products/product/${props.id}/edit`)}
                        className='text-orange cursor-pointer flex items-center justify-center space-x-1 text-sm'>
                        <div><BiSolidEdit className='text-lg' /></div>
                        <div className='font-semibold'>Edit</div>
                    </div>
                );
            },
        },

    ];

    const data = [
        {
            id: 1,
            order_date: "2021-04-01",
            number: "1001",
            customer: "John Doe",
            visibility: 'Yes',
            currency: "USD",
            total_price: "100",
            shipping_cost: "10",
        },
        {
            id: 2,
            order_date: "2021-04-02",
            number: "1002",
            customer: "John Doe",
            status: "Cancelled",
            currency: "USD",
            visibility: 'No',
            total_price: "100",
            shipping_cost: "10",
        },
        {
            id: 2,
            order_date: "2021-04-02",
            number: "1002",
            customer: "John Doe",
            status: "Processing",
            currency: "USD",
            total_price: "100",
            shipping_cost: "10",
            visibility: 'No',
        },
    ]

    const options = {
        paginationTop: true,
        paginationBottom: true,
    };

    return (
        <div className='mt-4 space-y-4'>
            <div className='grid md:grid-cols-3 gap-4'>
                <OrdersCard title='Total Product' amount='100' image={false} />
                <OrdersCard title='Product Inventory' amount='10002' image={false} />
                <OrdersCard title='Average Price' amount='10012' image={false} />
            </div>
            <CardLayout showTitle={false} title='' content={<DataTable
                data={data}
                groupBy={false}
                deleteRowsFilter={true}
                columnRowsFilter={true}
                columns={columns} options={options} />} />
        </div>
    )
}
