import React from 'react'

export const Tabs = ({ list , setTab , tab ,iconStatus}) => {
    return (
        <div className='border  overflow-x-scroll bg-white dark:bg-transparent flex space-x-2 text-xs lg:text-sm font-medium text-gray-text border-gray-100 dark:border-gray-dark p-1.5 rounded-xl'>
            {list.map((item) =>
                <div                
                    className={`px-2 md:px-4 py-2 space-x-2 flex items-center cursor-pointer hover:bg-gray-100 rounded-md ${item.id == tab && 'bg-gray-50 dark:bg-black-SECONDARY text-orange'}`}
                    key={item.id}
                    onClick={() => setTab(item.id)}
                >   
                    {iconStatus && <div className={`${item.id != tab && 'text-gray-icon'}`}>{item.icon}</div>}
                    <div className='line-clamp-1'>{item.name}</div>
                    
                </div>
            )
            }
        </div>
    )
}

