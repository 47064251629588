import React from 'react'
import { PostsTable, Layout } from '../../../Components/Organism'

export const PostPage = () => {
    return (
        <Layout>
            <div className='p-5 md:p-10 font-inter'>
                <PostsTable />
            </div>
        </Layout>
    )
}
