import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export const RichTextEditor = ({ value, onChangeValue, isRequired, name, title }) => {


    return <div className='flex flex-col space-y-2'>
        <label htmlFor={name} className='text-sm whitespace-nowrap text-black dark:text-white font-semibold max-w-min'>
            <div className='relative'>{title}
                {isRequired && <div className='text-red absolute -top-1.5 -right-2'>*</div>}
            </div>
        </label>

        <ReactQuill
        className='custom-quill'
        theme="snow" value={value} onChange={onChangeValue} />
    </div>
}