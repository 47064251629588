import React from 'react'
import { Button, Checkbox, CustomSelectWithLabel, InputWithLabel, PageHeading, Switch, UploadFile } from '../../../../Components/Atoms'
import { useAppNavigate } from '../../../../Hooks'
import { CommentsTable, DeleteModalComponent, Layout, ModalComponent } from '../../../../Components/Organism'
import { BreadCrum, CardLayout, RichTextEditor } from '../../../../Components/Molecules'
import { FaChevronUp } from 'react-icons/fa6'
import { FaTimes } from 'react-icons/fa'
import { UploadModalComponent } from '../../../../Components/Organism/modal/uploadModal'


export const EditProduct = () => {
    const navigate = useAppNavigate()
    const [deleteModal, setDeleteModal] = React.useState(false)
    const [commentModal, setCommentModal] = React.useState(false)

    const onSubmitForm = (e) => {
        e.preventDefault()
        const formData = new FormData(e.target)
        const data = Object.fromEntries(formData)
        console.log(data)
        // navigate('/dashboard')
    }

    const ModalBody = (

        <form
            onSubmit={onSubmitForm}
            className='p-5 border dark:border-gray-dark dark:bg-black-PRIMARY rounded-md dark:text-white'
        >
            <div className='flex items-center justify-between pb-6'>
                <div className='text-xl font-semibold'>
                    Create Comment
                </div>
                <div>
                    <FaTimes
                        onClick={() => setCommentModal(!commentModal)}
                        className='text-gray-icon cursor-pointer' />
                </div>
            </div>
            <div
                className=' grid md:grid-cols-2 gap-4'
            >
                <InputWithLabel
                    title={'Name'}
                    name="name"
                    placeholder='Enter name'
                    password={false}
                    type={'text'}
                    isRequired={true}
                />
                <InputWithLabel
                    title={'Slug'}
                    name="slug"
                    placeholder='Enter slug'
                    password={false}
                    type={'text'}
                    disabled={true}
                    isRequired={false}
                />
                <div className='md:col-span-2'>
                    <InputWithLabel
                        title={'Website'}
                        name="text"
                        placeholder='Enter Website'
                        password={false}
                        type={'text'}
                        isRequired={true}
                    />
                </div>
                <div>
                    <Switch title="Visible to Customers." />
                </div>

                <div className='md:col-span-2'>
                    <RichTextEditor
                        name="description"
                        title="Description"
                        isRequired={false}
                    />
                </div>

            </div>

            <div className='flex items-center mt-5 space-x-2'>
                <Button
                    title={'Create'}
                    type={'submit'}
                    textSize={'text-xs md:text-sm max-w-min px-4'}
                    height={'h-8 md:h-10'}
                />
                <Button
                    title={'Create & create another'}
                    type={'submit'}
                    textSize={'text-xs md:text-sm  px-4'}
                    height={'h-8 md:h-10'}
                    background={'bg-white'}
                    color="text-black"
                    border={true}
                />
                <Button
                    title={'Cancel'}
                    type={'button'}
                    onButtonPressed={() => navigate('/shop/customers')}
                    textSize={'text-xs md:text-sm max-w-min px-4'}
                    height={'h-8 md:h-10'}
                    background={'bg-white'}
                    color="text-black"
                    border={true}
                />
            </div>
        </form>
    )

    return (
        <Layout>
            <div className='p-5 md:p-10 font-inter'>
                <div className='py-1.5'>
                    <BreadCrum content={[
                        {
                            title: 'Products',
                            url: '/shop/products'
                        },
                        {
                            title: 'Bette Swaniawski', // replace customer name
                            url: '/shop/products/product/1/edit'
                        },
                        {
                            title: 'Edit', //
                            url: '/shop/products/product/1/edit'
                        },

                    ]} />
                </div>
                <div className='flex items-center justify-between'>
                    <PageHeading title='Edit Total contextually' />
                    <Button
                        title={'Delete'}
                        type={'button'}
                        textSize={'text-xs md:text-sm  px-4'}
                        height={'h-9'}
                        background={'bg-red'}
                        color="text-white"
                        border={false}
                        onButtonPressed={() => setDeleteModal(!deleteModal)}
                    />
                </div>

                <DeleteModalComponent
                    onModalStatus={() => setDeleteModal(!deleteModal)}
                    modal={deleteModal}
                    type={'delete'}
                />
                <form
                    onSubmit={onSubmitForm}
                    className='mt-5 space-y-6'
                >
                    <div className='grid lg:grid-cols-3 gap-3'>
                        <div className='space-y-6 col-span-2'>
                            <div
                                className=' grid md:grid-cols-2 gap-4 border border-gray-100 dark:border-gray-dark  bg-white dark:bg-transparent rounded-xl px-5 pt-6 pb-10'
                            >
                                <InputWithLabel
                                    title={'Name'}
                                    name="name"
                                    placeholder='Enter name'
                                    password={false}
                                    type={'text'}
                                    isRequired={true}
                                />
                                <InputWithLabel
                                    title={'Slug'}
                                    name="slug"
                                    placeholder='Enter slug'
                                    password={false}
                                    type={'text'}
                                    disabled={true}
                                    isRequired={false}
                                />

                                <div className='md:col-span-2'>
                                    <RichTextEditor
                                        name="description"
                                        title="Description"
                                        isRequired={false}
                                    />
                                </div>

                            </div>

                            <div className='mt-4'>
                                <CardLayout showTitle={true} title='Images'
                                    button={false}
                                    customButtonsStatus={true}
                                    customButtons={
                                        <div className=''>
                                            <FaChevronUp className='text-gray-text' />
                                        </div>
                                    }
                                    buttonTitle={'New Address'}
                                    buttonType={'orange'}
                                    content={<div className='p-5'>
                                        <UploadModalComponent/>

                                    </div>}
                                />
                            </div>

                            <CardLayout
                                title='Pricing'
                                showTitle={true}
                                content={
                                    <div
                                        className=' grid md:grid-cols-2 gap-4 px-5 pt-6 pb-10'
                                    >
                                        <InputWithLabel
                                            title={'Price'}
                                            name="price"
                                            placeholder='Enter price'
                                            password={false}
                                            type={'number'}
                                            isRequired={true}
                                        />
                                        <InputWithLabel
                                            title={'Cost per item'}
                                            name="compare_at_price"
                                            placeholder='Enter price'
                                            password={false}
                                            type={'number'}
                                            disabled={true}
                                            isRequired={false}
                                        />
                                        <div className='flex flex-col space-y-1'>
                                            <InputWithLabel
                                                title={'Cost per item'}
                                                name="cost_per_item"
                                                placeholder='Enter price'
                                                password={false}
                                                type={'number'}
                                                disabled={false}
                                                isRequired={true}
                                            />

                                            <div className='text-gray-text font-regular text-xs md:text-sm'>
                                                Customers won't see this price.
                                            </div>
                                        </div>

                                    </div>
                                }
                            />
                            <CardLayout
                                title='Inventory'
                                showTitle={true}
                                content={
                                    <div
                                        className=' grid md:grid-cols-2 gap-4 px-5 pt-6 pb-10'
                                    >
                                        <InputWithLabel
                                            title={'SKU (Stock Keeping Unit)'}
                                            name="sku"
                                            placeholder='Enter SKU (Stock Keeping Unit)*'
                                            password={false}
                                            type={'text'}
                                            isRequired={true}
                                        />
                                        <InputWithLabel
                                            title={'Barcode (ISBN, UPC, GTIN, etc.)'}
                                            name="barcode"
                                            placeholder='Enter Barcode (ISBN, UPC, GTIN, etc.)'
                                            password={false}
                                            type={'text'}
                                            disabled={true}
                                            isRequired={true}
                                        />

                                        <InputWithLabel
                                            title={'Quantity'}
                                            name="quantity"
                                            placeholder='Enter Quantity'
                                            password={false}
                                            type={'number'}
                                            disabled={true}
                                            isRequired={true}
                                        />

                                        <div className='flex flex-col space-y-1'>
                                            <InputWithLabel
                                                title={'Security stock'}
                                                name="security_stock"
                                                placeholder='Enter Security stock'
                                                password={false}
                                                type={'number'}
                                                disabled={true}
                                                isRequired={true}
                                            />

                                            <div className=' font-medium text-xs md:text-sm text-gray-text'>
                                                The safety stock is the limit stock for your products which alerts you if the product stock will soon be out of stock.
                                            </div>
                                        </div>

                                    </div>
                                }
                            />
                            <CardLayout
                                title='Shipping'
                                showTitle={true}
                                content={
                                    <div
                                        className=' grid md:grid-cols-2 gap-4 px-5 pt-6 pb-10'
                                    >
                                        <Checkbox
                                            title={'This product can be returned'}
                                        />
                                        <Checkbox
                                            title={'This product will be shipped'}
                                        />

                                    </div>
                                }
                            />
                        </div>
                        <div className='space-y-6'>
                            <CardLayout
                                title={'Status'}
                                showTitle={true}
                                content={<div className='p-6 space-y-4'>
                                    <div className='flex flex-col space-y-1'>
                                        <Switch
                                            title={'Visible'}
                                        />
                                        <div className='text-gray-card_heading text-sm'>
                                            This product will be hidden from all sales channels.
                                        </div>
                                    </div>

                                    <InputWithLabel
                                        title="Availability"
                                        name="availability"
                                        placeholder='Enter Availability'
                                        password={false}
                                        type={'date'}
                                        disabled={false}
                                    />

                                </div>}
                            />
                            <CardLayout
                                title={'Status'}
                                showTitle={true}
                                content={<div className='p-6 space-y-4'>
                                    <CustomSelectWithLabel
                                        title={'Brand'}
                                        name="brand"
                                        placeholder='Select Brand'
                                        options={[]}
                                    />
                                    <CustomSelectWithLabel
                                        title={'Categories'}
                                        name="category"
                                        placeholder='Select Category'
                                        options={[]}
                                        isRequired={true}
                                    />
                                </div>}
                            />

                        </div>
                    </div>

                    <div className='flex items-center mt-5 space-x-2'>
                        <Button
                            title={'Save Changes'}
                            type={'submit'}
                            textSize={'text-xs md:text-sm px-4'}
                            height={'h-8 md:h-10'}
                        />

                        <Button
                            title={'Cancel'}
                            type={'button'}
                            onButtonPressed={() => navigate('/shop/products')}
                            textSize={'text-xs md:text-sm  px-4'}
                            height={'h-8 md:h-10'}
                            background={'bg-white'}
                            color="text-black"
                            border={true}
                        />
                    </div>
                </form>
                <div className='mt-10'>
                    <CommentsTable onAddCommentModalToggle={() => setCommentModal(!commentModal)}
                    />
                </div>
                <ModalComponent
                    style="w-11/12 sm:w-10/12 md:w-1/2  2xl:w-[22%] top-[25%] lg:top-[30%] "
                    modal={commentModal}
                    onModalStatus={() => setCommentModal(!commentModal)}
                    content={ModalBody}
                />

            </div>
        </Layout>
    )
}
