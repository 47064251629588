import React from 'react'
import { CardLayout } from '../../Molecules';
import { DataTable } from '.';
import { BsCheck } from 'react-icons/bs';
import { BiSolidEdit } from 'react-icons/bi';
import { FaTimes } from 'react-icons/fa';
import { useAppNavigate } from '../../../Hooks';
import { Button } from '../../Atoms';
import { DeleteModalComponent } from '../modal/delete';
import { ImBin } from 'react-icons/im';

export const CommentsTable = ({ onAddCommentModalToggle }) => {
    const navigate = useAppNavigate();
    const [deleteModal, setDeleteModal] = React.useState(false);

    const statusColor = {
        'No': "bg-red-light dark:bg-transparent text-red border-2 border-red ",
        'Yes': "bg-green-light dark:bg-transparent text-green border-2 border-green ",
    }

    const statusIcons = {
        'Yes': <BsCheck />,
        'No': <FaTimes className='text-xs' />,
    }


    const columns = [
        {
            name: "number",
            label: "Number",
            filter: true,
            sort: true,
            sortOrder: "desc",
            visible: true,
        },
        {
            name: "customer",
            label: "Customer",
            filter: true,
            sort: true,
            sortOrder: "desc",
            visible: true,
        },
        {
            name: "visibility",
            label: "Visibility",
            filter: true,
            sort: true,
            sortOrder: "desc",
            visible: true,
            customComponent: (props) => {
                return (
                    <div className='flex items-center justify-center'>
                        <span className={`w-5 h-5 p-1 flex items-center space-x-1 justify-center text-xs leading-5 font-semibold rounded-full ${statusColor[props?.visibility]} p-0.5 `}>
                            <div className='text-sm'>{statusIcons[props.visibility]}</div>
                        </span>
                    </div>
                );
            },
        },

        {
            name: "currency",
            label: "Currency",
            filter: false,
            sort: true,
            sortOrder: "desc",
            visible: true,
        },
        {
            name: "total_price",
            label: "Total Price",
            filter: false,
            sort: true,
            sortOrder: "desc",
            visible: true,
        },

        {
            name: "shipping_cost",
            label: "Shipping Cost",
            filter: true,
            sort: false,
            sortOrder: "desc",
            visible: true,
        },
        {
            name: "order_date",
            label: "Order Date",
            filter: false,
            sort: true,
            sortOrder: "desc",
            visible: true,
        },
        {
            name: "",
            label: "Action",
            filter: true,
            sort: true,
            sortOrder: "desc",
            visible: true,
            customComponent: (props) => {
                return (
                    <div className='flex items-center justify-center space-x-3'>
                        <div
                            onClick={onAddCommentModalToggle}
                            className='text-orange cursor-pointer flex items-center justify-center space-x-1 text-sm'>
                            <div><BiSolidEdit className='text-lg' /></div>
                            <div className='font-medium'>Edit</div>
                        </div>
                        <div
                            onClick={() => setDeleteModal(!deleteModal)}
                            className='text-red cursor-pointer flex items-center justify-center space-x-1 text-sm'>
                            <div><ImBin className='text-lg' /></div>
                            <div className='text-red font-medium'>Delete</div>
                        </div>
                    </div>

                );
            },
        },

    ];

    const data = [
        {
            id: 1,
            order_date: "2021-04-01",
            number: "1001",
            customer: "John Doe",
            visibility: 'Yes',
            currency: "USD",
            total_price: "100",
            shipping_cost: "10",
        },
        {
            id: 2,
            order_date: "2021-04-02",
            number: "1002",
            customer: "John Doe",
            status: "Cancelled",
            currency: "USD",
            visibility: 'No',
            total_price: "100",
            shipping_cost: "10",
        },
        {
            id: 2,
            order_date: "2021-04-02",
            number: "1002",
            customer: "John Doe",
            status: "Processing",
            currency: "USD",
            visibility: 'No',
            total_price: "100",
            shipping_cost: "10",
        },
    ]

    const options = {
        paginationTop: true,
        paginationBottom: true,
    };


    return (
        <div className='mt-4'>

            <DeleteModalComponent
                onModalStatus={() => setDeleteModal(!deleteModal)}
                modal={deleteModal}
                type={'delete'}
            />
            <CardLayout showTitle={true} title='Address'
                button={false}
                customButtons={
                    <div className=''>
                        <Button
                            type='button'
                            textSize={'text-xs md:text-sm px-4'}
                            height={'h-8 md:h-9'}
                            background={'bg-orange'}
                            color={'text-white'}
                            title={'New Comment'}
                            border={true}
                            onButtonPressed={onAddCommentModalToggle}
                        />
                    </div>
                }
                customButtonsStatus={true}
                buttonTitle={'Add Comment'}
                content={<DataTable
                    data={data}
                    groupBy={false}
                    columns={columns} options={options} />} />
        </div>


    )
}
