import LOGO from './images/logo.png'
import AVATAR from './images/avatar.svg'
import INCREASE from './images/increase.png'
import DECREASE from './images/decrease.png'
import INCREASE_2 from './images/increase-2.png'
import GRAY_WAVE from './images/gray-wave.png'

export const ASSETS = {
    LOGO: LOGO,
    AVATAR: AVATAR,
    DASHBOARD:{
        CARD:{
            INCREASE,
            DECREASE,
            INCREASE_2,
            GRAY_WAVE
        }
    }
}