import React from 'react'

export const Switch = ({ onChangeSwitch , title }) => {
    return (
        <label htmlFor="default-toggle" className="inline-flex relative items-center cursor-pointer">
            <input
                onChange={onChangeSwitch}
                type="checkbox" value="" id="default-toggle" className="sr-only peer" />
            <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full  after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white  after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-orange"></div>
            <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">{title}</span>
        </label>
    )
}
