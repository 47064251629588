import React from "react";
import Modal from "react-modal";

const customStyles = {
    content: {
        position: "absolute",
        border: "none",
        background: "#fff",
        outline: "none",
        padding: "0px",
        zIndex: "9999 !important",
    },
};

Modal.defaultStyles.overlay.backgroundColor = "rgba(0,0,0,0.3)";

export const ModalComponent = ({
    content,
    modal,
    onModalStatus,
    defaultStyle,
    style,
    id
}) => {
    return (
        <Modal
            id={id}
            className={` ${defaultStyle ? "w-11/12 sm:w-10/12 md:w-8/12 xl:w-7/12 top-[25%] lg:top-[15%] 2xl:top-[20%] rounded-lg shadow-equal_dark" : style
                } mx-auto absolute right-0 left-0 rounded-xl shadow-equal_dark`}
            isOpen={modal}
            onRequestClose={onModalStatus}
            style={customStyles}
            overlayClassName=""
        >
            {content}
        </Modal>
    );
};

Modal.setAppElement("#root");