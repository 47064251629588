import React from 'react'
import { Button, InputWithLabel, PageHeading, Switch } from '../../../../Components/Atoms'
import { useAppNavigate } from '../../../../Hooks'
import { Layout } from '../../../../Components/Organism'
import { BreadCrum, RichTextEditor } from '../../../../Components/Molecules'

export const AddCategory = () => {
    const navigate = useAppNavigate()

    const onSubmitForm = (e) => {
        e.preventDefault()
        const formData = new FormData(e.target)
        const data = Object.fromEntries(formData)
        console.log(data)
        // navigate('/dashboard')
    }

    return (
        <Layout>
            <div className='p-5 md:p-10 font-inter'>
                <div className='py-1.5'>
                    <BreadCrum content={[
                        {
                            title: 'Products',
                            url: '/shop/products'
                        },
                        {
                            title: 'Categories',
                            url: '/shop/products'
                        },
                        {
                            title: 'Create',
                            url: '/shop/products/categories/add'
                        },

                    ]} />
                </div>

                <PageHeading title='Create Category' />
                <form
                    onSubmit={onSubmitForm}
                    className='mt-5'
                >
                    <div
                        className=' grid md:grid-cols-2 gap-4 border border-gray-100 dark:border-gray-dark  bg-white dark:bg-transparent rounded-xl px-6 md:px-8 pt-6 pb-10'
                    >
                        <InputWithLabel
                            title={'Name'}
                            name="name"
                            placeholder='Enter name'
                            password={false}
                            type={'text'}
                            isRequired={true}
                        />
                        <InputWithLabel
                            title={'Slug'}
                            name="slug"
                            placeholder='Enter slug'
                            password={false}
                            type={'text'}
                            disabled={true}
                            isRequired={false}
                        />
                        <div className='md:col-span-2'>
                            <InputWithLabel
                                title={'Parent'}
                                name="text"
                                placeholder='Enter Parent'
                                password={false}
                                type={'text'}
                                isRequired={false}
                            />
                        </div>
                        <div>
                            <Switch title="Visible to Customers." />
                        </div>

                        <div className='md:col-span-2'>
                            <RichTextEditor
                                name="description"
                                title="Description"
                                isRequired={false}
                            />
                        </div>

                    </div>

                    <div className='flex items-center mt-5 space-x-2'>
                        <Button
                            title={'Create'}
                            type={'submit'}
                            textSize={'text-xs md:text-sm max-w-min px-4'}
                            height={'h-8 md:h-10'}
                        />
                        <Button
                            title={'Create & create another'}
                            type={'submit'}
                            textSize={'text-xs md:text-sm  px-4'}
                            height={'h-8 md:h-10'}
                            background={'bg-white'}
                            color="text-black"
                            border={true}
                        />
                        <Button
                            title={'Cancel'}
                            type={'button'}
                            onButtonPressed={() => navigate('/shop/customers')}
                            textSize={'text-xs md:text-sm max-w-min px-4'}
                            height={'h-8 md:h-10'}
                            background={'bg-white'}
                            color="text-black"
                            border={true}
                        />
                    </div>
                </form>
            </div>
        </Layout>
    )
}
