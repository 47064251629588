import React from 'react'

export const Checkbox = ({ title, name , onPressCheckBox }) => {
    return (
        <div className="flex items-center mt-4">
            <input 
            onChange={onPressCheckBox}
            type="checkbox" id={name} name={name} className="rounded  bg-orange h-4 w-4" />
            <label htmlFor={name} className="ml-2 text-sm text-black dark:text-white font-medium">{title}</label>
        </div>

    )
}
