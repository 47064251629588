import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { FaTimes } from 'react-icons/fa';

export const MultiInputWithLabel = ({
    title,
    name,
    placeholder,
    type,
    isRequired = true,
    disabled = false
}) => {
    const [tags, setTags] = useState([]);

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            setTags([...tags, event.target.value]);
            event.target.value = '';
        }
    }

    const removeTag = (indexToRemove) => {
        setTags(tags.filter((_, index) => index !== indexToRemove));
    }

    return (
        <div className='flex flex-col space-y-2'>
            <label htmlFor={name} className='text-sm whitespace-nowrap text-black dark:text-white font-semibold max-w-min'>
                <div className='relative'>{title}
                    {isRequired && <div className='text-red absolute -top-1.5 -right-2'>*</div>}
                </div>
            </label>
            <div className='w-full'>
                <input
                    type={type}
                    name={name}
                    id={name}
                    autoComplete='off'
                    placeholder={placeholder}
                    disabled={disabled}
                    onKeyDown={handleKeyDown}
                    className='border-[1.5px] bg-transparent w-full text-black dark:text-white text-sm  px-4 placeholder-gray-200 border-gray-100  h-8 lg:h-[36px] rounded-lg focus:outline-none focus:ring-2 focus:ring-orange'
                />
            </div>
            <div className='flex items-center space-x-2'>
                {tags.map((tag, index) => (
                    <div
                        key={index}
                        className="tag flex items-center bg-orange-light text-orange text-xs px-1 py-1 space-x-1">
                        <div>{tag}</div>
                        <FaTimes onClick={() => removeTag(index)} />
                    </div>
                ))}
            </div>
        </div>
    )
}

MultiInputWithLabel.propTypes = {
    title: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    isRequired: PropTypes.bool,
    disabled: PropTypes.bool
}