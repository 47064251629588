import React from 'react'

import { PageHeading } from '../../Components/Atoms'
import { CardLayout, DashboardCard } from '../../Components/Molecules'
import { Layout, LineChart } from '../../Components/Organism'
import { DataTable } from '../../Components/Organism/table'
import { BsStars } from "react-icons/bs";
import { IoIosCloseCircle } from "react-icons/io";
import { LuRefreshCw } from "react-icons/lu";

export const Dashboard = () => {

    const data = [
        {
            id: 1,
            order_date: "2021-04-01",
            number: "1001",
            customer: "John Doe",
            status: "New",
            currency: "USD",
            total_price: "100",
            shipping_cost: "10",
        },
        {
            id: 2,
            order_date: "2021-04-02",
            number: "1002",
            customer: "John Doe",
            status: "Cancelled",
            currency: "USD",
            total_price: "100",
            shipping_cost: "10",
        },
        {
            id: 2,
            order_date: "2021-04-02",
            number: "1002",
            customer: "John Doe",
            status: "Processing",
            currency: "USD",
            total_price: "100",
            shipping_cost: "10",
        },
    ]

    const statusColor = {
        'New': "bg-blue-light dark:bg-transparent text-blue border border-blue border-opacity-20",
        'Processing': "bg-orange-light dark:bg-transparent text-orange border border-orange border-opacity-20",
        'Cancelled': "bg-red-light dark:bg-transparent text-red border border-red border-opacity-20",
        'Completed': "bg-green-light dark:bg-transparent text-green border border-green border-opacity-20",
    }

    const statusIcons = {
        'New': <BsStars />,
        'Processing': <LuRefreshCw />,
        'Cancelled': <IoIosCloseCircle />,
        'Completed': <BsStars />,
    }

    const columns = [
        {
            name: "order_date",
            label: "Order Date",
            filter: false,
            sort: true,
            sortOrder: "desc",
            visible: true,
        },
        {
            name: "number",
            label: "Number",
            filter: true,
            sort: true,
            sortOrder: "desc",
            visible: true,
        },
        {
            name: "customer",
            label: "Customer",
            filter: true,
            sort: true,
            sortOrder: "desc",
            visible: true,
        },
        {
            name: "status",
            label: "Status",
            filter: true,
            sort: true,
            sortOrder: "desc",
            visible: true,
            customComponent: (props) => {



                return (
                    <div>
                        <span className={`px-2 max-w-min flex items-center space-x-1 justify-center text-xs leading-5 font-semibold rounded ${statusColor[props?.status]} p-0.5 `}>
                            <div className='text-sm'>{statusIcons[props.status]}</div> <div>{props?.status}</div>
                        </span>
                    </div>
                );
            },
        },
        {
            name: "currency",
            label: "Currency",
            filter: false,
            sort: true,
            sortOrder: "desc",
            visible: true,
        },
        {
            name: "total_price",
            label: "Total Price",
            filter: false,
            sort: true,
            sortOrder: "desc",
            visible: true,
        },

        {
            name: "shipping_cost",
            label: "Shipping Cost",
            filter: true,
            sort: false,
            sortOrder: "desc",
            visible: true,
        },

        // {
        //     name: "",
        //     label: "Actions",
        //     sort: false,
        //     filter: false,
        //     visible: true,
        //     customComponent: (props: any) => {
        //         const routeString = route("asset.map", { filter: `id.${props?.id}` });

        //         const showMapButton = !!props?.long && !!props?.lat;

        //         return (
        //             <Grid gap={8} auto>
        //                 <Link href={props?.edit_url}>
        //                     <Button>Edit</Button>
        //                 </Link>
        //                 <Button
        //                     variant="delete"
        //                     onClick={() => handleDeleteAction(props.id)}
        //                 >
        //                     Delete
        //                 </Button>
        //                 {showMapButton && (
        //                     <Link href={routeString} target="_blank">
        //                         <Button>Map</Button>
        //                     </Link>
        //                 )}
        //             </Grid>
        //         );
        //     },
        // },
    ];

    const options = {
        paginationTop: true,
        paginationBottom: true,
    };

    return (
        <Layout>
            <div className='p-5 md:p-10 font-inter'>
                <PageHeading title='Dashboard' />
                <div className='mt-4 bg-white dark:bg-black-PRIMARY py-4 border border-gray-100 dark:border-gray-dark rounded-xl px-4'>
                    <div className=' grid md:grid-cols-3 gap-4 text-sm'>
                        <div className='flex flex-col space-y-1'>
                            <label className='font-semibold'>
                                Bussiness Customer only
                            </label>
                            <div className='border rounded-md px-4 py-2 text-sm border-gray-100 dark:border-gray-dark dark:bg-black-SECONDARY'>
                                <select className='w-full outline-none bg-transparent'>
                                    <option>-</option>
                                    <option>Yes</option>
                                    <option>No</option>
                                </select>
                            </div>
                        </div>
                        <div className='flex flex-col space-y-1'>
                            <label className='font-semibold'>
                                Start date
                            </label>
                            <div className='border rounded-md px-4 py-2 text-sm border-gray-100 dark:border-gray-dark dark:bg-black-SECONDARY'>
                                <input type='date' className='w-full outline-none bg-transparent text-white'/>
                            </div>
                        </div>
                        <div className='flex flex-col space-y-1'>
                            <label className='font-semibold'>
                                End date
                            </label>
                            <div className='border rounded-md px-4 py-2 text-sm border-gray-100 dark:border-gray-dark dark:bg-black-SECONDARY'>
                                <input type='date' className='w-full outline-none bg-transparent text-white'/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='my-4 grid md:grid-cols-3 gap-4'>
                    <DashboardCard
                        title='Revenue'
                        amount={192.10}
                        type='increase'
                        image='1'
                        percent={false}
                        subAmount={32}
                    />
                    <DashboardCard
                        title='New customers'
                        amount={1.34}
                        type='decrease'
                        image='2'
                        percent={true}
                        subAmount={3}
                    />
                    <DashboardCard
                        title="New orders"
                        amount={3.54}
                        type='increase'
                        image='3'
                        percent={true}
                        subAmount={7}
                    />
                </div>
                <div className='grid lg:grid-cols-2 gap-4'>
                    <CardLayout 
                    showTitle={true}
                    title='Recent Orders' content={<div className='p-5 object-cover w-full'>
                        <LineChart label={'Orders'} dataList={[100, 500, 205, 90, 20, 2000, 30, 2000, 100, 5000, 1000, 8000]} />
                    </div>} />
                    <CardLayout 
                    showTitle={true}
                    title='Total Customers' content={<div className='p-5 object-cover w-full'>
                        <LineChart label={'Customers'} dataList={[100, 500, 205, 90, 20, 2000, 30, 2000, 100, 5000, 1000, 8000]} />
                    </div>} />
                </div>
                <div className='mt-4'>
                    <CardLayout 
                    showTitle={true}
                    title='Latest Orders' content={<DataTable
                        data={data}
                        columns={columns} options={options} />} />
                </div>
            </div>
        </Layout>
    )
}
