import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import {
  Signin,
  Dashboard,
  Customers,
  NewCustomer,
  EditCustomer,
  Orders,
  NewOrder,
  Products,
  AddBrand,
  AddCategory,
  EditOrder,
  AddProduct,
  EditProduct,
  EditCategories,
  EditBrand,
  CategoriesPage,
  AuthorsPage,
  PostPage,
  AddPost,
  PostDetail,
  LinksPage,
  LinkDetail,
  Library,
} from "./Container";

function App() {
  return <DndProvider backend={HTML5Backend}>
    <Router>
      <Routes>
        <Route path="/" element={<Signin />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/shop/customers" element={<Customers />} />
        <Route path="/shop/customers/new" element={<NewCustomer />} />
        <Route path="/shop/customers/:id/edit" element={<EditCustomer />} />
        <Route path="/shop/orders" element={<Orders />} />
        <Route path="/shop/orders/new" element={<NewOrder />} />
        <Route path="/shop/orders/:id/edit" element={<EditOrder />} />
        <Route path="/shop/products" element={<Products />} />

        <Route path="/shop/products/brands/add" element={<AddBrand />} />
        <Route path="/shop/products/brands/:id/edit" element={<EditBrand />} />

        <Route path="/shop/products/categories/add" element={<AddCategory />} />
        <Route path="/shop/products/product/add" element={<AddProduct />} />
        <Route path="/shop/products/product/:id/edit" element={<EditProduct />} />
        <Route path="/shop/products/categories/:id/edit" element={<EditCategories />} />
        {/* BLOGS ROUTES */}
        <Route path="/blog/categories" element={<CategoriesPage />} />
        <Route path="/blog/authors" element={<AuthorsPage />} />
        <Route path="/blog/posts" element={<PostPage />} />
        <Route path="/blog/post/create" element={<AddPost />} />
        <Route path="/blog/post/:id/:type" element={<PostDetail />} />
        <Route path="/blog/links" element={<LinksPage />} />
        <Route path="/blog/link/:type/:id?" element={<LinkDetail />} />
        {/* MEDIA LIBRARY */}
        <Route path="/media/library" element={<Library />} />
      </Routes>
    </Router>;
  </DndProvider>
}

export default App;
