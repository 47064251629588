import React from 'react'
import PropTypes from 'prop-types'

export const TextAreaWithLabel = ({
    title,
    name,
    placeholder,
    type,
    isRequired = true,
    disabled = false
}) => {


    return (
        <div className='flex flex-col space-y-2'>
            <label htmlFor={name} className='text-sm whitespace-nowrap text-black dark:text-white font-semibold max-w-min'>
                <div className='relative'>{title}
                    {isRequired && <div className='text-red absolute -top-1.5 -right-2'>*</div>}
                </div>
            </label>
            <div className='w-full'>
                <textarea
                    rows={5}
                    type={type}
                    name={name}
                    id={name}
                    autoComplete='off'
                    placeholder={placeholder}
                    disabled={disabled}
                    className='border-[1.5px] bg-transparent w-full text-black dark:text-white text-sm  p-4 placeholder-gray-200 border-gray-100 dark:border-gray-dark rounded-lg focus:outline-none focus:ring-2 focus:ring-orange'
                />
            </div>
        </div>
    )
}

TextAreaWithLabel.propTypes = {
    title: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    password: PropTypes.bool.isRequired,
    placeholder: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    isRequired: PropTypes.bool,
    disabled: PropTypes.bool
}