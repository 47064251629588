import React from 'react'

import { Button, Checkbox, PageHeading, UploadFile } from '../../../Components/Atoms'
import { Layout, ModalComponent, SubLayout } from '../../../Components/Organism'
import { useAppNavigate } from '../../../Hooks'
import { ProductsPage } from './product'
import { CategoriesPage } from './categories'
import { BrandsPage } from './brands'
import { BreadCrum } from '../../../Components/Molecules'
import { FaTimes } from 'react-icons/fa'

export const Products = () => {

    const navigate = useAppNavigate()
    const [tab, setTab] = React.useState(1)
    const [importModal, setImportModal] = React.useState(false)
    const [exportModal, setExportModal] = React.useState(false)

    const onSubmitForm = (e) => {
        e.preventDefault()
        const formData = new FormData(e.target)
        const data = Object.fromEntries(formData)
        console.log(data)
        // navigate('/dashboard')
    }

    const ModalBody = (
        <form
            onSubmit={onSubmitForm}
            className='p-5 border dark:border-gray-dark dark:bg-black-PRIMARY rounded-md dark:text-white'
        >
            <div className=' flex items-center justify-between pb-3'>
                <div className='text-xl font-semibold'>
                    Import Categories
                </div>
                <div>
                    <FaTimes
                        onClick={() => setImportModal(!importModal)}
                        className='text-gray-icon cursor-pointer' />
                </div>
            </div>
            <div className='text-orange text-xs md:text-sm pb-2 font-semibold hover:underline hover:duration-300'>
                Download example CSV file
            </div>
            <div>
                <UploadFile
                    title={'Upload a CSV file'}
                    name={'file'}
                    id={'file'}
                    accept={'.csv'}
                />
            </div>

            <div className='flex items-center mt-5 space-x-2'>
                <Button
                    title={'Import'}
                    type={'submit'}
                    textSize={'text-xs md:text-sm  px-4'}
                    height={'h-8 md:h-10'}
                    background={'bg-orange'}
                    color="text-white"
                    border={true}
                />
                <Button
                    title={'Cancel'}
                    type={'button'}
                    onButtonPressed={() => setImportModal(!importModal)}
                    textSize={'text-xs md:text-sm max-w-min px-4'}
                    height={'h-8 md:h-10'}
                    background={'bg-white'}
                    color="text-black"
                    border={true}
                />
            </div>
        </form>
    )

    const ExportModalBody = (
        <form
            onSubmit={onSubmitForm}
            className='p-5 border dark:border-gray-dark dark:bg-black-PRIMARY rounded-md dark:text-white'
        >
            <div className=' flex items-center justify-between pb-3'>
                <div className='text-xl font-semibold'>
                    Export brands
                </div>
                <div>
                    <FaTimes
                        onClick={() => setExportModal(!exportModal)}
                        className='text-gray-icon cursor-pointer' />
                </div>
            </div>
            <div
                className='z bg-white dark:bg-transparent dark:text-gray-dark z-[50]  px-4 py-4 flex flex-col'>
                <div className='font-semibold text-sm lg:text-base dark:text-white'>Columns</div>
                <div className='space-y-4 '>
                    <Checkbox title={'Customer'} />
                    <Checkbox title={'Currency'} />
                    <Checkbox title={'Shipping cost'} />
                    <Checkbox title={'Order ate'} />
                </div>
            </div>


            <div className='flex items-center mt-5 space-x-2'>
                <Button
                    title={'Export'}
                    type={'submit'}
                    textSize={'text-xs md:text-sm  px-4'}
                    height={'h-8 md:h-10'}
                    background={'bg-orange'}
                    color="text-white"
                    border={true}
                />
                <Button
                    title={'Cancel'}
                    type={'button'}
                    onButtonPressed={() => setExportModal(!exportModal)}
                    textSize={'text-xs md:text-sm max-w-min px-4'}
                    height={'h-8 md:h-10'}
                    background={'bg-white'}
                    color="text-black"
                    border={true}
                />
            </div>
        </form>
    )

    return (
        <Layout>
            <ModalComponent
                style="w-11/12 sm:w-10/12 md:w-1/2  2xl:w-[22%] top-[25%] lg:top-[30%] "
                modal={importModal}
                onModalStatus={() => setImportModal(!importModal)}
                content={ModalBody}
            />
            <ModalComponent
                style="w-11/12 sm:w-10/12 md:w-1/2 lg:w-3/12  2xl:w-[22%] top-[25%] lg:top-[30%] "
                modal={exportModal}
                onModalStatus={() => setExportModal(!exportModal)}
                content={ExportModalBody}
            />
            <div className='p-5 md:p-10 font-inter'>
                {
                    tab == 1 ?
                        <div className='py-1.5'>
                            <BreadCrum content={[
                                {
                                    title: 'Products',
                                    url: '/shop/products'
                                },
                                {
                                    title: 'List',
                                    url: '/shop/products'
                                },

                            ]} />
                        </div>
                        : tab == 2 ?
                            <div className='py-1.5'>
                                <BreadCrum content={[
                                    {
                                        title: 'Products',
                                        url: '/shop/products'
                                    },
                                    {
                                        title: 'Categories',
                                        url: '/shop/products'
                                    },
                                    {
                                        title: 'List',
                                        url: '/shop/products'
                                    },
                                ]} />
                            </div> :
                            <div className='py-1.5'>
                                <BreadCrum content={[
                                    {
                                        title: 'Products',
                                        url: '/shop/brands'
                                    },
                                    {
                                        title: 'Brands',
                                        url: '/shop/brands'
                                    },
                                    {
                                        title: 'List',
                                        url: '/shop/brands'
                                    },
                                ]} />
                            </div>
                }

                <div className='flex items-center justify-between'>
                    <PageHeading title='Products' />
                    {
                        tab == 1 ?
                            <Button
                                title='New Product'
                                type={"button"}
                                textSize={"text-xs md:text-sm"}
                                height={"h-9 w-36"}
                                onButtonPressed={() => navigate('/shop/products/product/add')}
                            /> : tab == 2 ?
                                <div className='flex space-x-2'>
                                    <Button
                                        title='Import Categories'
                                        type={"button"}
                                        textSize={"text-xs md:text-sm"}
                                        height={"h-9 w-36"}
                                        background={'bg-white'}
                                        color={'text-black'}
                                        border={true}
                                        onButtonPressed={() => setImportModal(!importModal)}
                                    />
                                    <Button
                                        title='New category'
                                        type={"button"}
                                        textSize={"text-xs md:text-sm"}
                                        height={"h-9 w-36"}
                                        onButtonPressed={() => navigate('/shop/products/categories/add')}
                                    />
                                </div>
                                :
                                <div className='flex space-x-2'>
                                    <Button
                                        title='Export Brands'
                                        type={"button"}
                                        textSize={"text-xs md:text-sm"}
                                        height={"h-9 w-36"}
                                        background={'bg-white'}
                                        color={'text-black'}
                                        border={true}
                                        onButtonPressed={() => setExportModal(!exportModal)}
                                    />
                                    <Button
                                        title='New Brand'
                                        type={"button"}
                                        textSize={"text-xs md:text-sm"}
                                        height={"h-9 w-36"}
                                        onButtonPressed={() => navigate('/shop/products/brands/add')}
                                    />
                                </div>

                    }
                </div>

                <SubLayout
                    tab={tab}
                    setTab={setTab}
                >
                    {
                        tab === 1 ? <ProductsPage /> : tab === 2 ? <CategoriesPage /> :
                            <BrandsPage />
                    }
                </SubLayout>
            </div>
        </Layout>
    )
}
