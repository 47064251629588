import React from "react";
import { FaTimes } from "react-icons/fa";
import Modal from "react-modal";
import { Button, CustomSelectWithLabel, InputWithLabel } from "../../Atoms";
import { v4 as uuidv4 } from 'uuid';

const customStyles = {
    content: {
        position: "absolute",
        border: "none",
        background: "#fff",
        outline: "none",
        padding: "0px",
        zIndex: "9999 !important",
    },
};

Modal.defaultStyles.overlay.backgroundColor = "rgba(0,0,0,0.3)";

export const AddFolderModalComponent = ({
    content,
    modal,
    onModalStatus,
    defaultStyle,
    type,
    style = "w-11/12 sm:w-10/12 md:w-1/2 lg:w-4/12  2xl:w-[22%] top-[25%] lg:top-[30%] ",
    id
}) => {

    const onGetExistingFolders = () => {
        const folders = JSON.parse(localStorage.getItem('folders') || '[]');
        return folders
    }

    const onSubmitForm = (e) => {
        e.preventDefault()
        console.log('Form Submitted')
        const formData = new FormData(e.target)
        const result = Object.fromEntries(formData)
        const clone =
        {
            ...result, images: [], id: uuidv4(),
            type: result?.parent_folder === 'media_library' ? 1 : 2
        }
        
        const folders = JSON.parse(localStorage.getItem('folders') || '[]');
        if (folders?.some(folder => folder.folder_name === clone.folder_name && folder.parent_folder === result.parent_folder)) {
            alert('Folder name already exists')
            return
        }
        localStorage.setItem('folders', JSON.stringify([...folders, clone]))
        onModalStatus()
    }
    return (
        <Modal
            id={id}
            className={` ${defaultStyle ? "w-11/12 sm:w-10/12 md:w-8/12 xl:w-7/12 top-[25%] lg:top-[15%] 2xl:top-[20%] rounded-lg shadow-equal_dark" : style
                } mx-auto absolute right-0 left-0 rounded-xl shadow-equal_dark z-[1000]`}
            isOpen={modal}
            onRequestClose={onModalStatus}
            style={customStyles}
            overlayClassName=""
        >
            <form
                onSubmit={onSubmitForm}
                className="p-5 rounded-md border dark:border-gray-dark flex flex-col items-center justify-center dark:bg-black-PRIMARY">
                <div className="flex items-center justify-between w-full">
                    <div>Add new Folder</div> <FaTimes onClick={onModalStatus} />
                </div>
                <div className="mt-5 grid grid-cols-2 gap-4 w-full">
                    <InputWithLabel
                        type={'text'}
                        label={'Folder Name'}
                        placeholder={'Enter folder name'}
                        inputStyle={'w-full'}
                        isRequired={true}
                        title={'Name'}
                        name="folder_name"
                    />
                    <CustomSelectWithLabel
                        label={'Parent Folder'}
                        isRequired={true}
                        title={'Parent Folder'}
                        name="parent_folder"
                        options={
                            [
                                { value: 'media_library', label: 'Media Library' },
                                ...onGetExistingFolders().map(folder => {
                                    return { value: folder.id, label: folder.folder_name }
                                })
                            ]
                        }
                    />
                </div>

                <div className='mt-6 flex items-center justify-between w-full space-x-2'>
                    <Button
                        type='button'
                        textSize={'text-xs md:text-sm px-4'}
                        height={'h-8 md:h-9 w-full'}
                        background={'bg-white dark:bg-black-SECONDARY'}
                        color={'text-black dark:text-white'}
                        title={'Cancel'}
                        border={true}
                        onButtonPressed={onModalStatus}
                    />
                    <Button
                        type='submit'
                        textSize={'text-xs md:text-sm px-4'}
                        height={'h-8 md:h-9 w-full'}
                        background={'bg-orange'}
                        title={'Confirm'}
                    />
                </div>

            </form>
        </Modal>
    );
};

Modal.setAppElement("#root");