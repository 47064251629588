import React, { useState, useEffect } from 'react'
import Drawer from 'react-modern-drawer'
import 'react-modern-drawer/dist/index.css'

export const DrawerComponent = ({ isOpen, toggleDrawer, children , direction }) => {
    const [drawerWidth, setDrawerWidth] = useState('400px');

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 576) {
                setDrawerWidth('300px');
            } else if (window.innerWidth < 768) {
                setDrawerWidth('300px');
            } else {
                setDrawerWidth('400px');
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // Call the function initially to set the width based on the initial viewport size

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <Drawer
            open={isOpen}
            onClose={toggleDrawer}
            direction={direction}
            style={{ width: drawerWidth, zIndex: '9999999'  }}
        >
            {children}
        </Drawer>

    )
}
