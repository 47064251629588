import React from 'react'


import { CardLayout, OrdersCard } from '../../../Components/Molecules'
import { DataTable } from '../../../Components/Organism/table'

import { BiSolidEdit } from "react-icons/bi";
import { useAppNavigate } from '../../../Hooks'
import { BsCheck, BsStars } from 'react-icons/bs'
import { FaTimes } from 'react-icons/fa'

export const BrandsPage = () => {

    const navigate = useAppNavigate()

    const statusColor = {
        'No': "bg-red-light text-red border-2 border-red ",
        'Yes': "bg-green-light text-green border-2 border-green ",
    }

    const statusIcons = {
        'Yes': <BsCheck />,
        'No': <FaTimes className='text-xs'/>,
    }

    const columns = [
        {
            name: "name",
            label: "Name",
            filter: true,
            sort: true,
            sortOrder: "desc",
            visible: true,
        },
        {
            name: "website",
            label: "Website",
            filter: true,
            sort: true,
            sortOrder: "desc",
            visible: true,
        },
        {
            name: "visibility",
            label: "Visibility",
            filter: true,
            sort: true,
            sortOrder: "desc",
            visible: true,
            customComponent: (props) => {
                return (
                    <div className='flex items-center justify-center'>
                        <span className={`w-5 h-5 p-1 flex items-center space-x-1 justify-center text-xs leading-5 font-semibold rounded-full ${statusColor[props?.visibility]} p-0.5 `}>
                            <div className='text-sm'>{statusIcons[props.visibility]}</div>
                        </span>
                    </div>
                );
            },
        },
        {
            name: "updated_date",
            label: "Updated Date",
            filter: false,
            sort: true,
            sortOrder: "desc",
            visible: true,
        },
        {
            name: "",
            label: "Action",
            filter: true,
            sort: true,
            sortOrder: "desc",
            visible: true,
            customComponent: (props) => {
                return (
                    <div
                        onClick={() => navigate(`/shop/products/brands/${props.id}/edit`)}
                        className='text-orange cursor-pointer flex items-center justify-center space-x-1 text-sm'>
                        <div><BiSolidEdit className='text-lg' /></div>
                        <div className='font-semibold '>Edit</div>
                    </div>
                );
            },
        },

    ];

    const data = [
        {
            id: 1,
            name: 'Cotton',
            parent: 'Clothing',
            website: 'www.google.com',
            visibility: 'Yes',
            updated_date: '2021-08-25',
        },
        {
            id: 2,
            name: 'Candy',
            parent: 'Clothing',
            website: 'www.google.com',
            visibility: 'No',
            updated_date: '2021-08-25',
        },
    ]

    const options = {
        paginationTop: true,
        paginationBottom: true,
    };

    return (
        <div className='mt-10 space-y-4'>
            <CardLayout showTitle={false} title='' content={<DataTable
                data={data}
                groupBy={false}
                deleteRowsFilter={false}
                columnRowsFilter={false}
                columns={columns} options={options} />} />
        </div>
    )
}
