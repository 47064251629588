import { useState } from 'react';

import { useAppNavigate } from '../../Hooks';

import { RxLightningBolt } from "react-icons/rx";
import { HiOutlineTag } from "react-icons/hi2";
import { SiBrandfolder } from "react-icons/si";
export const SubLayout = ({ children, setTab, tab }) => {


    const [showSidebar, setShowSidebar] = useState(true)
    const [toggleSidebar, setToggleSidebar] = useState(false)
    const [showShopMenu, setShowShopMenu] = useState(true)


    const ShopMenuItems = [
        {
            id: 1,
            href: '/shop/products',
            title: 'Products',
            icon: <RxLightningBolt className='text-xl md:text-2xl' />,
        },
        {
            id: 2,
            href: '/shop/orders',
            title: 'Categories',
            icon: <HiOutlineTag className='text-xl md:text-2xl' />,
        },
        {
            id: 3,
            href: '/shop/customers',
            title: 'Brands',
            icon: <SiBrandfolder className='text-xl md:text-2xl' />,
        },
    ];

    return (
        <div className='min-h-screen flex flex-col bg-gray-50 dark:bg-transparent'>
            {
                showSidebar ? (
                    <div className='overflow-hidden'>
                        <div className='flex justify-between'>
                            <div className={`fixed ${toggleSidebar ? 'block' : 'hidden lg:block'} lg:relative z-1 px-5 space-y-8 py-10 lg:w-3/12 overflow-y-auto min-h-screen text-center bg-primary text-secondary`}
                            >
                                {
                                    showShopMenu &&
                                    <div className=''>
                                        {ShopMenuItems.map(({ href, title, icon, id }) => (
                                            (

                                                <div
                                                    onClick={() => setTab(id)}
                                                    className={
                                                        id == tab
                                                            ? 'p-2.5 bg-black bg-opacity-5 text-orange flex items-center rounded-md px-4 duration-300 cursor-pointer hover:bg-black hover:bg-opacity-5 hover:text-primary hover:duration-[100ms]'
                                                            : 'p-2.5 text-gray-text dark:text-white flex items-center rounded-md px-4 duration-300 cursor-pointer hover:bg-black hover:bg-opacity-5 hover:text-primary hover:duration-[100ms]'
                                                    }
                                                >
                                                    <div className='w-4 text-gray-icon'>{icon}</div>
                                                    <span className="text-xs lg:text-[14px] ml-4 font-semibold">{title}</span>
                                                </div>
                                            )
                                        ))}
                                    </div>
                                }
                            </div>
                            <main className='w-full lg:w-9/12'>{children}</main>
                        </div>
                    </div>
                ) :
                    <div>
                        <main>{children}</main>
                    </div>

            }

        </div>
    );
}
