import React, { useState } from 'react'
import { Button, InputWithLabel, PageHeading, Switch } from '../../../../Components/Atoms'
import { useAppNavigate } from '../../../../Hooks'
import { DeleteModalComponent, Layout, PaymentTable } from '../../../../Components/Organism'
import { BreadCrum, CardLayout, RichTextEditor } from '../../../../Components/Molecules'

export const EditCategories = () => {
    const navigate = useAppNavigate()
    const [deleteModal, setDeleteModal] = useState(false)

    const onSubmitForm = (e) => {
        e.preventDefault()
        const formData = new FormData(e.target)
        const data = Object.fromEntries(formData)
        console.log(data)
        // navigate('/dashboard')
    }

    return (
        <Layout>
            <div className='p-5 md:p-10 font-inter'>
                <DeleteModalComponent
                    onModalStatus={() => setDeleteModal(!deleteModal)}
                    modal={deleteModal}
                    type={'delete'}
                />

                <div className='py-1.5'>
                    <BreadCrum content={[
                        {
                            title: 'Orders',
                            url: '/shop/orders'
                        },
                        {
                            title: 'O421',
                            url: '/shop/orders/1/edit'
                        },
                        {
                            title: 'Edit',
                            url: '/shop/orders/1/edit'
                        },
                    ]} />
                </div>

                <div className='flex items-center justify-between'>
                    <PageHeading title='Edit O421' />
                    <Button
                        title={'Delete'}
                        type={'button'}
                        textSize={'text-xs md:text-sm  px-4'}
                        height={'h-9'}
                        onButtonPressed={() => setDeleteModal(!deleteModal)}
                        background={'bg-red'}
                        color="text-white"
                        border={false} />
                </div>

                <form
                    onSubmit={onSubmitForm}>
                    <div className='grid md:grid-cols-3 lg:grid-cols-3 gap-4 mt-5'>
                        <div className='md:col-span-2'>
                            <div
                                className=' grid md:grid-cols-2 gap-4 border border-gray-100  bg-white rounded-xl px-6 md:px-8 pt-6 pb-10'
                            >
                                <InputWithLabel
                                    title={'Name'}
                                    name="name"
                                    placeholder='Enter name'
                                    password={false}
                                    type={'text'}
                                    isRequired={true}
                                />
                                <InputWithLabel
                                    title={'Slug'}
                                    name="slug"
                                    placeholder='Enter slug'
                                    password={false}
                                    type={'text'}
                                    disabled={true}
                                    isRequired={false}
                                />
                                <div className='md:col-span-2'>
                                    <InputWithLabel
                                        title={'Parent'}
                                        name="text"
                                        placeholder='Enter Parent'
                                        password={false}
                                        type={'text'}
                                        isRequired={false}
                                    />
                                </div>
                                <div>
                                    <Switch title="Visible to Customers." />
                                </div>

                                <div className='md:col-span-2'>
                                    <RichTextEditor
                                        name="description"
                                        title="Description"
                                        isRequired={false}
                                    />
                                </div>

                            </div>

                        </div>
                        <div className='max-h-[250px] w-full border space-y-4 border-gray-100  bg-white rounded-xl p-6'>
                            <div className='flex flex-col space-y-2 text-xs lg:text-sm'>
                                <div className='font-medium'>
                                    Created at
                                </div>
                                <div>
                                    6 month ago
                                </div>
                            </div>
                            <div className='flex flex-col space-y-2 text-xs lg:text-sm'>
                                <div className='font-medium'>
                                    Last modified at
                                </div>
                                <div>
                                    3 month ago
                                </div>
                            </div>
                        </div>



                    </div>
                    <div className='flex items-center w-full mt-5 space-x-2'>
                        <Button
                            title={'Save changes'}
                            type={'submit'}
                            textSize={'text-xs md:text-sm px-4'}
                            height={'h-8 md:h-9'}
                        />

                        <Button
                            title={'Cancel'}
                            type={'button'}
                            onButtonPressed={() => navigate('/shop/orders')}
                            textSize={'text-xs md:text-sm max-w-min px-4'}
                            height={'h-8 md:h-9'}
                            background={'bg-white'}
                            color="text-black"
                            border={true}
                        />

                    </div>

                </form>
                <div className='mt-4'>
                    <PaymentTable/>
                </div>
            </div>
        </Layout>


    )
}
