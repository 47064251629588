import React from 'react'
import { CardLayout, RichTextEditor } from '../../Molecules';
import { DataTable } from '.';
import { BiSolidEdit } from 'react-icons/bi';

import { ImBin } from 'react-icons/im';
import { Button, PageHeading } from '../../Atoms';
import { DeleteModalComponent } from '../modal/delete';
import { FaEye } from 'react-icons/fa6';
import { ASSETS } from '../../../Assets/path';
import { useNavigate } from 'react-router-dom';

export const PostsTable = () => {
    const navigate = useNavigate()
    const [deleteModal, setDeleteModal] = React.useState(false);

    const statusColor = {
        'New': "bg-blue-light dark:bg-transparent text-blue border border-blue border-opacity-20",
        'Draft': "bg-orange-light dark:bg-transparent text-orange border border-orange border-opacity-20",
        'Cancelled': "bg-red-light dark:bg-transparent text-red border border-red border-opacity-20",
        'Published': "bg-green-light dark:bg-transparent text-green border border-green border-opacity-20",
    }

    const columns = [
        {
            name: "image",
            label: "Image",
            filter: false,
            sort: false,
            sortOrder: "desc",
            visible: true,
            customComponent: (props) => {
                return (
                    <div className='flex items-center justify-center'>
                        <img src={ASSETS.AVATAR} alt='' className='w-10 h-10' />
                    </div>
                );
            },

        },
        {
            name: "title",
            label: "Title",
            filter: true,
            sort: true,
            sortOrder: "desc",
            visible: true,
        },
        {
            name: "author",
            label: "Author",
            filter: true,
            sort: true,
            sortOrder: "desc",
            visible: true,
        },
        {
            name: "status",
            label: "Status",
            filter: true,
            sort: true,
            sortOrder: "desc",
            visible: true,
            customComponent: (props) => {
                return (
                    <div className='flex items-center justify-center'>
                        <div className={`${statusColor[props?.status]} max-w-min rounded-md text-xs px-2 py-1`}>
                            {props?.status}
                        </div>

                    </div>
                );
            },
        },
        {
            name: "published_date",
            label: "Published date",
            filter: true,
            sort: true,
            sortOrder: "desc",
            visible: true,
        },
        {
            name: "comment_authors",
            label: "Comment Authors",
            filter: true,
            sort: true,
            sortOrder: "desc",
            visible: true,
            customComponent: (props) => {
                return (
                    <div className='flex items-center text-xs line-clamp-1 justify-center'>
                        {props?.comment_authors}
                    </div>
                );
            },
        },
        {
            name: "",
            label: "Action",
            filter: true,
            sort: true,
            sortOrder: "desc",
            visible: true,
            customComponent: (props) => {
                return (
                    <div

                        className='flex items-center justify-center space-x-3'>
                        <div
                            onClick={() => navigate(`/blog/post/${props?.id}/1`)}
                            className='text-gray-icon cursor-pointer flex items-center justify-center space-x-1 text-sm'>
                            <div><FaEye className='text-lg' /></div>
                            <div className='font-medium text-gray-text'>View</div>
                        </div>
                        <div
                            onClick={() => navigate(`/blog/post/${props?.id}/2`)}
                            className='text-orange cursor-pointer flex items-center justify-center space-x-1 text-sm'>
                            <div><BiSolidEdit className='text-lg' /></div>
                            <div className='font-medium'>Edit</div>
                        </div>
                        <div
                            onClick={() => setDeleteModal(!deleteModal)}
                            className='text-red cursor-pointer flex items-center justify-center space-x-1 text-sm'>
                            <div><ImBin className='text-lg' /></div>
                            <div className='text-red font-medium'>Delete</div>
                        </div>
                    </div>
                );
            },
        },

    ];

    const data = [
        {
            id: 1,
            image: '',
            title: 'Lorem ipsum dolor sit amet',
            author: 'John Doe',
            status: 'Published',
            published_date: '2021-08-12',
            comment_authors: 'John Doe',
        },
        {
            id: 2,
            image: '',
            title: 'Lorem ipsum dolor sit amet',
            author: 'John Doe',
            status: 'Draft',
            published_date: '2021-08-12',
            comment_authors: 'John Doe',
        }
    ]

    const options = {
        paginationTop: true,
        paginationBottom: true,
    };

    return (
        <div className=''>
            <div className=' mb-4 flex items-center justify-between'>
                <PageHeading title='Categories' />
                <Button
                    title='New post'
                    type={"button"}
                    textSize={"text-xs md:text-sm"}
                    height={"h-9 w-36"}
                    onButtonPressed={() => navigate('/blog/post/create')}
                />

            </div>



            <DeleteModalComponent
                onModalStatus={() => setDeleteModal(!deleteModal)}
                modal={deleteModal}
                type={'delete'}
            />

            <CardLayout
                showTitle={false}
                title=''
                button={false}
                customButtonsStatus={false}
                content={<DataTable
                    data={data}
                    groupBy={false}
                    columns={columns} options={options} />}
            />
        </div>


    )
}
