import React from 'react';
import { Link } from 'react-router-dom';

import { FaChevronRight } from 'react-icons/fa6';

export const BreadCrum = ({ content }) => {
    return (
        <nav className="bg-grey-light text-xs lg:text-sm w-full rounded-md">
            <ol className="list-reset flex">
                {
                    content.map(({ title, url }, index) => (
                        <li key={index} className='flex items-center space-x-2'>
                            <li>
                                <Link
                                    to={url}
                                    className=" transition duration-150 ease-in-out hover:text-gray-black focus:text-gray-black text-gray-text dark:text-gray-500"
                                >
                                    {title}
                                </Link>
                            </li>
                            {
                                index !== content.length - 1 &&
                                <div className='pr-1'>
                                    <FaChevronRight className='text-gray-text text-[8px] lg:text-[10px]' />
                                </div>
                            }
                        </li >
                    ))
                }

            </ol>
        </nav>
    );
}