import React, { useEffect, useState } from 'react'
import { ModalComponent } from './index'
import { AddFolderModalComponent } from './folder'
import { Button, PageHeading, UploadFile } from '../../Atoms'

import { FaFolder } from "react-icons/fa";
import { v4 as uuidv4 } from 'uuid';
import { FaArrowLeft, FaChevronLeft } from 'react-icons/fa6'
import moment from 'moment'
import { CiMenuKebab } from "react-icons/ci";

export const LibraryModalComponent = () => {

    const [newFolder, setNewFolder] = React.useState(false)
    const [selectedImage, setSelectedImage] = useState(null)
    const [newImage, setNewImage] = useState(null)
    const [openedFolder, setOpenedFolder] = useState('')
    const [openedFolderImages, setOpenedFolderImages] = useState([])
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
    const [viewImage, setViewImage] = useState(false)
    const [folderMenu, setFolderMenu] = useState(null)
    const [isDeleting,setIsDeleting] = useState(false)

    useEffect(() => {
        const img = new Image();
        img.onload = () => setDimensions({ width: img.naturalWidth, height: img.naturalHeight });
        img.src = selectedImage?.url;
    }, [selectedImage]);

    useEffect(() => {
        if (openedFolder !== '') {
            setOpenedFolderImages(openedFolder.images)
        }
        else {
            const images = onGetExistingFolders().find(folder => folder.folder_name === 'media_library')?.images
            setOpenedFolderImages(images)
        }
    }, [openedFolder])


    const onGetExistingFolders = () => {
        const folders = JSON.parse(localStorage.getItem('folders') || '[]');
        return folders
    }

    const handleFileChange = (e) => {
        e.preventDefault();
        const file = e.target.files[0];
        const reader = new FileReader();

        reader.onloadend = () => {
            const base64String = reader.result;
            const imageObject = {
                name: file.name,
                size: file.size,
                url: base64String,
            };

            let folders = onGetExistingFolders();
            let mediaLibraryFolder = folders.find(folder => folder.folder_name === 'media_library');

            if (!mediaLibraryFolder) {
                mediaLibraryFolder = {
                    id: uuidv4(),
                    folder_name: 'media_library',
                    parent_folder: null,
                    type: 1,
                    images: [imageObject],
                };
                folders = [...folders, mediaLibraryFolder];
            } else {
                mediaLibraryFolder.images = [...mediaLibraryFolder.images, imageObject];
            }

            if (!openedFolder || openedFolder.folder_name === 'media_library') {
                setOpenedFolderImages(mediaLibraryFolder.images);
            } else {
                folders = folders.map(folder => {
                    if (folder.id === openedFolder.id) {
                        console.log('Image pushed')
                        setOpenedFolderImages([...openedFolderImages, imageObject])
                        return {
                            ...folder,
                            images: [...folder.images, imageObject]
                        };
                    } else {
                        return folder;
                    }
                });
            }

            localStorage.setItem('folders', JSON.stringify(folders));
        };

        reader.readAsDataURL(file);
    }

    const handleDeleteImage = () => {
        let folders = onGetExistingFolders();

        folders = folders.map(folder => {
            if (folder.id === openedFolder.id) {
                const updatedImages = folder.images.filter(image => image.url !== selectedImage.url);
                return {
                    ...folder,
                    images: updatedImages
                };
            } else {
                return folder;
            }
        });

        localStorage.setItem('folders', JSON.stringify(folders));
        setOpenedFolderImages(prevImages => prevImages.filter(image => image.url !== selectedImage.url));
        setSelectedImage(null);
    }

    const handleDeleteFolder = (folderId) => {
        if (isDeleting) {
           return alert('Please wait, deletion is in progress');
        }
        setIsDeleting(true)
        let folders = onGetExistingFolders();

        const folderIndex = folders.findIndex(folder => folder.id === folderId);

        if (folderIndex !== -1) {
            // Remove the folder from the array
            folders.splice(folderIndex, 1);

            // Update local storage
            localStorage.setItem('folders', JSON.stringify(folders));

            // If the deleted folder was opened, close it
            if (openedFolder.id === folderId) {
                setOpenedFolder('');
            }
        }
        setIsDeleting(false)
    }

    return (
        <div className='p-5 md:p-10 font-inter'>
            <ModalComponent
                content={<img src={selectedImage?.url} alt='Selected Image' className='w-full h-96 object-contain bg-gray-100 rounded-md' />}
                modal={viewImage}
                onModalStatus={() => { setViewImage(!viewImage) }}
                defaultStyle={true}
                style={'w-11/12 sm:w-10/12 md:w-1/2 lg:w-4/12  2xl:w-[22%] top-[25%] lg:top-[30%] '}
                id={'viewImage'}
            />
            <AddFolderModalComponent
                content={'Add new Folder'}
                modal={newFolder}
                onModalStatus={() => { setNewFolder(!newFolder) }}
                defaultStyle={true}
                type={'folder'}
                style={'w-11/12 sm:w-10/12 md:w-1/2 lg:w-4/12  2xl:w-[22%] top-[25%] lg:top-[30%] '}
                id={'addFolder'}
            />

            <div className='flex items-center justify-between w-full'>
                <div className='flex items-center space-x-2'>
                    {
                        openedFolder !== '' &&
                        <FaChevronLeft onClick={() => setOpenedFolder('')} />
                    }
                    <PageHeading title={openedFolder?.folder_name || 'Media Library'} />
                </div>
                <div className='space-x-2 flex items-center'>
                    <Button
                        title='Add folder'
                        type={"button"}
                        textSize={"text-xs md:text-sm"}
                        height={"h-9 w-36"}
                        background={'bg-white'}
                        color={'text-black'}
                        border={true}
                        onButtonPressed={() => { setNewFolder(!newFolder) }}
                    />

                    <Button
                        title='Add more assets'
                        type={"button"}
                        textSize={"text-xs md:text-sm"}
                        height={"h-9 w-36"}
                        onButtonPressed={() => setNewImage(!newImage)}
                    />
                </div>
            </div>
            {
                newImage &&
                <div
                    className='mt-5'>
                    <UploadFile
                        name="product"
                        handleFileChange={handleFileChange}
                        title={<div className='flex space-x-2 items-center justify-center'>
                            <div className='text-gray-text'>
                                Drag & drop your file or
                            </div> <div className="text-orange font-medium cursor-pointer">Browse</div>
                        </div>}
                    />
                </div>
            }

            <div className='mt-5 grid grid-cols-3  gap-4 w-full'>
                <div className={`${selectedImage != null ? 'col-span-2' : 'col-span-3'}`}>
                    {

                        openedFolder === '' &&
                        <div className={`grid ${selectedImage !== null ? 'grid-cols-1 md:gird-cols-3 lg:grid-cols-4 xl:grid-cols-5' : 'grid-cols-3 md:gird-cols-4 lg:grid-cols-5 xl:grid-cols-7'} gap-4 mt-5`}>
                            {
                                onGetExistingFolders()?.map((folder, index) =>
                                    folder.folder_name !== 'media_library' &&
                                    <div


                                        key={index} className='bg-gray-100 dark:bg-black-SECONDARY py-3 px-1 rounded-md  text-center cursor-pointer flex flex-col items-center '>
                                        <div className='flex items-center justify-end w-full relative'>
                                            <CiMenuKebab onClick={() => setFolderMenu(folder)} />
                                            {
                                                folder.folder_name === folderMenu?.folder_name &&
                                                <div
                                                    onMouseLeave={() => setFolderMenu(null)}
                                                    className='px-2 space-y-1 py-1.5 top-0 right-3 rounded-md absolute text-xs bg-white border-gray-100 dark:bg-black-PRIMARY dark:text-white'>
                                                    <div
                                                        onClick={() => {
                                                            setOpenedFolder(folder)
                                                        }}
                                                    >View</div>
                                                    <div
                                                        onClick={() => {
                                                            handleDeleteFolder(folder.id)
                                                        }}
                                                    >Delete</div>
                                                </div>

                                            }
                                        </div>

                                        <div className='flex items-center flex-col space-y-0.5 mt-2'>
                                            <div><FaFolder className='text-gray-icon text-3xl' /></div>
                                            <div className='text-xs font-semibold'>{folder.folder_name}</div>
                                        </div>
                                        <div className='text-xs mt-1'>
                                            {folder?.images?.length <= 0 ? 'Folder is empty' : folder?.images?.length + ' images'}
                                        </div>
                                    </div>
                                )
                            }
                            {
                                openedFolderImages?.map((image, index) =>
                                    <img
                                        onClick={() => {
                                            setSelectedImage(image)
                                        }}
                                        key={index} src={image.url} alt={`Image ${index}`} className='w-32 h-32 object-contain bg-gray-100 rounded-md' />
                                )
                            }
                        </div>
                    }
                    <div>
                        {
                            openedFolder !== '' &&
                            <div className='grid grid-cols-6 gap-4 mt-5'>
                                {
                                    openedFolderImages?.map((image, index) =>
                                        <img
                                            onClick={() => {
                                                setSelectedImage(image)
                                            }}
                                            key={index} src={image.url} alt={`Image ${index}`} className='w-40 h-40 object-contain bg-gray-100 rounded-md' />
                                    )
                                }
                            </div>
                        }
                    </div>
                </div>
                {
                    selectedImage && <div className='p-5 rounded-md bg-gray-100 min-h-screen'>
                        <div className='flex flex-col items-center'>
                            <div className='w-full'>
                                <div className='relative'>
                                    <img src={selectedImage.url} alt='Selected Image' className='w-full h-40 object-contain bg-gray-100 rounded-md' />
                                    <div
                                        onClick={() => setSelectedImage(null)}
                                        className='absolute top-2 right-2
                                            cursor-pointer
                                        bg-white text-black h-5 w-5 rounded-full flex items-center justify-center'>
                                        <FaArrowLeft className='text-xs' />
                                    </div>
                                </div>
                                <div className='text-xs md:text-sm mt-2'>
                                    <div className='text-base'>
                                        {selectedImage.name}
                                    </div>
                                    <div>
                                        {selectedImage.size}
                                    </div>
                                    <div className='mt-5 flex items-center justify-between border-t border-b border-gray-icon py-2 '>
                                        <div className='text-sm'>Uploaded By</div>
                                        <div className='text-xs'>Demo User</div>
                                    </div>
                                    <div className=' flex items-center justify-between border-b border-gray-icon py-2 '>
                                        <div className='text-sm'>Uploaded at</div>
                                        <div className='text-xs'>{moment().format('MMMM Do YYYY, h:mm:ss a')}</div>
                                    </div>

                                    <div className=' flex items-center justify-between border-b border-gray-icon py-2 '>
                                        <div className='text-sm'>Dimensions</div>
                                        <div className='text-xs'>{dimensions.width} x {dimensions.height}</div>
                                    </div>
                                    <div className=' flex items-center justify-between border-b border-gray-icon py-2 '>
                                        <div className='text-sm'>ID</div>
                                        <div className='text-xs'>3</div>
                                    </div>
                                    <div className=' flex items-center justify-between border-b border-gray-icon py-2 '>
                                        <div className='text-sm'>Thumb conversion generated</div>
                                        <div className='text-xs'>Yes</div>
                                    </div>

                                    <div className='space-x-2 mt-5 flex items-center'>
                                        <Button
                                            title='View'
                                            type={"button"}
                                            textSize={"text-xs md:text-sm"}
                                            height={"h-9 w-36"}
                                            background={'bg-white'}
                                            color={'text-black'}
                                            border={true}
                                            onButtonPressed={
                                                () => setViewImage(!viewImage)
                                            } />

                                        <Button
                                            title='Delete'
                                            type={"button"}
                                            textSize={"text-xs md:text-sm"}
                                            height={"h-9 w-36"}
                                            background={'bg-red'}
                                            onButtonPressed={handleDeleteImage}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>

        </div >
    )
}
