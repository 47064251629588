import React, { useRef, useState } from 'react'
import { Button, CustomSelectWithLabel, InputWithLabel, PageHeading } from '../../../Components/Atoms'
import { useAppNavigate } from '../../../Hooks'
import { Layout } from '../../../Components/Organism'
import { BreadCrum, RichTextEditor } from '../../../Components/Molecules'
import { BsStars } from 'react-icons/bs'
import { LuRefreshCw } from 'react-icons/lu'
import { IoIosCloseCircle } from 'react-icons/io'
import { BsPatchCheckFill } from "react-icons/bs";
import { ImTruck } from "react-icons/im";
import { CardLayout, OrderItemsCard } from '../../../Components/Molecules/Cards'

export const NewOrder = () => {
    const navigate = useAppNavigate()
    const [activeTab, setActiveTab] = useState(1)

    const onSubmitForm = (e) => {
        e.preventDefault()
        const formData = new FormData(e.target)
        const data = Object.fromEntries(formData)
        console.log(data)
        setActiveTab(2)
        // navigate('/dashboard')
    }
    const statusColor = {
        'New': "bg-blue text-white",
        'Processing': "bg-orange text-white",
        'Shipped': "bg-green text-white",
        'Delivered': "bg-green text-white",
        'Cancelled': "bg-red text-white",
    }

    const statusIcons = {
        'New': <BsStars />,
        'Processing': <LuRefreshCw />,
        'Cancelled': <IoIosCloseCircle />,
        'Delivered': <BsPatchCheckFill />,
        'Shipped': <ImTruck />

    }

    const [statusList, setStatusList] = useState(
        [
            {
                id: 1,
                status: 'New',
                active: true
            },
            {
                id: 2,
                status: 'Processing',
                active: false,
            },
            {
                id: 3,
                status: 'Shipped',
                active: false,
            },
            {
                id: 4,
                status: 'Delivered',
                active: false,
            },
            {
                id: 5,
                status: 'Cancelled',
                active: false,
            },

        ]
    )

    const onSelectStatus = (id) => {
        const updatedStatusList = statusList.map((status) => {
            if (status.id === id) {
                return { ...status, active: true };
            } else {
                return { ...status, active: false };
            }
        });

        setStatusList(updatedStatusList);
    }

    const [list, setList] = useState([{
        id: 1,
        title: 'pending',
        price: 100,
        quantity: 1,
    },
    {
        id: 2,
        title: 'completed',
        price: 100,
        quantity: 1,
    },
    {
        id: 3,
        title: 'cancelled',
        price: 100,
        quantity: 1,
    },
    ]);

    const dragOver = useRef(null);
    const dragItem = useRef(null);

    const onDragEnd = () => {
        const _list = [...list];
        const draggedItem = _list[dragItem.current]; // Get the dragged item
        const dragOverItem = _list[dragOver.current]; // Get the item at dragOver position

        _list[dragOver.current] = draggedItem; // Replace drag item with dragOver item
        _list[dragItem.current] = dragOverItem; // Replace drag item with dragOver item

        setList(_list);
        dragItem.current = null;
        dragOver.current = null;
    };

    return (
        <Layout>
            <div className='p-5 md:p-10 font-inter'>
                <div className='py-1.5'>
                    <BreadCrum content={[
                        {
                            title: 'Orders',
                            url: '/shop/orders'
                        },
                        {
                            title: 'Create',
                            url: '/shop/orders/new'
                        },

                    ]} />
                </div>

                <PageHeading title='Create Order' />
                <form
                    onSubmit={onSubmitForm}>
                    <div
                        className='mt-5 grid md:grid-cols-2 gap-4   border border-gray-100 dark:border-gray-dark  bg-white dark:bg-black-SECONDARY rounded-xl px-6 md:px-8 py-4'
                    >
                        <div className='relative flex items-center space-x-3 ' >
                            <div className='w-10 h-10 bg-orange flex items-center justify-center text-white rounded-full'>
                                01</div>
                            <div className='text-sm'>
                                Order Details
                            </div>
                            <div className="hidden md:block absolute w-12 h-12 right-10 -top-1 border-r border-b border-gray-100 dark:border-gray-dark bg-transparent transform -rotate-45" />
                        </div>
                        <div className='block md:hidden border-b md:border-0 border-gray-100 dark:border-gray-dark -mx-5'>
                        </div>
                        <div className='flex items-center space-x-3 text-orange font-medium' >
                            <div className='w-10 h-10 bg-white dark:bg-transparent flex items-center justify-center text-orange border-2 border-orange rounded-full'>
                                02</div>
                            <div className='text-sm'>
                                Order Items
                            </div>
                        </div>

                    </div>
                    {
                        activeTab == 2 ?
                            <div className='mt-5'>
                                <CardLayout
                                    title={'Order Items'}
                                    button={false}
                                    buttonTitle={'Reset'}
                                    buttonType={'delete'}
                                    showTitle={false}
                                    content={
                                        <div className='p-5 w-full space-y-4'>
                                            {                                                
                                                    list.map((item, index) =>
                                                        <OrderItemsCard
                                                            key={item}
                                                            id={item}
                                                            item={item}
                                                            showTitle={true}
                                                            Draggable={true}
                                                            onDragStart={() => (dragItem.current = index)}
                                                            onDragEnter={() => (dragOver.current = index)}
                                                            onDragEnd={onDragEnd}
                                                        />)
                                                
                                            }
                                            <Button
                                                title={'Add item'}
                                                type={'button'}
                                                textSize={'text-xs md:text-sm  px-4'}
                                                height={'h-9'}
                                                background={'bg-white'}
                                                color="text-black"
                                                border={true}
                                            />
                                        </div>
                                    }

                                />
                            </div>
                            : <>
                                <div
                                    className='mt-5 border border-gray-100 dark:border-gray-dark dark:bg-black-SECONDARY bg-white rounded-xl px-6 md:px-8 pt-6 pb-10'
                                >
                                    <div
                                        className=' grid md:grid-cols-2 gap-4'
                                    >
                                        <InputWithLabel
                                            title={'Number'}
                                            name="number"
                                            placeholder='Enter number'
                                            password={false}
                                            type={'text'}
                                            isRequired={false}
                                        />
                                        <CustomSelectWithLabel
                                            title={'Customer'}
                                            name="customer"
                                            placeholder='Enter customer'
                                            isRequired={true}
                                            button={true}
                                            options={[{
                                                label: 'Customer 1',
                                                value: 'customer1'
                                            }, {
                                                label: 'Customer 2',
                                                value: 'customer2'

                                            }]}
                                        />

                                        <div className='flex flex-col space-y-2'>
                                            <label htmlFor='status' className='text-sm whitespace-nowrap text-black dark:text-white font-semibold max-w-min'>
                                                <div className='relative '>Status
                                                    <div className='text-red absolute -top-1.5 -right-2'>*</div>
                                                </div>
                                            </label>
                                            <div className='flex flex-wrap gap-2'>
                                                {
                                                    statusList.map((item) =>
                                                        <div key={item.id}
                                                            className='cursor-pointer'
                                                            onClick={() => onSelectStatus(item.id)}
                                                        >
                                                            <span className={`px-3 cursor-pointer max-w-min flex items-center space-x-1.5 justify-center text-sm leading-5 font-semibold rounded-md lg:rounded-lg ${item.active ? statusColor[item.status] : 'bg-white dark:bg-black-SECONDARY text-black dark:text-white border border-gray-100 dark:border-gray-dark'} py-1.5 lg:py-2 `}>
                                                                <div className={`${item.active ? 'text-white' : 'text-sm lg:text-base text-gray-icon'}`}>{statusIcons[item.status]}</div> <div>{item.status}</div>
                                                            </span>
                                                        </div>
                                                    )
                                                }
                                            </div>


                                        </div>
                                        <CustomSelectWithLabel
                                            title={'Currency'}
                                            name="currency"
                                            placeholder='Enter currency'
                                            isRequired={true}
                                            button={false}
                                            options={[{
                                                label: 'Customer 1',
                                                value: 'customer1'
                                            }
                                            ]}
                                        />

                                    </div>
                                    <div className='grid md:grid-cols-2 gap-4 mt-4'>
                                        <CustomSelectWithLabel
                                            title={'Country'}
                                            name="country"
                                            placeholder='Enter country'
                                            isRequired={true}
                                            button={false}
                                            options={[{
                                                label: 'Customer 1',
                                                value: 'customer1'
                                            }, {
                                                label: 'Customer 2',
                                                value: 'customer2'

                                            }]}
                                        />
                                    </div>
                                    <div className='mt-4'>
                                        <InputWithLabel
                                            title={'Street Number'}
                                            name="text"
                                            placeholder='Enter street number'
                                            password={false}
                                            type={'text'}
                                            isRequired={false}
                                        />
                                    </div>
                                    <div className='mt-4 grid md:grid-cols-3 gap-4'>
                                        <InputWithLabel
                                            title={'City'}
                                            name="text"
                                            placeholder='Enter city'
                                            password={false}
                                            type={'text'}
                                            isRequired={false}
                                        />
                                        <InputWithLabel
                                            title={'State / Province'}
                                            name="text"
                                            placeholder='Enter state / province'
                                            password={false}
                                            type={'text'}
                                            isRequired={false}
                                        />
                                        <InputWithLabel
                                            title={'Zip / Postal code'}
                                            name="text"
                                            placeholder='Enter street number'
                                            password={false}
                                            type={'text'}
                                            isRequired={false}
                                        />
                                    </div>

                                    <div className='mt-4'>
                                        <RichTextEditor
                                            name="notes"
                                            title="Notes"
                                            isRequired={false}
                                        />
                                    </div>

                                </div>
                                <div className='flex items-center w-full justify-between mt-5 space-x-2'>
                                    <Button
                                        title={'Cancel'}
                                        type={'button'}
                                        onButtonPressed={() => navigate('/shop/orders')}
                                        textSize={'text-xs md:text-sm max-w-min px-4'}
                                        height={'h-8 md:h-9'}
                                        background={'bg-white'}
                                        color="text-black"
                                        border={true}
                                    />

                                    <Button
                                        title={'Next'}
                                        type={'submit'}
                                        textSize={'text-xs md:text-sm max-w-min px-4'}
                                        height={'h-8 md:h-9'}
                                    />
                                </div>

                            </>
                    }

                </form>

            </div>
        </Layout>


    )
}
