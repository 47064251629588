import React, { useState } from 'react'
import { CardLayout } from '../../Molecules';
import { DataTable } from '.';
import { BiSolidEdit } from 'react-icons/bi';
import { useAppNavigate } from '../../../Hooks';
import { Button, CustomSelectWithLabel, InputWithLabel } from '../../Atoms';
import { DeleteModalComponent } from '../modal/delete';
import { ImBin } from 'react-icons/im';
import { FaTimes } from 'react-icons/fa';
import { ModalComponent } from '../modal';

export const PaymentTable = () => {
    const navigate = useAppNavigate();
    const [deleteModal, setDeleteModal] = React.useState(false);
    const [paymentModal, setPaymentModal] = useState(false)
    const [provider, setProvider] = useState('stripe')
    const [methodProvider, setMethodProvider] = useState('Credit Card')

    const columns = [
        {
            name: "reference",
            label: "Reference",
            filter: true,
            sort: true,
            sortOrder: "desc",
            visible: true,
        },
        {
            name: "amount",
            label: "Amount",
            filter: true,
            sort: true,
            sortOrder: "desc",
            visible: true,
        },
        {
            name: "provider",
            label: "Provider",
            filter: false,
            sort: true,
            sortOrder: "desc",
            visible: true,
        },
        {
            name: "method",
            label: "Method",
            filter: false,
            sort: true,
            sortOrder: "desc",
            visible: true,
        },
        {
            name: "",
            label: "Action",
            filter: true,
            sort: true,
            sortOrder: "desc",
            visible: true,
            customComponent: (props) => {
                return (
                    <div className='flex items-center justify-center space-x-2'>
                        <div
                            onClick={()=> setPaymentModal(!paymentModal)}
                            className='text-orange cursor-pointer flex items-center justify-center space-x-1 text-sm'>
                            <div><BiSolidEdit className='text-lg' /></div>
                            <div className='font-semibold '>Edit</div>
                        </div>
                        <div
                            onClick={() => setDeleteModal(!deleteModal)}
                            className='text-red cursor-pointer flex items-center justify-center space-x-1 text-sm'>
                            <div><ImBin className='text-lg' /></div>
                            <div className='text-red font-medium'>Delete</div>
                        </div>
                    </div>
                );
            },
        },

    ];

    const data = [
        {
            id: 1,
            reference: "O421",
            amount: "$100",
            provider: "Stripe",
            method: "Credit Card",
        },
        {
            id: 2,
            reference: "O421",
            amount: "$100",
            provider: "Paypal",
            method: "Paypal",
        }
    ]

    const options = {
        paginationTop: true,
        paginationBottom: true,
    };

    const onSubmitForm = (e) => {
        e.preventDefault()

    }

    const PaymentModalBody = (<form

        onSubmit={onSubmitForm}
        className='p-5 border dark:border-gray-dark dark:bg-black-PRIMARY rounded-md '
    >
        <div className='flex items-center justify-between pb-6 '>
            <div className='text-xl font-semibold dark:text-white'>
                Create payment
            </div>
            <div>
                <FaTimes
                    onClick={() => setPaymentModal(!paymentModal)}
                    className='text-gray-icon cursor-pointer' />
            </div>
        </div>
        <div
            className=' grid grid-cols-2 gap-4  rounded-xl '
        >
            <CustomSelectWithLabel
                title={'Order'}
                name="order"
                isRequired={true}
                placeholder='Enter order'
                options={[
                    {
                        id: 1,
                        label: 'Order 1',
                        value: 'order 1'
                    },
                ]}
            />

            <InputWithLabel
                title={'Reference'}
                name="ref"
                placeholder='Enter Ref'
                password={false}
                type={'text'}
                isRequired={true}
            />
            <InputWithLabel
                title={'Amount'}
                name="amount"
                placeholder='Enter amount'
                password={false}
                type={'text'}
                isRequired={false}
            />
            <CustomSelectWithLabel
                title={'Currency'}
                name="currency"
                placeholder='Enter currency'
                isRequired={true}
                options={[
                    {
                        id: 1,
                        label: '$100',
                        value: '$100'
                    },
                ]}
            />
            <div className='flex flex-col space-y-2'>
                <label htmlFor='provider' className='text-sm whitespace-nowrap text-black dark:text-white font-semibold max-w-min'>
                    <div className='relative'>Provider
                        {<div className='text-red absolute -top-1.5 -right-2'>*</div>}
                    </div>
                </label>
                <div className='border flex items-center text-sm max-w-min font-regular  border-gray-100 dark:border-gray-dark rounded-lg overflow-hidden cursor-pointer '>
                    <div
                        onClick={() => setProvider('stripe')}
                        className={`px-2.5 border-r border-gray-100 dark:border-gray-dark py-1.5 ${provider == 'stripe' ? 'bg-orange text-white dark:text-white' : 'dark:text-white'}`}>
                        Stripe
                    </div>
                    <div

                        onClick={() => setProvider('paypal')}
                        className={`px-2.5 border-r border-gray-100 dark:border-gray-dark py-1.5 ${provider == 'paypal' ? 'bg-orange text-white dark:text-white' : 'dark:text-white'}`}
                    >
                        Paypal
                    </div>
                </div>
            </div>
            <div className='flex flex-col space-y-2'>
                <label htmlFor='provider' className='text-sm whitespace-nowrap text-black dark:text-white font-semibold max-w-min'>
                    <div className='relative'>Method
                        {<div className='text-red absolute -top-1.5 -right-2'>*</div>}
                    </div>
                </label>
                <div className='flex items-center text-sm space-x-2 font-regular   cursor-pointer '>
                    <div
                        onClick={() => setMethodProvider('Credit Card')}
                        className={`px-2.5 border border-gray-100 dark:border-gray-dark rounded-lg  py-1.5 ${methodProvider == 'Credit Card' ? 'bg-orange text-white' : 'dark:text-white'}`}>
                        Credit Card
                    </div>
                    <div

                        onClick={() => setMethodProvider('Bank Transfer')}
                        className={`px-2.5 border border-gray-100 dark:border-gray-dark rounded-lg  py-1.5 ${methodProvider == 'Bank Transfer' ? 'bg-orange text-white' : 'dark:text-white'}`}
                    >
                        Bank Transfer
                    </div>
                    <div

                        onClick={() => setMethodProvider('PayPal')}
                        className={`px-2.5 border border-gray-100 dark:border-gray-dark rounded-lg  py-1.5 ${methodProvider == 'PayPal' ? 'bg-orange text-white' : 'dark:text-white'}`}
                    >
                        PayPal
                    </div>
                </div>
            </div>


        </div>
        <div className='flex items-center mt-5 space-x-2'>
            <Button
                title={'Create'}
                onButtonPressed={() => setPaymentModal(!paymentModal)}
                type={'submit'}
                textSize={'text-xs md:text-sm max-w-min px-4'}
                height={'h-8 md:h-10'}
            />
            <Button
                title={'Create & create another'}
                type={'submit'}
                textSize={'text-xs md:text-sm  px-4'}
                height={'h-8 md:h-10'}
                background={'bg-white'}
                color="text-black"
                border={true}
            />
            <Button
                title={'Cancel'}
                type={'button'}
                onButtonPressed={() => setPaymentModal(!paymentModal)}
                textSize={'text-xs md:text-sm max-w-min px-4'}
                height={'h-8 md:h-10'}
                background={'bg-white'}
                color="text-black"
                border={true}
            />
        </div>
    </form>
    )

    return (
        <div className='mt-4'>
            <DeleteModalComponent
                onModalStatus={() => setDeleteModal(!deleteModal)}
                modal={deleteModal}
                type={'delete'}
            />
            <ModalComponent
                style="w-11/12 sm:w-10/12 md:w-1/2  2xl:w-[22%] top-[25%] lg:top-[30%] "
                modal={paymentModal}
                onModalStatus={() => setPaymentModal(!paymentModal)}
                content={PaymentModalBody}
            />

            <CardLayout
                showTitle={true}
                title='Payments'
                button={false}
                buttonTitle={'New Payment'}
                buttonType={'orange'}
                customButtonsStatus={true}
                customButtons={
                    <div className='flex items-center justify-center'>
                        <Button
                            type='button'
                            textSize={'text-xs md:text-sm px-4'}
                            height={'h-8 md:h-9'}
                            background={'bg-orange'}
                            title={'New Payment'}
                            onButtonPressed={() => setPaymentModal(!paymentModal)}
                        />
                    </div>
                }
                content={<DataTable
                    data={data}
                    groupBy={false}
                    columns={columns} options={options} />} />
        </div>


    )
}
