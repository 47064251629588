import React, { useState } from 'react'

import { Button, CustomSelectWithLabel, InputWithLabel, PageHeading } from '../../../Components/Atoms'
import { useAppNavigate } from '../../../Hooks'
import { AddressTable, DeleteModalComponent, Layout, ModalComponent, PaymentTable } from '../../../Components/Organism'
import { BreadCrum, Tabs } from '../../../Components/Molecules'
import { FaTimes } from 'react-icons/fa'

export const EditCustomer = () => {
    const navigate = useAppNavigate()

    const [deleteModal, setDeleteModal] = useState(false)

    const [tab, setTab] = useState(1)

    const onSubmitForm = (e) => {
        e.preventDefault()
        const formData = new FormData(e.target)
        const data = Object.fromEntries(formData)
        console.log(data)
        // navigate('/dashboard')
    }

    const tabList = [
        {
            id: 1,
            name: 'Address',
            active: true
        },
        {
            id: 2,
            name: 'Payment',
            active: false
        }
    ]

    return (
        <Layout>
            <div className='p-5 md:p-10 font-inter'>
                <div className='py-1.5'>
                    <BreadCrum content={[
                        {
                            title: 'Customers',
                            url: '/shop/customers'
                        },
                        {
                            title: 'Bette Swaniawski', // replace customer name
                            url: '/shop/customers/1/edit'
                        },
                        {
                            title: 'Edit', //
                            url: '/shop/customers/1/edit'
                        },

                    ]} />
                </div>
                <DeleteModalComponent
                    onModalStatus={() => setDeleteModal(!deleteModal)}
                    modal={deleteModal}    
                    type={'delete'}                
                />
                <div className='flex items-center justify-between'>
                    <PageHeading title='Edit Bette Swaniawski' />
                    <Button
                        title={'Delete'}
                        type={'button'}
                        textSize={'text-xs md:text-sm  px-4'}
                        height={'h-9'}
                        background={'bg-red'}
                        color="text-white"
                        border={false}
                        onButtonPressed={()=> setDeleteModal(!deleteModal)}
                    />
                </div>
                <form
                    onSubmit={onSubmitForm}
                    className='mt-5  '
                >
                    <div className='grid md:grid-cols-2 lg:grid-cols-3 gap-4'>
                        <div
                            className='lg:col-span-2 grid grid-cols-2 gap-4 border border-gray-100 dark:border-gray-dark  bg-white dark:bg-transparent rounded-xl px-6 md:px-8 pt-6 pb-10'
                        >
                            <InputWithLabel
                                title={'Name'}
                                name="name"
                                placeholder='Enter name'
                                password={false}
                                type={'text'}
                                isRequired={true}
                            />
                            <InputWithLabel
                                title={'Email address'}
                                name="email"
                                placeholder='Enter email'
                                password={false}
                                type={'email'}
                                isRequired={true}
                            />
                            <InputWithLabel
                                title={'Phone'}
                                name="phone"
                                placeholder='Enter phone no.'
                                password={false}
                                type={'tel'}
                                isRequired={false}
                            />
                            <InputWithLabel
                                title={'Birthday'}
                                name="phone"
                                placeholder='Enter phone no.'
                                password={false}
                                type={'date'}
                                isRequired={false}
                            />

                        </div>
                        <div className='border space-y-4 border-gray-100 dark:border-gray-dark  bg-white dark:bg-transparent rounded-xl p-6'>
                            <div className='flexx flex-col space-y-2 text-xs lg:text-sm'>
                                <div className='font-medium'>
                                    Created at
                                </div>
                                <div>
                                    6 month ago
                                </div>
                            </div>
                            <div className='flexx flex-col space-y-2 text-xs lg:text-sm'>
                                <div className='font-medium'>
                                    Last modified at
                                </div>
                                <div>
                                    3 month ago
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className='flex items-center mt-5 space-x-2'>
                        <Button
                            title={'Save changes'}
                            type={'submit'}
                            textSize={'text-xs md:text-sm px-4'}
                            height={'h-8 md:h-10'}
                        />
                        <Button
                            title={'Cancel'}
                            type={'button'}
                            onButtonPressed={() => navigate('/shop/customers')}
                            textSize={'text-xs md:text-sm max-w-min px-4'}
                            height={'h-8 md:h-10'}
                            background={'bg-white'}
                            color="text-black"
                            border={true}

                        />
                    </div>

                </form>
                <div className='flex items-center justify-center mt-5'>
                    <Tabs list={tabList} tab={tab} setTab={setTab} />
                </div>
                {
                    tab == 1 ?
                        <AddressTable  /> :
                        <PaymentTable />
                }
            </div>

        </Layout>
    )
}
