import React from 'react'
import { CardLayout } from '../../Molecules';
import { DataTable } from '.';
import { BiSolidEdit } from 'react-icons/bi';
import { FaTimes } from 'react-icons/fa';
import { ImBin } from 'react-icons/im';
import { Button, CustomSelectWithLabel, InputWithLabel } from '../../Atoms';
import { DeleteModalComponent } from '../modal/delete';
import { ModalComponent } from '../modal';

export const AddressTable = () => {

    const [deleteModal, setDeleteModal] = React.useState(false);
    const [attachModal, setAttachModal] = React.useState(false);
    const [deAttachModal, setDeAttachModal] = React.useState(false);
    const [addressModal, setAddressModal] = React.useState(false);
    const columns = [
        {
            name: "street",
            label: "Street",
            filter: true,
            sort: true,
            sortOrder: "desc",
            visible: true,
        },
        {
            name: "zip",
            label: "Zip",
            filter: true,
            sort: true,
            sortOrder: "desc",
            visible: true,
        },
        {
            name: "city",
            label: "City",
            filter: false,
            sort: true,
            sortOrder: "desc",
            visible: true,
        },
        {
            name: "country",
            label: "Country",
            filter: false,
            sort: true,
            sortOrder: "desc",
            visible: true,
        },
        {
            name: "",
            label: "Action",
            filter: true,
            sort: true,
            sortOrder: "desc",
            visible: true,
            customComponent: (props) => {
                return (
                    <div className='flex items-center justify-center space-x-3'>
                        <div

                            onClick={()=> setAddressModal(!addressModal)}
                            className='text-orange cursor-pointer flex items-center justify-center space-x-1 text-sm'>
                            <div><BiSolidEdit className='text-lg' /></div>
                            <div className='font-medium'>Edit</div>
                        </div>
                        <div
                            onClick={() => setDeAttachModal(!deAttachModal)}
                            className='text-red cursor-pointer flex items-center justify-center space-x-1 text-sm'>
                            <div><FaTimes className='text-lg' /></div>
                            <div className='text-red font-medium'>Detach</div>
                        </div>
                        <div
                            onClick={() => setDeleteModal(!deleteModal)}
                            className='text-red cursor-pointer flex items-center justify-center space-x-1 text-sm'>
                            <div><ImBin className='text-lg' /></div>
                            <div className='text-red font-medium'>Delete</div>
                        </div>
                    </div>
                );
            },
        },

    ];

    const data = [
        {
            id: 1,
            street: 'Street 1',
            zip: '123456',
            city: 'City 1',
            country: 'Country 1',

        },
        {
            id: 2,
            street: 'Street 2',
            zip: '123456',
            city: 'City 1',
            country: 'Country 1',

        },
    ]

    const options = {
        paginationTop: true,
        paginationBottom: true,
    };

    const onSubmitForm = (e) => {
        e.preventDefault()
        const formData = new FormData(e.target)
        const data = Object.fromEntries(formData)
        console.log(data)
        // navigate('/dashboard')
    }

    const AttachModalBody = (<form

        className='p-5 border dark:border-gray-dark dark:bg-black-PRIMARY rounded-md dark:text-white'
    >
        <div className='flex items-center justify-between pb-6 dark:bg-black-PRIMARY'>
            <div className='text-xl font-semibold dark:text-white'>
                Create payment
            </div>
            <div>
                <FaTimes
                    onClick={() => setAttachModal(!attachModal)}
                    className='text-gray-icon cursor-pointer' />
            </div>
        </div>
        <div
            className=''
        >
            <CustomSelectWithLabel
                title={'Order'}
                name="order"
                isRequired={true}
                placeholder='Enter order'
                options={[
                    {
                        id: 1,
                        label: 'Order 1',
                        value: 'order 1'
                    },
                ]}
            />
        </div>
        <div className='flex items-center mt-5 space-x-2'>
            <Button
                title={'Attach'}
                onButtonPressed={() => setAttachModal(!attachModal)}
                type={'submit'}
                textSize={'text-xs md:text-sm max-w-min px-4'}
                height={'h-8 md:h-10'}
            />
            <Button
                title={'Attach & attach another'}
                type={'submit'}
                textSize={'text-xs md:text-sm  px-4'}
                height={'h-8 md:h-10'}
                background={'bg-white'}
                color="text-black"
                border={true}
            />
            <Button
                title={'Cancel'}
                type={'button'}
                onButtonPressed={() => setAttachModal(!attachModal)}
                textSize={'text-xs md:text-sm max-w-min px-4'}
                height={'h-8 md:h-10'}
                background={'bg-white'}
                color="text-black"
                border={true}
            />
        </div>
    </form>
    )

    const AddressModalBody = (<form
        onSubmit={onSubmitForm}
        className='p-5 border dark:border-gray-dark dark:bg-black-PRIMARY rounded-md dark:text-white'
    >
        <div className='flex items-center justify-between pb-6'>
            <div className='text-xl font-semibold'>
                Create address
            </div>
            <div>
                <FaTimes
                    onClick={() => setAddressModal(!addressModal)}
                    className='text-gray-icon cursor-pointer' />
            </div>
        </div>
        <div
            className=' grid grid-cols-2 gap-4 '
        >
            <InputWithLabel
                title={'Street'}
                name="street"
                placeholder='Enter street address'
                password={false}
                type={'text'}
                isRequired={true}
            />
            <InputWithLabel
                title={'Zip'}
                name="zip"
                placeholder='Enter zip'
                password={false}
                type={'text'}
                isRequired={true}
            />
            <InputWithLabel
                title={'City'}
                name="city"
                placeholder='Enter city'
                password={false}
                type={'text'}
                isRequired={false}
            />
            <InputWithLabel
                title={'State'}
                name="state"
                placeholder='Enter state'
                password={false}
                type={'text'}
                isRequired={false}
            />
            <CustomSelectWithLabel
                title={'Country'}
                name="country"
                placeholder='Enter country'
                options={[
                    {
                        id: 1,
                        label: 'Pakistan',
                        value: 'pakistan'
                    },
                    {
                        id: 2,
                        label: 'India',
                        value: 'india'
                    },
                ]}
            />
        </div>


        <div className='flex items-center mt-5 space-x-2'>
            <Button
                title={'Create'}
                onButtonPressed={() => setAddressModal(!addressModal)}
                type={'submit'}
                textSize={'text-xs md:text-sm max-w-min px-4'}
                height={'h-8 md:h-10'}
            />
            <Button
                title={'Create & create another'}
                type={'submit'}
                textSize={'text-xs md:text-sm  px-4'}
                height={'h-8 md:h-10'}
                background={'bg-white'}
                color="text-black"
                border={true}
            />
            <Button
                title={'Cancel'}
                type={'button'}
                onButtonPressed={() => setAddressModal(!addressModal)}
                textSize={'text-xs md:text-sm max-w-min px-4'}
                height={'h-8 md:h-10'}
                background={'bg-white'}
                color="text-black"
                border={true}
            />
        </div>

    </form>
    )

    return (
        <div className='mt-4'>
            <ModalComponent
                style="w-11/12 sm:w-10/12 md:w-1/2  2xl:w-[22%] top-[25%] lg:top-[30%] "
                modal={addressModal}
                onModalStatus={() => setAddressModal(!addressModal)}
                content={AddressModalBody}
            />

            <DeleteModalComponent
                onModalStatus={() => setDeleteModal(!deleteModal)}
                modal={deleteModal}
                type={'delete'}
            />
            <DeleteModalComponent
                onModalStatus={() => setDeAttachModal(!deAttachModal)}
                modal={deAttachModal}
                type={''}
            />
            <ModalComponent
                style="w-11/12 sm:w-10/12 md:w-1/2 lg:w-4/12  2xl:w-[22%] top-[25%] lg:top-[30%] "
                modal={attachModal}
                onModalStatus={() => setAttachModal(!attachModal)}
                content={AttachModalBody}
            />

            <CardLayout showTitle={true} title='Address'
                button={false}
                customButtonsStatus={true}
                customButtons={
                    <div className='flex items-center justify-center space-x-2'>
                        <Button
                            type='button'
                            textSize={'text-xs md:text-sm px-4'}
                            height={'h-8 md:h-9'}
                            background={'bg-white'}
                            color={'text-black'}
                            title={'Attach'}
                            border={true}
                            onButtonPressed={() => setAttachModal(!attachModal)}
                        />
                        <Button
                            type='button'
                            textSize={'text-xs md:text-sm px-4'}
                            height={'h-8 md:h-9'}
                            background={'bg-orange'}
                            title={'New address'}
                            onButtonPressed={() => setAddressModal(!addressModal)}
                        />
                    </div>
                }
                buttonTitle={'New Address'}
                buttonType={'orange'}
                content={<DataTable
                    data={data}
                    groupBy={false}
                    columns={columns} options={options} />}
            />
        </div>


    )
}
