import React from "react";
import Modal from "react-modal";
import { LibraryModalComponent } from "./uploadImage";

const customStyles = {
    content: {
        position: "absolute",
        border: "none",
        background: "#fff",
        outline: "none",
        padding: "0px",
        zIndex: "9999 !important",
    },
};

Modal.defaultStyles.overlay.backgroundColor = "rgba(0,0,0,0.3)";

export const UploadModalComponent = ({
    defaultStyle = true,
    style,
}) => {
    const [modal, setModal] = React.useState(false);
    const onModalStatus = () => {
        setModal(!modal);
    }
    return (
        <div>
            <div
                className='border text-center w-full text-black dark:text-white text-sm  py-8  border-gray-100 dark:border-gray-dark rounded-lg focus:outline-none focus:ring-2 focus:ring-orange'
            >
                <div className='flex flex-col space-y-2 '>
                    <div
                        onClick={onModalStatus}
                        className='flex space-x-2 items-center justify-center '>
                        <div className='text-gray-text'>
                            Drag & drop your file or
                        </div> <div className="text-orange font-medium cursor-pointer">Browse</div>
                    </div>
                </div>
            </div>


            <Modal

                className={` ${defaultStyle ? "w-11/12 sm:w-10/12 md:w-8/12 xl:w-10/12 top-[25%] lg:top-[15%] 2xl:top-[20%] rounded-lg shadow-equal_dark" : style
                    } mx-auto absolute right-0 left-0 rounded-xl shadow-equal_dark`}
                isOpen={modal}
                onRequestClose={onModalStatus}
                style={customStyles}
                overlayClassName=""
            >
                <LibraryModalComponent />
            </Modal>
        </div>
    );
};

Modal.setAppElement("#root");