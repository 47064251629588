import React, { useCallback, useRef, useState } from 'react'
import { Button, CustomSelectWithLabel, InputWithLabel, PageHeading } from '../../../Components/Atoms'
import { useAppNavigate } from '../../../Hooks'
import { DeleteModalComponent, Layout, ModalComponent, PaymentTable } from '../../../Components/Organism'
import { BreadCrum, CardLayout, RichTextEditor } from '../../../Components/Molecules'
import { BsStars } from 'react-icons/bs'
import { LuRefreshCw } from 'react-icons/lu'
import { IoIosCloseCircle } from 'react-icons/io'
import { BsPatchCheckFill } from "react-icons/bs";
import { ImTruck } from "react-icons/im";
import { OrderItemsCard } from '../../../Components/Molecules/Cards'
import { FaTimes } from 'react-icons/fa'

export const EditOrder = () => {
    const navigate = useAppNavigate()
    const [deleteModal, setDeleteModal] = useState(false)
    const [paymentModal, setPaymentModal] = useState(false)
    const [provider, setProvider] = useState('stripe')
    const [methodProvider, setMethodProvider] = useState('Credit Card')
    const [resetModal, setResetModal] = useState(false)
    const [list, setList] = useState([{
        id: 1,
        title: 'pending',
        price: 100,
        quantity: 1,
    },
    {
        id: 2,
        title: 'completed',
        price: 100,
        quantity: 1,
    },
    {
        id: 3,
        title: 'cancelled',
        price: 100,
        quantity: 1,
    },
]);

    const dragOver = useRef(null);
    const dragItem = useRef(null);

    const onSubmitForm = (e) => {
        e.preventDefault()
        const formData = new FormData(e.target)
        const data = Object.fromEntries(formData)
        console.log(data)
        // navigate('/dashboard')
    }
    const statusColor = {
        'New': "bg-blue text-white",
        'Processing': "bg-orange text-white",
        'Shipped': "bg-green text-white",
        'Delivered': "bg-green text-white",
        'Cancelled': "bg-red text-white",
    }

    const statusIcons = {
        'New': <BsStars />,
        'Processing': <LuRefreshCw />,
        'Cancelled': <IoIosCloseCircle />,
        'Delivered': <BsPatchCheckFill />,
        'Shipped': <ImTruck />

    }
    const [statusList, setStatusList] = useState(
        [
            {
                id: 1,
                status: 'New',
                active: true
            },
            {
                id: 2,
                status: 'Processing',
                active: false,
            },
            {
                id: 3,
                status: 'Shipped',
                active: false,
            },
            {
                id: 4,
                status: 'Delivered',
                active: false,
            },
            {
                id: 5,
                status: 'Cancelled',
                active: false,
            },

        ]
    )

    const onSelectStatus = (id) => {
        const updatedStatusList = statusList.map((status) => {
            if (status.id === id) {
                return { ...status, active: true };
            } else {
                return { ...status, active: false };
            }
        });

        setStatusList(updatedStatusList);
    }

    const PaymentModalBody = (<form
        onSubmit={onSubmitForm}
        className='p-5'
    >
        <div className='flex items-center justify-between pb-6'>
            <div className='text-xl font-semibold'>
                Create payment
            </div>
            <div>
                <FaTimes
                    onClick={() => setPaymentModal(!paymentModal)}
                    className='text-gray-icon cursor-pointer' />
            </div>
        </div>
        <div
            className=' grid grid-cols-2 gap-4  bg-white rounded-xl '
        >
            <CustomSelectWithLabel
                title={'Order'}
                name="order"
                isRequired={true}
                placeholder='Enter order'
                options={[
                    {
                        id: 1,
                        label: 'Order 1',
                        value: 'order 1'
                    },
                ]}
            />

            <InputWithLabel
                title={'Reference'}
                name="ref"
                placeholder='Enter Ref'
                password={false}
                type={'text'}
                isRequired={true}
            />
            <InputWithLabel
                title={'Amount'}
                name="amount"
                placeholder='Enter amount'
                password={false}
                type={'text'}
                isRequired={false}
            />
            <CustomSelectWithLabel
                title={'Currency'}
                name="currency"
                placeholder='Enter currency'
                isRequired={true}
                options={[
                    {
                        id: 1,
                        label: '$100',
                        value: '$100'
                    },
                ]}
            />
            <div className='flex flex-col space-y-2'>
                <label htmlFor='provider' className='text-sm whitespace-nowrap text-black font-semibold max-w-min'>
                    <div className='relative'>Provider
                        {<div className='text-red absolute -top-1.5 -right-2'>*</div>}
                    </div>
                </label>
                <div className='border flex items-center text-sm max-w-min font-regular  border-gray-100 rounded-lg overflow-hidden cursor-pointer '>
                    <div
                        onClick={() => setProvider('stripe')}
                        className={`px-2.5 border-r border-gray-100 py-1.5 ${provider == 'stripe' ? 'bg-orange text-white' : ''}`}>
                        Stripe
                    </div>
                    <div

                        onClick={() => setProvider('paypal')}
                        className={`px-2.5 border-r border-gray-100 py-1.5 ${provider == 'paypal' ? 'bg-orange text-white' : ''}`}
                    >
                        Paypal
                    </div>
                </div>
            </div>
            <div className='flex flex-col space-y-2'>
                <label htmlFor='provider' className='text-sm whitespace-nowrap text-black font-semibold max-w-min'>
                    <div className='relative'>Method
                        {<div className='text-red absolute -top-1.5 -right-2'>*</div>}
                    </div>
                </label>
                <div className='flex items-center text-sm space-x-2 font-regular   cursor-pointer '>
                    <div
                        onClick={() => setMethodProvider('Credit Card')}
                        className={`px-2.5 border border-gray-100 rounded-lg  py-1.5 ${methodProvider == 'Credit Card' ? 'bg-orange text-white' : ''}`}>
                        Credit Card
                    </div>
                    <div

                        onClick={() => setMethodProvider('Bank Transfer')}
                        className={`px-2.5 border border-gray-100 rounded-lg  py-1.5 ${methodProvider == 'Bank Transfer' ? 'bg-orange text-white' : ''}`}
                    >
                        Bank Transfer
                    </div>
                    <div

                        onClick={() => setMethodProvider('PayPal')}
                        className={`px-2.5 border border-gray-100 rounded-lg  py-1.5 ${methodProvider == 'PayPal' ? 'bg-orange text-white' : ''}`}
                    >
                        PayPal
                    </div>
                </div>
            </div>


        </div>
        <div className='flex items-center mt-5 space-x-2'>
            <Button
                title={'Create'}
                onButtonPressed={() => setPaymentModal(!paymentModal)}
                type={'submit'}
                textSize={'text-xs md:text-sm max-w-min px-4'}
                height={'h-8 md:h-10'}
            />
            <Button
                title={'Create & create another'}
                type={'submit'}
                textSize={'text-xs md:text-sm  px-4'}
                height={'h-8 md:h-10'}
                background={'bg-white'}
                color="text-black"
                border={true}
            />
            <Button
                title={'Cancel'}
                type={'button'}
                onButtonPressed={() => setPaymentModal(!paymentModal)}
                textSize={'text-xs md:text-sm max-w-min px-4'}
                height={'h-8 md:h-10'}
                background={'bg-white'}
                color="text-black"
                border={true}
            />
        </div>
    </form>
    )

    const onDragEnd = () => {
        const _list = [...list];
        const draggedItem = _list[dragItem.current]; // Get the dragged item
        const dragOverItem = _list[dragOver.current]; // Get the item at dragOver position

        _list[dragOver.current] = draggedItem; // Replace drag item with dragOver item
        _list[dragItem.current] = dragOverItem; // Replace drag item with dragOver item

        setList(_list);
        dragItem.current = null;
        dragOver.current = null;
    };


    return (
        <Layout>
            <DeleteModalComponent
                onModalStatus={() => setResetModal(!resetModal)}
                modal={resetModal}
                type={'reset'}
            />

            <DeleteModalComponent
                onModalStatus={() => setDeleteModal(!deleteModal)}
                modal={deleteModal}
                type={'delete'}
            />
            <ModalComponent
                style="w-11/12 sm:w-10/12 md:w-1/2  2xl:w-[22%] top-[25%] lg:top-[30%] "
                modal={paymentModal}
                onModalStatus={() => setPaymentModal(!paymentModal)}
                content={PaymentModalBody}
            />

            <div className='p-5 md:p-10 font-inter'>
                <div className='py-1.5'>
                    <BreadCrum content={[
                        {
                            title: 'Orders',
                            url: '/shop/orders'
                        },
                        {
                            title: 'O421',
                            url: '/shop/orders/1/edit'
                        },
                        {
                            title: 'Edit',
                            url: '/shop/orders/1/edit'
                        },

                    ]} />
                </div>

                <div className='flex items-center justify-between'>
                    <PageHeading title='Edit O421' />
                    <Button
                        title={'Delete'}
                        type={'button'}
                        textSize={'text-xs md:text-sm  px-4'}
                        height={'h-9'}
                        background={'bg-red'}
                        color="text-white"
                        onButtonPressed={() => setDeleteModal(!deleteModal)}
                        border={false} />
                </div>

                <form
                    onSubmit={onSubmitForm}>
                    <div className='grid md:grid-cols-3 lg:grid-cols-3 gap-4 '>
                        <div className='md:col-span-2 flex flex-col space-y-4'>
                            <div
                                className='border mt-5 border-gray-100  dark:border-gray-dark dark:bg-transparent bg-white rounded-xl px-6 md:px-8 pt-6 pb-10'
                            >
                                <div
                                    className=' grid md:grid-cols-2 gap-4'
                                >
                                    <InputWithLabel
                                        title={'Number'}
                                        name="number"
                                        placeholder='Enter number'
                                        password={false}
                                        type={'text'}
                                        isRequired={false}
                                    />
                                    <CustomSelectWithLabel
                                        title={'Customer'}
                                        name="customer"
                                        placeholder='Enter customer'
                                        isRequired={true}
                                        button={true}
                                        options={[{
                                            label: 'Customer 1',
                                            value: 'customer1'
                                        }, {
                                            label: 'Customer 2',
                                            value: 'customer2'

                                        }]}
                                    />

                                    <div className='flex flex-col space-y-2'>
                                        <label htmlFor='status' className='text-sm whitespace-nowrap text-black dark:text-white dark:border-gray-dark  font-semibold max-w-min'>
                                            <div className='relative'>Status
                                                <div className='text-red absolute -top-1.5 -right-2'>*</div>
                                            </div>
                                        </label>
                                        <div className='flex flex-wrap gap-2'>
                                            {
                                                statusList.map((item) =>
                                                    <div key={item.id}
                                                        className='cursor-pointer'
                                                        onClick={() => onSelectStatus(item.id)}
                                                    >
                                                        <span className={`px-3 cursor-pointer max-w-min flex items-center space-x-1.5 justify-center text-sm leading-5 font-semibold rounded-md lg:rounded-lg ${item.active ? statusColor[item.status] : 'bg-white dark:bg-black-SECONDARY text-black dark:text-white border dark:border-gray-dark border-gray-100'} py-1.5 lg:py-2 `}>
                                                            <div className={`${item.active ? 'text-white' : 'text-sm lg:text-base text-gray-icon'}`}>{statusIcons[item.status]}</div> <div>{item.status}</div>
                                                        </span>
                                                    </div>
                                                )


                                            }
                                        </div>


                                    </div>
                                    <CustomSelectWithLabel
                                        title={'Currency'}
                                        name="currency"
                                        placeholder='Enter currency'
                                        isRequired={true}
                                        button={false}
                                        options={[{
                                            label: 'Customer 1',
                                            value: 'customer1'
                                        }
                                        ]}
                                    />

                                </div>
                                <div className='grid md:grid-cols-2 gap-4 mt-4'>
                                    <CustomSelectWithLabel
                                        title={'Country'}
                                        name="country"
                                        placeholder='Enter country'
                                        isRequired={true}
                                        button={false}
                                        options={[{
                                            label: 'Customer 1',
                                            value: 'customer1'
                                        }, {
                                            label: 'Customer 2',
                                            value: 'customer2'

                                        }]}
                                    />
                                </div>
                                <div className='mt-4'>
                                    <InputWithLabel
                                        title={'Street Number'}
                                        name="text"
                                        placeholder='Enter street number'
                                        password={false}
                                        type={'text'}
                                        isRequired={false}
                                    />
                                </div>
                                <div className='mt-4 grid md:grid-cols-3 gap-4'>
                                    <InputWithLabel
                                        title={'City'}
                                        name="text"
                                        placeholder='Enter city'
                                        password={false}
                                        type={'text'}
                                        isRequired={false}
                                    />
                                    <InputWithLabel
                                        title={'State / Province'}
                                        name="text"
                                        placeholder='Enter state / province'
                                        password={false}
                                        type={'text'}
                                        isRequired={false}
                                    />
                                    <InputWithLabel
                                        title={'Zip / Postal code'}
                                        name="text"
                                        placeholder='Enter street number'
                                        password={false}
                                        type={'text'}
                                        isRequired={false}
                                    />
                                </div>

                                <div className='mt-4'>
                                    <RichTextEditor
                                        name="notes"
                                        title="Notes"
                                        isRequired={false}
                                    />
                                </div>

                            </div>

                            <CardLayout
                                title={'Order Items'}
                                button={true}
                                buttonTitle={'Reset'}
                                buttonType={'delete'}
                                onButtonPressed={() => setResetModal(!resetModal)}
                                showTitle={true}
                                content={
                                    <div className='p-5 flex flex-col items-center justify-center space-y-4'>
                                        {
                                            list.map((item,index) => 
                                            <OrderItemsCard
                                                key={item}
                                                id={item}
                                                item={item}
                                                showTitle={true}
                                                Draggable={true}
                                                onDragStart={() => (dragItem.current = index)}
                                                onDragEnter={() => (dragOver.current = index)}
                                                onDragEnd={onDragEnd}
                                            />)
                                        }
                                        <Button
                                            title={'Add item'}
                                            type={'button'}
                                            textSize={'text-xs md:text-sm  px-4'}
                                            height={'h-9'}
                                            background={'bg-white'}
                                            color="text-black"
                                            border={true}
                                        />
                                    </div>
                                }

                            />
                        </div>
                        <div className='max-h-[250px] mt-5 w-full border space-y-4 border-gray-100 dark:border-gray-dark dark:bg-black-PRIMARY  dark:text-white rounded-xl p-6'>
                            <div className='flex flex-col space-y-2 text-xs lg:text-sm'>
                                <div className='font-medium'>
                                    Created at
                                </div>
                                <div>
                                    6 month ago
                                </div>
                            </div>
                            <div className='flex flex-col space-y-2 text-xs lg:text-sm'>
                                <div className='font-medium'>
                                    Last modified at
                                </div>
                                <div>
                                    3 month ago
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='flex items-center w-full mt-5 space-x-2'>
                        <Button
                            title={'Save changes'}
                            type={'submit'}
                            textSize={'text-xs md:text-sm px-4'}
                            height={'h-8 md:h-9'}
                        />

                        <Button
                            title={'Cancel'}
                            type={'button'}
                            onButtonPressed={() => navigate('/shop/orders')}
                            textSize={'text-xs md:text-sm max-w-min px-4'}
                            height={'h-8 md:h-9'}
                            background={'bg-white'}
                            color="text-black"
                            border={true}
                        />

                    </div>

                </form>
                <div className='mt-4'>
                    <PaymentTable />
                </div>
            </div>
        </Layout>


    )
}
