import React from 'react'
import { IoIosSearch } from "react-icons/io";

export const Search = ({onSearchItem}) => {
    return (
        <div className='bg-white dark:bg-transparent flex items-center rounded-md md:rounded-lg px-4 py-0.5 space-x-2 border border-gray-100 dark:border-gray-dark hover:border-orange hover:duration-[1000ms]'>

            <IoIosSearch className='text-xl lg:text-2xl text-gray-400' />
            <input
                className="appearance-none bg-transparent placeholder-gray-400 text-opacity text-sm font-regular h-full rounded w-full py-1.5 text-gray-700 dark:text-white leading-tight focus:outline-none focus:shadow-outline"
                id="search"
                type="text"
                defaultValue={''}
                autoComplete='off'                
                placeholder="Search"
                onChange={onSearchItem}
                />

        </div>
    )
}
