import React from 'react';
import classNames from 'classnames';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa6';
import { Checkbox, CustomSelect, CustomSelectWithLabel, IconWithQuantity, InputWithLabel, Search } from '../../Atoms';
import { RiFilter2Fill } from "react-icons/ri";
import { DeleteIcon } from '../../Atoms/Icons';
export const DataTable = ({
    data,
    columns,
    options,
    groupBy,
    sortBy,
    deleteRowsFilter,
    columnRowsFilter,
    showHeading = true,
}) => {
    const [columnsList, setColumnsList] = React.useState(columns);
    const [tableData, setTableData] = React.useState(data);
    const [currentPage, setCurrentPage] = React.useState(1);
    const [itemsPerPage, setItemsPerPage] = React.useState(10);
    const [columnFilter, setColumnFilter] = React.useState(false);
    const [deleteFilter, setDeleteFilter] = React.useState(false);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentTableData = tableData.slice(startIndex, endIndex);

    const onHeaderClick = (col) => {
        if (col.sort) {
            const clone = [...columns]
            const index = clone.findIndex(c => c.name === col.name)
            clone[index].sortOrder = clone[index].sortOrder === 'asc' ? 'desc' : 'asc'
            setColumnsList(clone)
        }
    };

    const filterableColumns = columns.filter(column => column.filter === true);

    const onSearchItem = (e) => {
        const value = e.target.value;
        console.log(value)
        const filteredData = data.filter((item) => {
            return filterableColumns.some((column) => {
                const columnValue = item[column.name];
                return (
                    columnValue &&
                    columnValue
                        .toString()
                        .toLowerCase()
                        .indexOf(value.toLowerCase()) > -1
                );
            });
        });
        setTableData(filteredData);
    }

    const onPageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const onItemsPerPageChange = (e) => {
        if (e.target.value === 'All') {
            setItemsPerPage(tableData.length);
        } else {
            setItemsPerPage(e.target.value);
        }
        setCurrentPage(1);
    };

    const renderPagination = () => {
        const totalPages = Math.ceil(tableData.length / itemsPerPage);

        return (
            <div className="flex items-center justify-between px-5 py-3">
                {
                    currentPage > 1 ? <button
                        className='text-black dark:text-white dark:bg-gray-SECONDARY  px-4 py-1 border font-semibold text-xs lg:text-sm border-gray-100 dark:border-gray-dark rounded-md h-9'
                        onClick={() => onPageChange(currentPage - 1)} disabled={currentPage === 1}>
                        Previous
                    </button> : <div></div>

                }
                <div className='border border-gray-100 dark:border-gray-dark dark:bg-black-SECONDARY rounded-md text-sm flex space-x-2  h-9 items-center pr-2'>
                    <div className='border-r h-full flex items-center justify-center px-3 border-gray-300 dark:border-gray-dark text-gray-text dark:text-white'>Per page</div>
                    <select className='px-2 text-gray-icon dark:text-gray-icon outline-none bg-transparent' value={itemsPerPage} onChange={onItemsPerPageChange}>
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                        <option value='All'>All</option>
                    </select>
                </div>
                <button
                    className='text-black dark:text-white dark:bg-black-SECONDARY px-4 py-1 border font-semibold text-xs lg:text-sm border-gray-100 dark:border-gray-dark rounded-md h-9'
                    onClick={() => onPageChange(currentPage + 1)} disabled={currentPage === totalPages}>
                    Next
                </button>
            </div>
        );
    };

    return (
        <div className="w-full dark:bg-black-PRIMARY">
            <div className='px-5 py-3 flex items-center justify-between  border-b border-gray-100 dark:border-gray-dark'>
                <div>
                    {
                        groupBy ? <CustomSelect options={[
                            { label: 'Group by', value: 'Group by' },
                            { label: 'Order Date', value: 'Order Date' },
                        ]} /> :
                            sortBy && <div className='flex items-center space-x-3'>
                                <div className='flex items-center'>
                                    <div className='border border-gray-100 dark:border-gray-dark  py-2 rounded-l-md bg-white dark:bg-black-PRIMARY px-3 text-sm'>Sort by</div>
                                    <div className='border border-l-0 bg-white dark:bg-transparent rounded-l-none rounded-md px-3 py-2 text-sm border-gray-100 dark:border-gray-dark'>
                                        <select

                                            name='value'
                                            className='w-full outline-none bg-transparent'>
                                            {
                                                [
                                                    { label: 'Name', value: 'name' },
                                                    { label: 'Email', value: 'email' },
                                                ].map((option, index) => {
                                                    return (
                                                        <option key={index} value={option.value}>{option.label}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                                <CustomSelect options={[
                                    { label: 'Ascending', value: 'ascending' },
                                    { label: 'Descending', value: 'desending' },
                                ]} />

                            </div>
                    }
                </div>

                <div className='space-x-5 flex items-center'>
                    <Search onSearchItem={onSearchItem} />
                    {
                        deleteRowsFilter &&
                        <div className='relative'>
                            <div
                                onClick={() => setDeleteFilter(!deleteFilter)}
                            >
                                <IconWithQuantity amount={0} icon={<RiFilter2Fill />} style='text-2xl cursor-pointer' />

                            </div>
                            {
                                deleteFilter &&
                                <div
                                    onMouseLeave={() => setDeleteFilter(false)}
                                    className='absolute top-7 border border-gray-100 dark:border-gray-dark rounded-md right-0 bg-white dark:bg-black-PRIMARY z-[50] w-[300px] px-4 py-4 flex flex-col'>
                                    <div className='flex items-center justify-between mb-4'>
                                        <div className='font-semibold text-sm md:text-base'>Columns</div>
                                        <div className='font-semibold text-sm text-red hover:underline hover:duration-[1000ms] cursor-pointer'>Reset</div>
                                    </div>
                                    <div className='space-y-4 '>
                                        <CustomSelectWithLabel
                                            title="Deleted records"
                                            options={[
                                                { label: 'All', value: 'All' },
                                                { label: 'Deleted', value: 'Deleted' },
                                                { label: 'Not deleted', value: 'Not deleted' },
                                            ]}
                                        />
                                        <InputWithLabel
                                            title="Created from"
                                            type="date"
                                            placeholder="Created from"
                                            name={'createdFrom'}
                                            isRequired={false}
                                        />
                                        <InputWithLabel
                                            title="Created until"
                                            type="date"
                                            placeholder="Created until"
                                            name={'createdUntil'}
                                            isRequired={false}
                                        />
                                    </div>
                                </div>
                            }
                        </div>

                    }
                    {
                        columnRowsFilter &&
                        <div className='relative'>
                            <div
                                onClick={() => setColumnFilter(!columnFilter)}
                            >
                                <DeleteIcon
                                    className='absolute top-0 right-0 text-gray-icon cursor-pointer z-10'

                                />

                            </div>
                            {
                                columnFilter &&
                                <div
                                    onMouseLeave={() => setColumnFilter(false)}
                                    className='absolute top-7 border border-gray-100 dark:border-gray-dark rounded-md right-0 bg-white dark:bg-black-PRIMARY z-[50] w-[250px] px-4 py-4 flex flex-col'>
                                    <div className='font-semibold text-sm lg:text-base'>Columns</div>
                                    <div className='space-y-4 '>
                                        <Checkbox title={'Customer'} />
                                        <Checkbox title={'Currency'} />
                                        <Checkbox title={'Shipping cost'} />
                                        <Checkbox title={'Order ate'} />
                                    </div>
                                </div>
                            }
                        </div>
                    }
                </div>

            </div>
            <div className="overflow-x-auto w-full border-b border-gray-300 dark:border-gray-dark">
                <div className="inline-block min-w-full ">
                    <table className="min-w-full divide-y divide-gray-300 dark:divide-gray-dark">
                        {
                            showHeading &&
                            <thead>
                                <tr className='bg-gray-50 dark:bg-black-SECONDARY w-full'>
                                    {columnsList?.map((col, key) => {
                                        return (
                                            <th
                                                onClick={() => onHeaderClick(col)}
                                                key={key}
                                                scope="col"
                                                className={classNames(
                                                    'py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-black dark:text-white ',
                                                    col.sort && 'hover:cursor-pointer',
                                                )}
                                            >
                                                <div className="flex items-center justify-center gap-2 ">
                                                    <div>{col.label}</div>
                                                    {col.sort ? (
                                                        col.sortOrder === 'desc' ? (
                                                            <FaChevronDown className='text-xs text-gray-icon' />
                                                        ) : (
                                                            <FaChevronUp className='text-xs text-gray-icon' />
                                                        )
                                                    ) : (
                                                        <></>
                                                    )}
                                                </div>
                                            </th>
                                        );
                                    })}
                                </tr>
                            </thead>
                        }
                        <tbody className="divide-y divide-gray-200 dark:divide-gray-dark bg-white dark:bg-black-PRIMARY">
                            {currentTableData?.map(record => (
                                <tr key={record._id}>
                                    {columns?.map((col, key) => {
                                        const CustomComponent = col?.customComponent;
                                        if (CustomComponent) {
                                            return (
                                                <td
                                                    key={key}
                                                    className="relative whitespace-nowrap py-5 pl-1 pr-4 text-left text-sm font-medium "
                                                >
                                                    <CustomComponent {...record} />
                                                </td>
                                            );
                                        }

                                        return (
                                            <td
                                                key={key}
                                                className="relative text-center whitespace-nowrap py-5  text-sm font-regular "
                                            >
                                                {record[col.name]}
                                            </td>
                                        );
                                    })}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>

            {options?.paginationBottom && renderPagination()}
        </div>
    );
};
