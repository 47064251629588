import React from 'react'
import { Button, Checkbox, CustomSelectWithLabel, InputWithLabel, PageHeading, Switch, UploadFile } from '../../../../Components/Atoms'
import { useAppNavigate } from '../../../../Hooks'
import { Layout } from '../../../../Components/Organism'
import { CardLayout, RichTextEditor } from '../../../../Components/Molecules'
import { FaChevronUp } from 'react-icons/fa6'
import { UploadModalComponent } from '../../../../Components/Organism/modal/uploadModal'


export const AddProduct = () => {
    const navigate = useAppNavigate()

    const onSubmitForm = (e) => {
        e.preventDefault()
        const formData = new FormData(e.target)
        const data = Object.fromEntries(formData)
        console.log(data)
        // navigate('/dashboard')
    }

    return (
        <Layout>
            <div className='p-5 md:p-10 font-inter'>
                <PageHeading title='Create Product' />
                <form
                    onSubmit={onSubmitForm}
                    className='mt-5 space-y-6'
                >
                    <div className='grid lg:grid-cols-3 gap-3'>
                        <div className='space-y-6 col-span-2'>
                            <div
                                className=' grid md:grid-cols-2 gap-4 border border-gray-100 dark:border-gray-dark  bg-white dark:bg-transparent rounded-xl px-5 pt-6 pb-10'
                            >
                                <InputWithLabel
                                    title={'Name'}
                                    name="name"
                                    placeholder='Enter name'
                                    password={false}
                                    type={'text'}
                                    isRequired={true}
                                />
                                <InputWithLabel
                                    title={'Slug'}
                                    name="slug"
                                    placeholder='Enter slug'
                                    password={false}
                                    type={'text'}
                                    disabled={true}
                                    isRequired={false}
                                />

                                <div className='md:col-span-2'>
                                    <RichTextEditor
                                        name="description"
                                        title="Description"
                                        isRequired={false}
                                    />
                                </div>

                            </div>

                            <div className='mt-4'>
                                <CardLayout showTitle={true} title='Images'
                                    button={false}
                                    customButtonsStatus={true}
                                    customButtons={
                                        <div className=''>
                                            <FaChevronUp className='text-gray-text' />
                                        </div>
                                    }
                                    buttonTitle={'New Address'}
                                    buttonType={'orange'}
                                    content={<div className='p-5'>
                                        <UploadModalComponent/>

                                    </div>} />
                            </div>

                            <CardLayout
                                title='Pricing'
                                showTitle={true}
                                content={
                                    <div
                                        className=' grid md:grid-cols-2 gap-4 px-5 pt-6 pb-10'
                                    >
                                        <InputWithLabel
                                            title={'Price'}
                                            name="price"
                                            placeholder='Enter price'
                                            password={false}
                                            type={'number'}
                                            isRequired={true}
                                        />
                                        <InputWithLabel
                                            title={'Cost per item'}
                                            name="compare_at_price"
                                            placeholder='Enter price'
                                            password={false}
                                            type={'number'}
                                            disabled={true}
                                            isRequired={false}
                                        />
                                        <div className='flex flex-col space-y-1'>
                                            <InputWithLabel
                                                title={'Cost per item'}
                                                name="cost_per_item"
                                                placeholder='Enter price'
                                                password={false}
                                                type={'number'}
                                                disabled={false}
                                                isRequired={true}
                                            />

                                            <div className='text-gray-text font-regular text-xs md:text-sm'>
                                                Customers won't see this price.
                                            </div>
                                        </div>

                                    </div>
                                }
                            />
                            <CardLayout
                                title='Inventory'
                                showTitle={true}
                                content={
                                    <div
                                        className=' grid md:grid-cols-2 gap-4 px-5 pt-6 pb-10'
                                    >
                                        <InputWithLabel
                                            title={'SKU (Stock Keeping Unit)'}
                                            name="sku"
                                            placeholder='Enter SKU (Stock Keeping Unit)*'
                                            password={false}
                                            type={'text'}
                                            isRequired={true}
                                        />
                                        <InputWithLabel
                                            title={'Barcode (ISBN, UPC, GTIN, etc.)'}
                                            name="barcode"
                                            placeholder='Enter Barcode (ISBN, UPC, GTIN, etc.)'
                                            password={false}
                                            type={'text'}
                                            disabled={true}
                                            isRequired={true}
                                        />

                                        <InputWithLabel
                                            title={'Quantity'}
                                            name="quantity"
                                            placeholder='Enter Quantity'
                                            password={false}
                                            type={'number'}
                                            disabled={true}
                                            isRequired={true}
                                        />

                                        <div className='flex flex-col space-y-1'>
                                            <InputWithLabel
                                                title={'Security stock'}
                                                name="security_stock"
                                                placeholder='Enter Security stock'
                                                password={false}
                                                type={'number'}
                                                disabled={true}
                                                isRequired={true}
                                            />

                                            <div className=' font-medium text-xs md:text-sm text-gray-text'>
                                                The safety stock is the limit stock for your products which alerts you if the product stock will soon be out of stock.
                                            </div>
                                        </div>

                                    </div>
                                }
                            />
                            <CardLayout
                                title='Shipping'
                                showTitle={true}
                                content={
                                    <div
                                        className=' grid md:grid-cols-2 gap-4 px-5 pt-6 pb-10'
                                    >
                                        <Checkbox
                                            title={'This product can be returned'}
                                        />
                                        <Checkbox
                                            title={'This product will be shipped'}
                                        />

                                    </div>
                                }
                            />
                        </div>
                        <div className='space-y-6'>
                            <CardLayout
                                title={'Status'}
                                showTitle={true}
                                content={<div className='p-6 space-y-4'>
                                    <div className='flex flex-col space-y-1'>
                                        <Switch
                                            title={'Visible'}
                                        />
                                        <div className='text-gray-card_heading text-sm'>
                                            This product will be hidden from all sales channels.
                                        </div>
                                    </div>

                                    <InputWithLabel
                                        title="Availability"
                                        name="availability"
                                        placeholder='Enter Availability'
                                        password={false}
                                        type={'date'}
                                        disabled={false}
                                    />

                                </div>}
                            />
                            <CardLayout
                                title={'Status'}
                                showTitle={true}
                                content={<div className='p-6 space-y-4'>
                                    <CustomSelectWithLabel
                                        title={'Brand'}
                                        name="brand"
                                        placeholder='Select Brand'
                                        options={[]}
                                    />
                                    <CustomSelectWithLabel
                                        title={'Categories'}
                                        name="category"
                                        placeholder='Select Category'
                                        options={[]}
                                        isRequired={true}
                                    />
                                </div>}
                            />

                        </div>
                    </div>


                    <div className='flex items-center mt-5 space-x-2'>
                        <Button
                            title={'Create'}
                            type={'submit'}
                            textSize={'text-xs md:text-sm max-w-min px-4'}
                            height={'h-8 md:h-10'}
                        />
                        <Button
                            title={'Create & create another'}
                            type={'submit'}
                            textSize={'text-xs md:text-sm  px-4'}
                            height={'h-8 md:h-10'}
                            background={'bg-white'}
                            color="text-black"
                            border={true}
                        />
                        <Button
                            title={'Cancel'}
                            type={'button'}
                            onButtonPressed={() => navigate('/shop/customers')}
                            textSize={'text-xs md:text-sm max-w-min px-4'}
                            height={'h-8 md:h-10'}
                            background={'bg-white'}
                            color="text-black"
                            border={true}
                        />
                    </div>
                </form>
            </div>
        </Layout>
    )
}
