import React from 'react'

export const IconWithQuantity = ({amount , icon , style , onPressIcon}) => {
    return (
        <div className='relative'
        onClick={onPressIcon}
        >

            <div className={`${style} text-gray-icon`}>
            {icon}
            </div>
            <div className='absolute -top-2 font-semibold w-4 h-4 flex items-center justify-center text-xs rounded-md -right-3 bg-orange-light dark:bg-black-PRIMARY border-2 border-orange border-opacity-15 text-orange'>
                {amount}
            </div>
        </div>

    )
}
