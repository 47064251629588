import React from "react";
import PropTypes from 'prop-types'

export const Button = ({ title, type , textSize , height , onButtonPressed , background , color , border}) => {
  return <button
    type={type}
    onClick={onButtonPressed}
    className={` ${height} rounded-lg font-semibold ${textSize} ${background || 'bg-orange'} ${color || 'text-white'} ${color =='text-black' && 'dark:text-white dark:bg-black-SECONDARY'} ${border && 'border border-gray-100 dark:border-gray-dark'}`}
  >
    {title}
  </button>;
};

Button.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  textSize: PropTypes.string.isRequired,
}