import React from 'react'
import PropTypes from 'prop-types'

export const UploadFile = ({
    title,
    name,
    disabled = false,
    handleFileChange
}) => {

    return (
        <div className='flex flex-col space-y-2'>
            <label
                htmlFor={name}
                className='border text-center w-full text-black dark:text-white text-sm  py-8  border-gray-100 dark:border-gray-dark rounded-lg focus:outline-none focus:ring-2 focus:ring-orange'
            >
                {title}
            </label>
            <div className='w-full'>
                {
                    <input
                        type='file'
                        name={name}
                        id={name}
                        autoComplete='off'
                        disabled={disabled}
                        className='hidden'
                        onChange={handleFileChange}
                    />
                }

            </div>
        </div>
    )
}

UploadFile.propTypes = {
    title: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    disabled: PropTypes.bool
}