import React, { useState } from 'react'
import { Button, Checkbox, CustomSelect, PageHeading } from '../../../Components/Atoms'

import { Layout } from '../../../Components/Organism'
import { BreadCrum, CardLayout } from '../../../Components/Molecules'
import { ASSETS } from '../../../Assets/path'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa6'
import { BiSolidEdit } from 'react-icons/bi'
import { RxExternalLink } from "react-icons/rx";
import { useAppNavigate } from '../../../Hooks'

export const LinksPage = () => {

    const navigate = useAppNavigate()
    const [selectAll, setSelectAll] = useState(false);
    const [selectedCards, setSelectedCards] = useState([
        { id: 1, checked: false, active: false },
        { id: 2, checked: false, active: false },
        { id: 3, checked: false, active: false },
    ]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(18);

    const handleSelectAll = () => {
        setSelectAll(!selectAll);
        setSelectedCards(selectedCards.map(checkbox => ({ ...checkbox, checked: !selectAll })));
    };

    const handleSingleCheck = (id) => {
        setSelectedCards(selectedCards.map(checkbox =>
            checkbox.id === id ? { ...checkbox, checked: !checkbox.checked } : checkbox
        ));
    };

    const handleActiveInative = (id) => {
        setSelectedCards(selectedCards.map(item =>
            item.id === id ? { ...item, active: !item.active } : item
        ));
    }

    const getTotalSelected = () => {
        return selectedCards.filter(checkbox => checkbox.checked).length;
    };

    const renderPagination = () => {
        const totalPages = Math.ceil(selectedCards.length / itemsPerPage);
        return (
            <div className="flex items-center justify-between px-5 py-3 bg-white dark:bg-black-PRIMARY border-t border-gray-100 dark:border-gray-dark">
                {
                    currentPage > 1 ? <button
                        className='text-black dark:text-white  px-4 py-1 border font-semibold text-xs lg:text-sm border-gray-100 dark:border-gray-dark dark:bg-black-SECONDARY rounded-md h-9'
                        onClick={() => onPageChange(currentPage - 1)} disabled={currentPage === 1}>
                        Previous
                    </button> : <div></div>

                }
                <div className='border border-gray-100 dark:border-gray-dark rounded-md text-sm flex space-x-2  h-9 items-center pr-2 text-white'>
                    <div className='border-r h-full flex items-center justify-center px-3 border-gray-300 dark:border-gray-dark text-gray-text dark:text-white'>Per page</div>
                    <select className='px-2 text-gray-icon outline-none bg-transparent' value={itemsPerPage} onChange={onItemsPerPageChange}>
                        <option value={18}>18</option>
                        <option value={36}>36</option>
                        <option value={72}>72</option>
                        <option value='All'>All</option>
                    </select>
                </div>
                <button
                    className='text-black dark:text-white px-4 py-1 border font-semibold text-xs lg:text-sm border-gray-100 dark:border-gray-dark dark:bg-black-SECONDARY rounded-md h-9'
                    onClick={() => onPageChange(currentPage + 1)} disabled={currentPage === totalPages}>
                    Next
                </button>
            </div>
        );
    };

    const onPageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const onItemsPerPageChange = (event) => {
        setItemsPerPage(event.target.value);
    };

    const paginatedCards = selectedCards.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    return (
        <Layout>
            <div className='p-5 md:p-10 font-inter'>
                <div className='py-1.5'>
                    <BreadCrum content={[
                        {
                            title: 'Links',
                            url: '/blog/links'
                        },
                        {
                            title: 'List',
                            url: `/blog/links`
                        },
                    ]} />
                </div>
                <div className='flex items-center justify-between'>
                    <PageHeading title='Links' />
                    <div className='flex items-center space-x-2'>
                        <Button
                            title={'New Link'}
                            type={'button'}
                            textSize={'text-xs md:text-sm  px-4'}
                            height={'h-9'}
                            background={'bg-orange'}
                            color="text-white"
                            border={false}
                            onButtonPressed={() => navigate(`/blog/link/add`)}
                        />
                        <CustomSelect
                            options={[
                                {
                                    id: 1,
                                    label: 'English',
                                    value: 'english'
                                },
                                {
                                    id: 2,
                                    label: 'Indonesia',
                                    value: 'indonesia'
                                },
                                {
                                    id: 3,
                                    label: 'Arabic',
                                    value: 'arabic'
                                },
                            ]}
                        />
                    </div>
                </div>

                <div className='mt-5'>
                    <div className='bg-white dark:bg-black-PRIMARY border border-gray-100 dark:border-gray-dark rounded-xl overflow-hidden '>
                        <div className='flex flex-col w-full bg-gray-50  dark:bg-transparent px-5 py-3 space-y-2 border-b border-gray-100 dark:border-gray-dark'>
                            {
                                getTotalSelected() > 0 &&
                                <div className='font-medium bg-gray-50 dark:bg-transparent w-full text-xs md:text-sm flex items-center justify-between '>
                                    <div className='text-gray-text dark:text-white'>{getTotalSelected()} records selected</div>
                                    <div className='flex items-center space-x-2 text-xs md:text-sm'>
                                        <div
                                            onClick={handleSelectAll}
                                            className='text-orange'>
                                            Select all {selectedCards.length}
                                        </div>
                                        <div
                                            onClick={handleSelectAll}
                                            className='text-red'>
                                            Deselect all
                                        </div>

                                    </div>
                                </div>
                            }
                            <div className='text-xs lg:text-sm xl:text-base font-semibold text-black'>
                                <input type="checkbox" className='w-4 h-4' checked={selectAll} onChange={handleSelectAll} />
                            </div>
                        </div>

                        <div className='grid md:grid-cols-2 lg:grid-cols-3 gap-4 p-5'>
                            {paginatedCards.map((checkbox) => (
                                <div key={checkbox} className=' flex rounded-xl space-x-4 p-4 border border-gray-100 dark:border-gray-dark dark:bg-black-SECONDARY'>
                                    <div className='self-center'>
                                        <input type="checkbox" className='border border-gray-100 h-4 w-4' checked={checkbox.checked} onChange={() => handleSingleCheck(checkbox.id)} />
                                    </div>
                                    <div className='w-full'>
                                        <div className='w-full h-[130px]'>
                                            <img src={ASSETS.AVATAR} alt='' className='w-full object-cover h-full' />
                                        </div>
                                        <div className='space-y-1 mt-3'>
                                            <div className='text-gray-text dark:text-white font-semibold text-xs md:text-sm'>Anything that is cool</div>
                                            <div className='text-gray-500 text-xs'>www.google.com</div>
                                        </div>
                                        {
                                            checkbox.active &&
                                            <div className='w-[90%] mx-auto flex items-center space-x-2 justify-between border border-gray-100 dark:border-gray-dark bg-gray-50 dark:bg-black-SECONDARY rounded-lg px-2 py-2 mt-4'>
                                                <div className='rounded-md w-6 h-6 bg-blue'></div>
                                                <div className='text-xs text-gray-500 w-[80%] text-center'>
                                                    Qui velit blanditiis laboriosam corrupti laborum voluptate.
                                                </div>
                                            </div>
                                        }
                                        <div className='mt-4'>
                                            <div className='flex items-center space-x-3'>
                                                <div
                                                    className='text-gray-text dark:text-white cursor-pointer flex items-center justify-center space-x-1 text-sm'>
                                                    <div><RxExternalLink className='text-lg' /></div>
                                                    <div className='font-medium'>Visit link</div>
                                                </div>
                                                <div
                                                    onClick={() => navigate(`/blog/link/edit/${checkbox.id}`)}
                                                    className='text-orange cursor-pointer flex items-center justify-center space-x-1 text-sm'>
                                                    <div><BiSolidEdit className='text-lg' /></div>
                                                    <div className='font-medium'>Edit</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className='cursor-pointer self-center'
                                        onClick={() => handleActiveInative(checkbox.id)}>
                                        {
                                            checkbox.active ?
                                                <FaChevronDown className='text-xs text-gray-icon' /> :
                                                <FaChevronUp className='text-xs text-gray-icon' />
                                        }
                                    </div>

                                </div>

                            ))}

                        </div>
                        {renderPagination()}
                    </div>

                </div>



            </div>
        </Layout>
    )
}
