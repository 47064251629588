import React from 'react'
import { ASSETS } from '../../../Assets/path'
import { Search } from '../../Atoms';
import { IoNotificationsOutline } from "react-icons/io5";
import { RxHamburgerMenu } from "react-icons/rx";
import { DrawerComponent } from '../../Organism';
import { FaTimes } from 'react-icons/fa';
import { HiOutlineShoppingBag } from "react-icons/hi2";
import { IoNotificationsOffOutline } from "react-icons/io5";
import { LiaTimesSolid } from "react-icons/lia";
import { useTheme } from '../../../Theme';
import { IoSunnyOutline } from "react-icons/io5";
import { IoIosMoon } from "react-icons/io";
import { FaDesktop } from "react-icons/fa";
import { SlLogout } from "react-icons/sl";
import { useAppNavigate } from '../../../Hooks';

export const Header = ({ onShowMenu }) => {
    const { theme, setTheme } = useTheme()
    const navigate = useAppNavigate()

    const [showNotification, setShowNotification] = React.useState(false)
    const [showProfile, setShowProfile] = React.useState(false)
    const [notifications, setNotifications] = React.useState([
        {
            title: 'New Order',
            date: 'Today, 10:00 AM',
            description: 'You have a new order from #1234567890'
        }, {
            title: 'New Order',
            date: 'Today, 10:00 AM',
            description: 'You have a new order from #1234567890'
        }, {
            title: 'New Order',
            date: 'Today, 10:00 AM',
            description: 'You have a new order from #1234567890'
        }, {
            title: 'New Order',
            date: 'Today, 10:00 AM',
            description: 'You have a new order from #1234567890'
        }, {
            title: 'New Order',
            date: 'Today, 10:00 AM',
            description: 'You have a new order from #1234567890'
        }
    ])

    return (
        <>
            <nav
                className="flex fixed z-[9999] w-full items-center justify-between flex-wrap bg-white dark:bg-black-SECONDARY py-4 px-4 shadow border-solid ">
                <img src={ASSETS.LOGO} alt='' className='h-8 hidden lg:block lg:h-10 object-contain  dark:brightness-500 dark:filter dark:invert dark:brightness-800' />
                <RxHamburgerMenu
                    onClick={onShowMenu}
                    className='block lg:hidden cursor-pointer' />

                <div className='flex items-center space-x-5'>
                    <Search />
                    <div className='relative cursor-pointer'
                        onClick={() => setShowNotification(!showNotification)}
                    >
                        <IoNotificationsOutline className='text-xl text-gray-300' />
                        <div className='absolute -top-2 font-semibold w-4 h-4 flex items-center justify-center text-xs rounded-md -right-3 bg-orange-light dark:bg-transparent border-2 border-orange border-opacity-15 text-orange'>
                            0
                        </div>
                    </div>
                    <div className='relative'>
                        <img src={ASSETS.AVATAR} alt=''
                            onClick={() => setShowProfile(!showProfile)}
                            className='w-8 h-8 rounded-full cursor-pointer' />
                        {
                            showProfile &&
                            <div
                                // onMouseLeave={() => setShowProfile(!showProfile)}
                                className='bg-white dark:bg-black-PRIMARY space-y-1 z-[100] absolute right-0 w-[200px] py-1 rounded-lg border border-gray-100 dark:border-gray-dark'>
                                <div className='text-sm text-gray-400 font-regular flex items-center px-5 border-b space-x-2 border-gray-100 dark:border-gray-dark py-2'>
                                    <img src={ASSETS.AVATAR} alt='' className='w-5 h-5 rounded-full' />
                                    <div>Demo User</div>
                                </div>
                                <div className='flex items-center text-gray-icon justify-between text-xl space-x-2 py-2 px-5 border-b border-gray-100 dark:border-gray-dark'>
                                    <div className={`${theme == 'light' && 'bg-gray-50 dark:bg-gray-800 rounded-lg'} p-2`}>
                                        <IoSunnyOutline
                                            className='cursor-pointer'
                                            onClick={() => setTheme('light')}
                                        />
                                    </div>
                                    <div className={`${theme == 'dark' && 'dark:bg-gray-800 rounded-lg'} p-2`}>
                                        <IoIosMoon
                                            className='cursor-pointer dark:text-orange'
                                            onClick={() => setTheme('dark')}
                                        />
                                    </div>
                                    <div className={`${theme == 'system' && 'bg-gray-50 dark:bg-gray-800 rounded-lg'} p-2`}>
                                        <FaDesktop
                                            className='cursor-pointer'
                                            onClick={() => setTheme(theme === 'light' ? 'dark' : 'light')}
                                        />
                                    </div>

                                </div>
                                <div
                                    onClick={() => navigate('/')}
                                    className='text-sm cursor-pointer text-gray-400 font-regular flex items-center px-5 border-b space-x-2 border-gray-100 dark:border-gray-dark py-2'>
                                    <SlLogout className='text-base text-gray-icon' />
                                    <div>Sign out</div>
                                </div>

                            </div>
                        }
                    </div>
                </div>
            </nav>
            <DrawerComponent
                isOpen={showNotification}
                direction={'right'}
                toggleDrawer={() => setShowNotification(!showNotification)}
            >
                {
                    notifications.length < 1 ?
                        <div className='w-full py-5 dark:bg-black-SECONDARY min-h-screen'>
                            <div className="flex  justify-between px-5">
                                <div>

                                </div>

                                <div className="bg-gray-50 h-14 w-14 rounded-full flex items-center justify-center">
                                    <IoNotificationsOffOutline className='text-xl lg:text-2xl text-gray-icon' />
                                </div>
                                <FaTimes
                                    onClick={() => setShowNotification(!showNotification)}
                                    className='text-gray-icon text-xl cursor-pointer' />
                            </div>
                            <div className='flex flex-col items-center justify-center'>
                                <div className="font-semibold text-xs sm:text-sm lg:text-base mt-5 mb-2">
                                    No Notifications
                                </div>
                                <div className="font-regular text-xs md:text-sm text-gray-500">
                                    Please check again later.
                                </div>
                            </div>


                        </div> :
                        <div className=' w-full py-5 z-[9999] dark:bg-black-SECONDARY min-h-screen'>
                            <div className='px-5 flex items-center justify-between'>
                                <div className='relative'
                                >
                                    <div className={` text-black dark:text-white font-medium`}>
                                        Notifications
                                    </div>
                                    <div className='absolute -top-2 font-semibold w-4 h-4 flex items-center justify-center text-xs rounded-md -right-3 bg-orange-light dark:bg-transparent border  border-orange border-opacity-15 dark:border-gray-dark text-orange'>
                                        5
                                    </div>
                                </div>


                                <LiaTimesSolid
                                    onClick={() => setShowNotification(!showNotification)}
                                    className='text-gray-icon text-xl cursor-pointer' />
                            </div>
                            <div className='space-x-3 flex px-5 border-b border-gray-100 dark:border-gray-dark pt-1 pb-5'>
                                <div className='cursor-pointer text-orange font-medium md:font-semibold text-xs md:text-sm' >Mark all as read</div>
                                <div className='cursor-pointer text-red font-medium md:font-semibold text-xs md:text-sm' >Clear</div>
                            </div>
                            {
                                notifications.map((notification, index) => (
                                    <div key={index} className='flex items-center text-sm py-4 border-b border-gray-100 dark:border-gray-dark px-5 space-x-2'>
                                        <div className='w-[10%]'>
                                            <HiOutlineShoppingBag className='text-xl text-gray-icon md:text-2xl' />
                                        </div>
                                        <div className='w-[90%] space-y-1'>
                                            <div className='text-black dark:text-white font-medium'>
                                                {notification.title}
                                            </div>
                                            <div className='text-gray-text dark:text-gray-icon text-xs'>
                                                {notification.date}
                                            </div>
                                            <div className='text-gray-text dark:text-gray-icon text-xs'>
                                                {notification.description}
                                            </div>
                                            <div className='cursor-pointer text-orange font-medium md:font-semibold text-xs md:text-sm' >View</div>
                                        </div>
                                    </div>
                                ))
                            }

                        </div>
                }

            </DrawerComponent>

        </>
    )
}
