import React from 'react'
import { AuthorsTable, Layout } from '../../../Components/Organism'

export const AuthorsPage = () => {
    return (
        <Layout>
            <div className='p-5 md:p-10 font-inter'>
                <AuthorsTable />
            </div>
        </Layout>

    )
}
