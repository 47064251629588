import React from 'react'
import { FaArrowTrendDown, FaArrowTrendUp } from "react-icons/fa6";
import { HiMiniArrowsUpDown } from "react-icons/hi2";
import { IoOpenOutline } from "react-icons/io5";
import { ImBin } from "react-icons/im";
import { ASSETS } from '../../../Assets/path'
import { Button, CustomSelectWithLabel, InputWithLabel } from '../../Atoms';

export const DashboardCard = ({ amount, title, type, percent, image }) => {
    return (
        <div className='bg-white dark:bg-black-PRIMARY border border-gray-100 dark:border-gray-dark rounded-xl overflow-hidden '>
            <div className='px-5 pt-4 pb-2 space-y-2'>
                <div className='text-xs lg:text-sm font-medium text-gray-card_heading'>{title}</div>
                <div className='font-semibold text-3xl'>${amount}k</div>
                {
                    type == 'increase' ?
                        <div className='flex items-center space-x-2 text-xs lg:text-sm font-regular text-green'>
                            <div>{amount}{percent ? '%' : 'k'} increase</div>
                            <div>
                                <FaArrowTrendUp className='text-green text-base ' />
                            </div>
                        </div> :
                        <div className='flex items-center space-x-2 text-xs lg:text-sm font-regular text-red'>
                            <div>{amount}{percent ? '%' : 'k'} decrease</div>
                            <div>
                                <FaArrowTrendDown className='text-red text-base ' />
                            </div>
                        </div>
                }
            </div>
            {
                image == '1' ?
                    <img src={ASSETS.DASHBOARD.CARD.INCREASE} alt='increase' className='w-full h-5' />
                    : image == '2' ?
                        <img src={ASSETS.DASHBOARD.CARD.DECREASE} alt='decrease' className='w-full h-5' />
                        :
                        <img src={ASSETS.DASHBOARD.CARD.INCREASE_2} alt='increase' className='w-full h-5' />
            }
        </div>
    )
}

export const CardLayout = ({ onButtonPressed, title, content, showTitle, button, buttonTitle, buttonType, customButtonsStatus, customButtons }) => {
    return (
        <div className='bg-white dark:bg-black-PRIMARY border border-gray-100 dark:border-gray-dark rounded-xl overflow-hidden '>
            {
                showTitle && <div className='flex items-center justify-between px-5 pt-4 pb-2 space-y-2 border-b border-gray-100 dark:border-gray-dark'>
                    <div className='text-xs lg:text-sm xl:text-base font-semibold text-black dark:text-white'>{title}</div>
                    {
                        button ?
                            <Button
                                type='button'
                                textSize={'text-xs md:text-sm px-4'}
                                height={'h-8 md:h-9'}
                                background={buttonType == 'delete' ? 'bg-red' : buttonType == 'success' ? 'bg-green' : 'bg-orange'}
                                title={buttonTitle}
                                onButtonPressed={onButtonPressed}
                            />
                            : customButtonsStatus && customButtons
                    }
                </div>
            }
            <div className=''>
                {content}
            </div>
        </div>
    )
}

export const OrdersCard = ({ amount, title, image }) => {
    return (
        <div className='bg-white dark:bg-black-PRIMARY border border-gray-100 dark:border-gray-dark rounded-xl overflow-hidden '>
            <div className='px-5 pt-4 pb-2 space-y-2'>
                <div className='text-xs lg:text-sm font-medium text-gray-card_heading'>{title}</div>
                <div className='font-semibold text-3xl'>{amount}</div>
            </div>
            {
                image == true ?
                    <img src={ASSETS.DASHBOARD.CARD.GRAY_WAVE} alt='increase' className='w-full h-5' />
                    : <div className='h-3'></div>
            }
        </div>
    )
}

export const OrderItemsCard = ({
    item,
    showTitle,
    moveToLink = true,
    onDragStart,
    onDragEnd,
    onDragEnter,
}) => {


    return (
        <div
            onDragStart={onDragStart}
            onDragEnter={onDragEnter}
            onDragEnd={onDragEnd}
            onDragOver={(e) => e.preventDefault()}
            draggable

            className='bg-white dark:bg-black-SECONDARY border border-gray-100 dark:border-gray-dark rounded-xl overflow-hidden '>
            {
                showTitle && <div className='flex items-center justify-between px-5 pt-4 pb-2 space-y-2 border-b border-gray-100 dark:border-gray-dark'>
                    <div className='text-xs lg:text-sm xl:text-base font-semibold text-gray-icon cursor-pointer'><HiMiniArrowsUpDown /></div>
                    <div className=''>
                        <div className='flex items-center text-xs space-x-2 lg:text-sm xl:text-base font-semibold text-gray-icon'>
                            {moveToLink && <IoOpenOutline className='cursor-pointer' />}
                            <ImBin className='text-red cursor-pointer' />
                        </div>
                    </div>

                </div>
            }
            <div className='grid md:grid-cols-3 w-full gap-4 p-4'>
                <CustomSelectWithLabel
                    title='Products'
                    name='status'
                    value={item?.title}
                    isRequired={true}
                    options={[
                        { value: 'pending', label: 'Pending' },
                        { value: 'completed', label: 'Completed' },
                        { value: 'cancelled', label: 'Cancelled' },
                    ]}
                    placeholder='Select Status'
                    className='w-full'
                />
                <InputWithLabel
                    title='Quantity'
                    name='quantity'
                    type='number'
                    value={item?.quantity}
                    isRequired={true}
                    placeholder='Enter Quantity'
                    className='w-full'
                />
                <InputWithLabel
                    title='Unit Price'
                    name='quantity'
                    type='number'
                    isRequired={true}
                    value={item?.price}
                    placeholder='Enter Unit Price'
                    className='w-full'
                    disabled={true}
                />

            </div>
        </div>
    )
}