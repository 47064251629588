import React from 'react'
import { FaChevronUp } from 'react-icons/fa6'

import { Button, CustomSelectWithLabel, InputWithLabel, MultiInputWithLabel, PageHeading, UploadFile } from '../../../Components/Atoms'
import { useAppNavigate } from '../../../Hooks'
import { Layout } from '../../../Components/Organism'
import { CardLayout, RichTextEditor } from '../../../Components/Molecules'
import { UploadModalComponent } from '../../../Components/Organism/modal/uploadModal'



export const AddPost = () => {
    const navigate = useAppNavigate()

    const onSubmitForm = (e) => {
        e.preventDefault()
        const formData = new FormData(e.target)
        const data = Object.fromEntries(formData)
        console.log(data)
        // navigate('/dashboard')
    }

    return (
        <Layout>
            <div className='p-5 md:p-10 font-inter'>
                <PageHeading title='Create Post' />
                <form
                    onSubmit={onSubmitForm}
                    className='mt-5 space-y-6'
                >

                    <div className='space-y-6'>
                        <div
                            className=' grid md:grid-cols-2 gap-4 border dark:border-gray-dark  bg-white dark:bg-transparent  rounded-xl px-5 pt-6 pb-10'
                        >
                            <InputWithLabel
                                title={'Name'}
                                name="name"
                                placeholder='Enter name'
                                password={false}
                                type={'text'}
                                isRequired={true}
                            />
                            <InputWithLabel
                                title={'Slug'}
                                name="slug"
                                placeholder='Enter slug'
                                password={false}
                                type={'text'}
                                disabled={false}
                                isRequired={false}
                            />

                            <div className='md:col-span-2'>
                                <RichTextEditor
                                    name="content"
                                    title="Content"
                                    isRequired={false}
                                />
                            </div>
                            <CustomSelectWithLabel
                                title={'Author'}
                                name="author"
                                placeholder='Select author'
                                isRequired={true}
                                options={[
                                    { value: '1', label: 'Author 1' },
                                    { value: '2', label: 'Author 2' },
                                    { value: '3', label: 'Author 3' },
                                ]}
                            />
                            <CustomSelectWithLabel
                                title={'Category'}
                                name="category"
                                placeholder='Select category'
                                isRequired={true}
                                options={[
                                    { value: '1', label: 'Category 1' },
                                    { value: '2', label: 'Category 2' },
                                    { value: '3', label: 'Category 3' },
                                ]} />
                            <InputWithLabel
                                title={'Published Date'}
                                name="published_date"
                                placeholder='Enter published date'
                                password={false}
                                type='date'
                                disabled={false}
                                isRequired={false}
                            />
                            <MultiInputWithLabel
                                title={'Tags'}
                                name="tags"
                                placeholder='Enter tags'
                                type={'text'}
                                isRequired={false}
                                disabled={false}
                            />
                        </div>

                        <div className='mt-4'>
                            <CardLayout showTitle={true} title='Images'
                                button={false}
                                customButtonsStatus={true}
                                customButtons={
                                    <div className=''>
                                        <FaChevronUp className='text-gray-icon text-xs' />
                                    </div>
                                }
                                buttonTitle={'New Address'}
                                buttonType={'orange'}
                                content={<div className='p-5'>
                                    <UploadModalComponent />

                                </div>} />
                        </div>

                    </div>

                    <div className='flex items-center mt-5 space-x-2'>
                        <Button
                            title={'Create'}
                            type={'submit'}
                            textSize={'text-xs md:text-sm max-w-min px-4'}
                            height={'h-8 md:h-10'}
                        />
                        <Button
                            title={'Create & create another'}
                            type={'submit'}
                            textSize={'text-xs md:text-sm  px-4'}
                            height={'h-8 md:h-10'}
                            background={'bg-white'}
                            color="text-black"
                            border={true}
                        />
                        <Button
                            title={'Cancel'}
                            type={'button'}
                            onButtonPressed={() => navigate('/shop/customers')}
                            textSize={'text-xs md:text-sm max-w-min px-4'}
                            height={'h-8 md:h-10'}
                            background={'bg-white'}
                            color="text-black"
                            border={true}
                        />
                    </div>
                </form>
            </div>
        </Layout>
    )
}
