import React from 'react'

import { Button, PageHeading } from '../../../Components/Atoms'
import { BreadCrum, CardLayout, OrdersCard, Tabs } from '../../../Components/Molecules'
import { Layout } from '../../../Components/Organism'
import { DataTable } from '../../../Components/Organism/table'

import { BiSolidEdit } from "react-icons/bi";
import { useAppNavigate } from '../../../Hooks'
import { statusColor, statusIcons } from '../../../Constants'
export const Orders = () => {

    const navigate = useAppNavigate()
    const [tab, setTab] = React.useState(1)

    const columns = [
        {
            name: "number",
            label: "Number",
            filter: true,
            sort: true,
            sortOrder: "desc",
            visible: true,
        },
        {
            name: "customer",
            label: "Customer",
            filter: true,
            sort: true,
            sortOrder: "desc",
            visible: true,
        },
        {
            name: "status",
            label: "Status",
            filter: true,
            sort: true,
            sortOrder: "desc",
            visible: true,
            customComponent: (props) => {
                return (
                    <div>
                        <span className={`px-2 max-w-min flex items-center space-x-1 justify-center text-xs leading-5 font-semibold rounded ${statusColor[props?.status]} p-0.5 `}>
                            <div className='text-sm'>{statusIcons[props.status]}</div> <div>{props?.status}</div>
                        </span>
                    </div>
                );
            },
        },
        {
            name: "currency",
            label: "Currency",
            filter: false,
            sort: true,
            sortOrder: "desc",
            visible: true,
        },
        {
            name: "total_price",
            label: "Total Price",
            filter: false,
            sort: true,
            sortOrder: "desc",
            visible: true,
        },

        {
            name: "shipping_cost",
            label: "Shipping Cost",
            filter: true,
            sort: false,
            sortOrder: "desc",
            visible: true,
        },
        {
            name: "order_date",
            label: "Order Date",
            filter: false,
            sort: true,
            sortOrder: "desc",
            visible: true,
        },
        {
            name: "",
            label: "Action",
            filter: true,
            sort: true,
            sortOrder: "desc",
            visible: true,
            customComponent: (props) => {
                return (
                    <div
                        onClick={() => navigate(`/shop/orders/${props.id}/edit`)}
                        className='text-orange cursor-pointer flex items-center justify-center space-x-1 text-sm'>
                        <div><BiSolidEdit className='text-lg' /></div>
                        <div className='font-semibold '>Edit</div>
                    </div>
                );
            },
        },

    ];

    const data = [
        {
            id: 1,
            order_date: "2021-04-01",
            number: "1001",
            customer: "John Doe",
            status: "New",
            currency: "USD",
            total_price: "100",
            shipping_cost: "10",
        },
        {
            id: 2,
            order_date: "2021-04-02",
            number: "1002",
            customer: "John Doe",
            status: "Cancelled",
            currency: "USD",
            total_price: "100",
            shipping_cost: "10",
        },
        {
            id: 2,
            order_date: "2021-04-02",
            number: "1002",
            customer: "John Doe",
            status: "Processing",
            currency: "USD",
            total_price: "100",
            shipping_cost: "10",
        },
    ]

    const options = {
        paginationTop: true,
        paginationBottom: true,
    };

    const tabList = [
        {
            id: 1,
            name: 'All',
            active: true
        },
        {
            id: 2,
            name: 'New',
            active: false
        },
        {
            id: 3,
            name: 'Processing',
            active: false
        },
        {
            id: 4,
            name: 'Shipped',
            active: false
        },
        {
            id: 5,
            name: 'Delivered',
            active: false
        },
        {
            id: 6,
            name: 'Cancelled',
            active: false
        },
    ]

    return (
        <Layout>
            <div className='p-5 md:p-10 font-inter'>
                <div className='py-1.5'>
                    <BreadCrum content={[
                        {
                            title: 'Orders',
                            url: '/shop/orders'
                        },
                        {
                            title: 'List',
                            url: '/shop/orders'
                        },

                    ]} />
                </div>

                <div className='flex items-center justify-between'>
                    <PageHeading title='Orders' />
                    <Button
                        title='New Order'
                        type={"button"}
                        textSize={"text-xs md:text-sm"}
                        height={"h-9 w-36"}
                        onButtonPressed={() => navigate('/shop/orders/new')}
                    />
                </div>

                <div className='mt-4 space-y-4'>
                    <div className='grid md:grid-cols-3 gap-4'>
                        <OrdersCard title='Orders' amount='100' image={true} />
                        <OrdersCard title='Open orders' amount='10002' image={false} />
                        <OrdersCard title='Average Price' amount='10012' image={false} />
                    </div>
                    <div className='flex items-center justify-center'>
                        <Tabs list={tabList} tab={tab} setTab={setTab} />
                    </div>
                    <CardLayout showTitle={false} title='' content={<DataTable
                        data={data}
                        groupBy={true}
                        columns={columns} options={options} />} />
                </div>
            </div>
        </Layout>
    )
}
