import React from 'react'

export const DeleteIcon = ({ onPressColumnsFilter }) => {
    return (
        <div
            onClick={onPressColumnsFilter}
            className='h-4 space-x-0.5 cursor-pointer flex items-center'>
            <div className='bg-gray-icon h-3.5 w-1.5'>

            </div>
            <div className='bg-gray-icon h-3.5 w-1.5'>

            </div>
            <div className='bg-gray-icon h-3.5 w-1.5'>

            </div>
        </div>
    )
}
