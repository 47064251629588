import React from 'react'

import { Button, PageHeading } from '../../../Components/Atoms'
import { BreadCrum, CardLayout } from '../../../Components/Molecules'
import { Layout } from '../../../Components/Organism'
import { DataTable } from '../../../Components/Organism/table'

import { BiSolidEdit } from "react-icons/bi";
import { useAppNavigate } from '../../../Hooks'

export const Customers = () => {

    const navigate = useAppNavigate()

    const data = [
        {
            id: 1,
            name: "John Doe",
            email: "johndoe@gmail.com",
            country: "United States",
            phone: "+1 1234567890",
        },
        {
            id: 2,
            name: "John Carter",
            email: 'carter@gmail.com',
            country: "United States",
            phone: "+1 1234567890",
        },
        {
            id: 3,
            name: "John Doe",
            email: "kam@gmail.com",
            country: "United States",
            phone: "+1 1234567890",
        },
    ]

    const columns = [
        {
            name: "name",
            label: "Name",
            filter: false,
            sort: true,
            sortOrder: "desc",
            visible: true,
        },
        {
            name: "email",
            label: "Email address",
            filter: true,
            sort: true,
            sortOrder: "desc",
            visible: true,
        },
        {
            name: "country",
            label: "Country",
            filter: true,
            sort: false,
            sortOrder: "desc",
            visible: true,
        },
        {
            name: "phone",
            label: "Phone",
            filter: true,
            sort: true,
            sortOrder: "desc",
            visible: true,
        },
        {
            name: "",
            label: "Status",
            filter: true,
            sort: true,
            sortOrder: "desc",
            visible: true,
            customComponent: (props) => {
                return (
                    <div
                        onClick={() => navigate(`/shop/customers/${props.id}/edit`)}
                        className='text-orange cursor-pointer flex items-center justify-center space-x-1 text-sm'>
                        <div><BiSolidEdit className='text-lg' /></div>
                        <div className='font-semibold '>Edit</div>
                    </div>
                );
            },
        },

    ];

    const options = {
        paginationTop: true,
        paginationBottom: true,
    };

    return (
        <Layout>
            <div className='p-5 md:p-10 font-inter'>
                <div className='py-1.5'>
                    <BreadCrum content={[
                        {
                            title: 'Customers',
                            url: '/shop/customers'
                        },
                        {
                            title: 'List',
                            url: '/shop/customers'
                        },
                    ]} />
                </div>
                <div className='flex items-center justify-between'>
                    <PageHeading title='Customers' />
                    <Button
                        title='New Customer'
                        type={"button"}
                        textSize={"text-xs md:text-sm"}
                        height={"h-9 w-36"}
                        onButtonPressed={() => navigate('/shop/customers/new')}
                    />
                </div>
                <div className='mt-4'>
                    <CardLayout showTitle={false} title='Latest Orders' content={<DataTable
                        data={data}
                        deleteRowsFilter={true}
                        columnRowsFilter={false}
                        columns={columns} options={options} />} />
                </div>
            </div>
        </Layout>
    )
}
