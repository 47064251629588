import React from 'react'
import { CardLayout, RichTextEditor } from '../../Molecules';
import { DataTable } from '.';
import { BiSolidEdit } from 'react-icons/bi';
import { FaTimes } from 'react-icons/fa';
import { ImBin } from 'react-icons/im';
import { Button, Checkbox, InputWithLabel, PageHeading, Switch, UploadFile } from '../../Atoms';
import { DeleteModalComponent } from '../modal/delete';
import { ModalComponent } from '../modal';

import { FaGithub, FaTwitter } from 'react-icons/fa6';

export const AuthorsTable = () => {

    const [deleteModal, setDeleteModal] = React.useState(false);
    const [authorModal, setAuthorModal] = React.useState(false);
    const [importModal, setImportModal] = React.useState(false);
    const [exportModal, setExportModal] = React.useState(false);


    const columns = [
        {
            name: "user",
            label: "",
            filter: true,
            sort: true,
            sortOrder: "desc",
            visible: true,
            customComponent: (props) => {
                return (
                    <div className='flex flex-col space-y-1 px-4 text-gray-text'>
                        <div className='font-semibold'>
                            {props.name}
                        </div>
                        <div className='font-light text-gray-500 '>
                            {props.email}
                        </div>
                    </div>
                );
            },
        },
        {
            name: "social",
            label: "",
            filter: true,
            sort: true,
            sortOrder: "desc",
            visible: true,
            customComponent: (props) => {
                return (
                    <div className='flex text-gray-icon flex-col space-y-2 items-center justify-center'>
                        <div className='font-medium flex space-x-2'>
                            <div>
                                <FaGithub className='text-base md:text-lg lg:text-xl' />
                            </div>
                            <div className='text-gray-text'>
                                {props?.github}
                            </div>
                        </div>
                        <div className='font-medium flex space-x-2'>
                            <div>
                                <FaTwitter className='text-base md:text-lg lg:text-xl' />
                            </div>
                            <div className='text-gray-text'>
                                {props?.github}
                            </div>
                        </div>

                    </div>
                );
            },
        },
        {
            name: "",
            label: "Action",
            filter: true,
            sort: true,
            sortOrder: "desc",
            visible: true,
            customComponent: (props) => {
                return (
                    <div className='flex items-center justify-center space-x-3'>

                        <div
                            onClick={() => setAuthorModal(!authorModal)}
                            className='text-orange cursor-pointer flex items-center justify-center space-x-1 text-sm'>
                            <div><BiSolidEdit className='text-lg' /></div>
                            <div className='font-medium'>Edit</div>
                        </div>
                        <div
                            onClick={() => setDeleteModal(!deleteModal)}
                            className='text-red cursor-pointer flex items-center justify-center space-x-1 text-sm'>
                            <div><ImBin className='text-lg' /></div>
                            <div className='text-red font-medium'>Delete</div>
                        </div>
                    </div>
                );
            },
        },

    ];

    const data = [
        {
            id: 1,
            name: 'John Doe',
            email: 'johndoe@gmail.com',
            github: 'Johndoe',
            twitter: 'Johndoe',
        },
    ]

    const options = {
        paginationTop: true,
        paginationBottom: true,
    };

    const onSubmitForm = (e) => {
        e.preventDefault()
        const formData = new FormData(e.target)
        const data = Object.fromEntries(formData)
        console.log(data)
        // navigate('/dashboard')
    }

    const ImportModalBody = (
        <form
            onSubmit={onSubmitForm}
            className='p-5 '
        >
            <div className=' flex items-center justify-between pb-3'>
                <div className='text-xl font-semibold'>
                    Import Categories
                </div>
                <div>
                    <FaTimes
                        onClick={() => setImportModal(!importModal)}
                        className='text-gray-icon cursor-pointer' />
                </div>
            </div>
            <div className='text-orange text-xs md:text-sm pb-2 font-semibold hover:underline hover:duration-300'>
                Download example CSV file
            </div>
            <div>
                <UploadFile
                    title={'Upload a CSV file'}
                    name={'file'}
                    id={'file'}
                    accept={'.csv'}
                />
            </div>

            <div className='flex items-center mt-5 space-x-2'>
                <Button
                    title={'Import'}
                    type={'submit'}
                    textSize={'text-xs md:text-sm  px-4'}
                    height={'h-8 md:h-10'}
                    background={'bg-orange'}
                    color="text-white"
                    border={true}
                />
                <Button
                    title={'Cancel'}
                    type={'button'}
                    onButtonPressed={() => setImportModal(!importModal)}
                    textSize={'text-xs md:text-sm max-w-min px-4'}
                    height={'h-8 md:h-10'}
                    background={'bg-white'}
                    color="text-black"
                    border={true}
                />
            </div>
        </form>
    )

    const AuthorModalBody = (<form
        onSubmit={onSubmitForm}
        className='p-5 border dark:border-gray-dark dark:bg-black-PRIMARY rounded-md dark:text-white'
    >
        <div className=' flex items-center justify-between pb-6'>
            <div className='text-xs lg:text-sm xl:text-base font-semibold'>
                Create author
            </div>
            <div>
                <FaTimes
                    onClick={() => setAuthorModal(!authorModal)}
                    className='text-gray-icon cursor-pointer' />
            </div>
        </div>
        <div
            className=' grid md:grid-cols-2 gap-4  '
        >
            <InputWithLabel
                title={'Name'}
                name="name"
                placeholder='Enter name'
                password={false}
                type={'text'}
                isRequired={true}
            />
            <InputWithLabel
                title={'Email address'}
                name="email"
                placeholder='Enter email address'
                password={false}
                type={'text'}
                disabled={false}
                isRequired={true}
            />

            <div className='md:col-span-2'>
                <RichTextEditor
                    name="bio"
                    title="Bio"
                    isRequired={false}
                />
            </div>
            <InputWithLabel
                title={'Github handle'}
                name="github"
                placeholder='Enter gitub username'
                password={false}
                type={'text'}
                disabled={false}
                isRequired={false}
            />
            <InputWithLabel
                title={'Twitter handle'}
                name="twitter"
                placeholder='Enter twitter username'
                password={false}
                type={'text'}
                disabled={false}
                isRequired={false}
            />

        </div>


        <div className='flex items-center mt-5 space-x-2'>
            <Button
                title={'Create'}
                onButtonPressed={() => setAuthorModal(!authorModal)}
                type={'submit'}
                textSize={'text-xs md:text-sm max-w-min px-4'}
                height={'h-8 md:h-10'}
            />
            <Button
                title={'Create & create another'}
                type={'submit'}
                textSize={'text-xs md:text-sm  px-4'}
                height={'h-8 md:h-10'}
                background={'bg-white'}
                color="text-black"
                border={true}
            />
            <Button
                title={'Cancel'}
                type={'button'}
                onButtonPressed={() => setAuthorModal(!authorModal)}
                textSize={'text-xs md:text-sm max-w-min px-4'}
                height={'h-8 md:h-10'}
                background={'bg-white'}
                color="text-black"
                border={true}
            />
        </div>

    </form>
    )

    const ExportModalBody = (
        <form
            onSubmit={onSubmitForm}
            className='p-5 border dark:border-gray-dark dark:bg-black-PRIMARY rounded-md dark:text-white'
        >
            <div className=' flex items-center justify-between pb-3'>
                <div className='text-xl font-semibold'>
                    Export Authors
                </div>
                <div>
                    <FaTimes
                        onClick={() => setExportModal(!exportModal)}
                        className='text-gray-icon cursor-pointer' />
                </div>
            </div>
            <div
                className='z bg-white dark:bg-transparent z-[50]  px-4 py-4 flex flex-col'>
                <div className='font-semibold text-sm lg:text-base'>Columns</div>
                <div className='space-y-4 '>
                    <Checkbox title={'Customer'} />
                    <Checkbox title={'Currency'} />
                    <Checkbox title={'Shipping cost'} />
                    <Checkbox title={'Order ate'} />
                </div>
            </div>


            <div className='flex items-center mt-5 space-x-2'>
                <Button
                    title={'Export'}
                    type={'submit'}
                    textSize={'text-xs md:text-sm  px-4'}
                    height={'h-8 md:h-10'}
                    background={'bg-orange'}
                    color="text-white"
                    border={true}
                />
                <Button
                    title={'Cancel'}
                    type={'button'}
                    onButtonPressed={() => setExportModal(!exportModal)}
                    textSize={'text-xs md:text-sm max-w-min px-4'}
                    height={'h-8 md:h-10'}
                    background={'bg-white'}
                    color="text-black"
                    border={true}
                />
            </div>
        </form>
    )

    return (
        <div className=''>
            <div className=' mb-4 flex items-center justify-between'>
                <PageHeading title='Authors' />
                <div className='flex space-x-2'>
                    <Button
                        title='Export authors'
                        type={"button"}
                        textSize={"text-xs md:text-sm"}
                        height={"h-9 w-36"}
                        background={'bg-white'}
                        color={'text-black'}
                        border={true}
                        onButtonPressed={() => setExportModal(!exportModal)}
                    />
                    <Button
                        title='New author'
                        type={"button"}
                        textSize={"text-xs md:text-sm"}
                        height={"h-9 w-36"}
                        onButtonPressed={() => setAuthorModal(!authorModal)}
                    />
                </div>

            </div>

            <ModalComponent
                style="w-11/12 sm:w-10/12 md:w-1/2  2xl:w-[22%] top-[25%] lg:top-[20%]"
                modal={authorModal}
                onModalStatus={() => setAuthorModal(!authorModal)}
                content={AuthorModalBody}
            />

            <DeleteModalComponent
                onModalStatus={() => setDeleteModal(!deleteModal)}
                modal={deleteModal}
                type={'delete'}
            />

            <ModalComponent
                style="w-11/12 sm:w-10/12 md:w-1/2 lg:w-4/12  2xl:w-[22%] top-[25%] lg:top-[30%] "
                modal={importModal}
                onModalStatus={() => setImportModal(!importModal)}
                content={ImportModalBody}
            />
            <ModalComponent
                style="w-11/12 sm:w-10/12 md:w-1/2 lg:w-4/12  2xl:w-[22%] top-[25%] lg:top-[30%] "
                modal={exportModal}
                onModalStatus={() => setExportModal(!exportModal)}
                content={ExportModalBody}
            />

            <CardLayout
                showTitle={false}
                title=''
                button={false}
                customButtonsStatus={false}
                content={<DataTable
                    data={data}
                    groupBy={false}
                    sortBy={true}
                    showHeading={false}
                    columns={columns} options={options} />}
            />
        </div>


    )
}
